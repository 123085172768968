import React from 'react';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import mapPointer from '../../img/contacts/map-pointer.svg';

const Map = withScriptjs(withGoogleMap((props) =>
  <GoogleMap
    defaultZoom={13}
    defaultCenter={{ lat: 47.238477, lng: 39.610799 }}>
    {props.isMarkerShown && <Marker icon={mapPointer} position={{ lat: 47.238477, lng: 39.610799 }} />}
  </GoogleMap>
))

export default Map;