import React from "react";
import { Link } from "react-router-dom";

import "./index.scss";

const Footer = () => (
  <footer className="footer">
    <div className="footer__container">
      <div className="footer__contacts">
        <div className="footer__contacts__group">
          <a href="tel:+7 928 226 53 81" className="footer__contacts__phone">
            +7 928 226 53 81
          </a>
        </div>

      </div>
      <div className="footer__navigation">
        <Link to="/delivery" className="footer__navigation__link">
          Доставка
        </Link>
        <Link to="/contacts" className="footer__navigation__link">
          Контакты
        </Link>
        <Link to="/faq" className="footer__navigation__link">
          Часто задаваемые вопросы
        </Link>
      </div>
      <div className="footer__contacts__group">
        <a
          href="mailto:mail@futbolka.market"
          className="footer__contacts__email">
          mail@futbolka.market
        </a>
      </div>
    </div>
  </footer>
);

export default Footer;
