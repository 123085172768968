import uuidv1 from 'uuid/v1';

export function makeEmptyOrder(
  hex,
  previews,
  price_grid,
  size_info,
  modelName,
  model,
  gender,
  productType
) {
  const sizes = size_info.sort((a, b) => a.rus_title - b.rus_title).map(size => 0);
  const sizesTitles = size_info.map(size => size.title);
  const emptyOrder = {
    sizes: {
      [hex]: sizes
    },
    layeredPreviews: [],
    sizesTitles,
    price: [],
    id: uuidv1(),
    previews,
    price_grid,
    model,
    gender,
    productType,
    modelName,
    size_info,
    productsCounter: 0,
    totalPrice: 0
  };
  return emptyOrder
}