import React, { Component } from "react";
import axios from "axios";
import Spinner from "react-spinkit";
import { connect } from "react-redux";

import "./index.scss";
import Button from "../../components/Button";
import BasketItem from "../../components/BasketItem";
import MobileBasketItem from "../../components/MobileBasketItem";
import TabletBasketItem from "../../components/TabletBasketItem";
import ProductAmountLabel from "../../components/ProductAmountLabel";
import { formatPrice } from "../../helpers";
import Helmeted from "../../components/Helmeted";

class Order extends Component {
  state = {
    customer: {
      name: "",
      phone: "",
      email: ""
    },
    total: null,
    orders: [],
    loading: true,
    totalProductsAmount: 0
  };
  componentDidMount() {
    this.getOrder();
  }

  getOrder = () => {
    const orderId = this.props.match.params.id;
    axios
      .get(`/api/v1/orders?order=${orderId}`)
      .then(({ data }) => {

        this.setState({
          customer: {
            name: data.user,
            phone: data.phone,
            email: data.email
          },
          total: data.total_price
        });

        let items = data.items.map(item => {
          let modelGrid = axios
            .get(`/api/v1/catalog/main/${item.catalog_item.id}`)
            .then(({ data }) => {
              const order = this.makeOrder(data, item);
              return order;
            })
            .then(model => {
              return axios
                .get(
                  `/api/v1/catalog/main/${item.catalog_item.id}/previews_list/`
                )
                .then(({ data }) => {
                  const previews = data.previews;

                  return Object.assign({}, model, {
                    previews,
                    layeredPreviews: this.getLayeredPreviews(
                      previews,
                      Object.keys(model.sizes)
                    )
                  });
                });
            });

          return modelGrid;
        });

        return Promise.all(items).then(response => response);
      })
      .then(orders => {

        this.setState({
          orders,
          loading: false,
          totalProductsAmount: orders.reduce(
            (acc, order) => acc + order.productsCounter,
            0
          )
        });
      })
      .catch(error => this.props.history.push('/'));
  };

  makeOrder = (modelGrid, itemInfo, title) => {

    let sizes = {};
    let size_list = modelGrid.size_info.map(size => size.title);
    let sizeRow = size_list.map(size => 0);
    itemInfo.item_info.forEach(item => {
      if (!(item.hex in sizes)) {
        sizes = Object.assign({}, sizes, { [item.hex]: sizeRow.slice(0) });
      }
    });

    const isSameSize = orderedSizeTitle => {
      return size_list.findIndex(sizeTitle => sizeTitle === orderedSizeTitle);
    };

    itemInfo.item_info.forEach(item => {
      item.sizes.forEach(size => {
        sizes[item.hex][isSameSize(size.title)] = size.count;
      });
    });

    const productsCounter = Object.keys(sizes)
      .map(key => sizes[key])
      .map(
        sizes => sizes.reduce((acc, value) => Number(acc) + Number(value)),
        0
      )
      .reduce((acc, value) => {
        if (typeof acc === null) {
          return;
        } else return acc + Number(value);
      }, 0);

    const order = {
      modelName: itemInfo.catalog_item.name,
      sizes,
      productsCounter,
      totalPrice: itemInfo.item_price,
      layeredPreviews: modelGrid.preview.images,
      sizesTitles: size_list
    };

    return order;
  };

  getLayeredPreviews = (previews, colors) => {
    const layeredPreviews = [];

    colors.forEach(color => {
      let layeredPreviewIndex = previews.findIndex(
        preview => preview.color.hex === color
      );
      if (layeredPreviewIndex !== -1) {
        layeredPreviews.push(previews[layeredPreviewIndex].image);
      }
    });
    return layeredPreviews;
  };

  render() {
    const { name, phone, email } = this.state.customer;
    const { totalProductsAmount, orders, loading } = this.state;
    const { clientWidth } = this.props;
    return (
      <div className="orderPay page">
        <Helmeted />
        {loading ? (
          <Spinner
            className="productModal__spinner"
            name="ball-clip-rotate-multiple"
          />
        ) : (
            <div className="orderPay__body">
              <div className="orderPay__header">
                <div className="orderPay__header__group">
                  <span className="orderPay__header__title">Заказ</span>
                </div>
                <div className="orderPay__header__amount">
                  <span className="orderPay__header__order">В заказе:</span>
                  <ProductAmountLabel amount={totalProductsAmount} />
                </div>
              </div>

              {clientWidth > 540
                ? clientWidth <= 1100
                  ? orders.map((order, index) => (
                    <TabletBasketItem
                      basket
                      order={order}
                      key={order.modelName + index}
                      checkout
                    />
                  ))
                  : orders.map((order, index) => (
                    <BasketItem
                      basket
                      order={order}
                      key={order.modelName}
                      checkout
                    />
                  ))
                : orders.map((order, index) => (
                  <MobileBasketItem
                    basket
                    order={order}
                    key={order.modelName}
                    checkout
                  />
                ))}
              <div className="orderPay__header__title orderPay__customer__title">
                Получатель груза
              </div>
              <div className="orderPay__customer">
                <div className="orderPay__customer__info orderPay__customer__name">
                  {name}
                </div>
                <div className="orderPay__customer__info orderPay__customer__phone">
                  {phone}
                </div>
                <div className="orderPay__customer__info orderPay__customer__email">
                  {email}
                </div>
              </div>
              <div className="orderPay__footer">
                <div className="orderPay__footer__group">
                  <span className="orderPay__footer__order">
                    В заказе:
                </span>
                  <span className="orderPay__footer__amount">
                    {totalProductsAmount} шт.
                </span>
                </div>
                <div className="orderPay__footer__price">
                  <span className="orderPay__footer__price__label">
                    Итого к оплате:
                  </span>
                  <span className="orderPay__footer__price__value">
                    {formatPrice(this.state.total)} руб
                  </span>
                </div>
                <Button>Оплатить</Button>
              </div>
            </div>
          )}
      </div>
    );
  }
}

const mapStateToProps = ({ helpers }) => {
  return {
    clientWidth: helpers.clientWidth
  };
};

export default connect(mapStateToProps)(Order);
