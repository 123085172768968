import React from 'react';
import Slider from 'react-slick';

import './index.scss';
import shirtPreviewSwitcher from '../../img/productModal/shirt-preview-switcher.png';
import { ReactComponent as PhotoNotAvailable } from '../../img/shirtPhotoNotAvailable.svg';

const settings = {
  dots: false,
  arrows: false,
  infinite: true,
  speed: 500,
  autoplay: true,
  autoplaySpeed: 2000,
  slidesToShow: 1,
  slidesToScroll: 1
};

const PreviewProduct = ({ previews }) => {
  let slider = React.createRef();

  return (
    <div className="preview">
      {previews ?
        <>
          <Slider
            ref={slider}
            {...settings}>
            {previews.map(preview =>
              <div key={preview} className="preview__image__wrapper">
                <img
                  src={preview}
                  alt=""
                  className="preview__image" />
              </div>)}
          </Slider>
          <div className="preview__switchers">
            <img
              src={shirtPreviewSwitcher}
              alt=""
              className="preview__switcher"
              onClick={() => slider.current.slickPrev()} />
            <img
              src={shirtPreviewSwitcher}
              alt=""
              className="preview__switcher"
              onClick={() => slider.current.slickNext()} />
          </div>
        </> :
        <div className="preview__image__wrapper">
          <div className="preview__image__nophoto">
            <PhotoNotAvailable />
            <span className="preview__image__nophoto__label">
              Данная модель есть в другом цвете
            </span>
          </div>
        </div>}
    </div>
  )
}

export default PreviewProduct;
