export function formatOrder(basketOrder) {

  const filteredOrders = basketOrder.orders.map(order => {
    let titles = order.sizesTitles;
    let sizes = order.sizes;
    let orderColors = Object.keys(order.sizes);
    let orderSizes = [];
    let colors = [];
    orderColors.forEach(orderColor => {

      colors.push(colorRow)
      let colorRow = sizes[orderColor].reduce((acc, value, index) => {
        if (value > 0) {
          return {
            ...acc,
            hex: orderColor,
            sizes: [
              ...acc.sizes,
              {
                title: titles[index],
                count: value
              }
            ]
          }
        }
        return acc;

      }, {
        sizes: []
      });
      colors = colors.concat(colorRow)
      colors = colors.filter(color => color !== undefined)

    })
    return {
      model_id: Number(order.model),
      colors: colors
    }
  })

  const finalItems = [];
  for (let i = 0; i < filteredOrders.length; i++) {

    let alreadyInFilteredOrders = finalItems.findIndex((order => order.model_id === filteredOrders[i].model_id));
    if (alreadyInFilteredOrders === -1) {
      finalItems.push(filteredOrders[i]);
    } else {
      finalItems[alreadyInFilteredOrders] = {
        ...finalItems[alreadyInFilteredOrders],
        colors: mergeColors(finalItems[alreadyInFilteredOrders].colors.concat(filteredOrders[i].colors))
      }
    }
  }
  const finalOrder = {
    items: finalItems,
    phone: basketOrder.customerInfo.phone,
    title: basketOrder.id.slice(0, 8).toUpperCase(),
  }

  if (basketOrder.delivery) {
    return Object.assign({}, finalOrder, {
      delivery_info: {
        company: basketOrder.deliveryCompany,
        address: basketOrder.customerInfo.city,
      }
    })

  } else {
    return finalOrder;
  }

}

function mergeColors(colors) {
  const formattedColors = [];
  for (let i = 0; i < colors.length; i++) {
    let colorIndex = formattedColors.findIndex(color => color.hex === colors[i].hex);

    if (colorIndex === -1) {
      formattedColors.push(colors[i])
    } else {
      formattedColors[colorIndex] = {
        hex: formattedColors[colorIndex].hex,
        sizes: mergeSizes(formattedColors[colorIndex].sizes.concat(colors[i].sizes))
      }
    }

  }
  return formattedColors;
}

function mergeSizes(sizes) {
  const formattedSizes = [];
  for (let i = 0; i < sizes.length; i++) {
    let sizeIndex = formattedSizes.findIndex(size => size.title === sizes[i].title);
    if (sizeIndex === -1) {
      formattedSizes.push(sizes[i])
    } else {
      formattedSizes[sizeIndex] = {
        title: formattedSizes[sizeIndex].title,
        count: formattedSizes[sizeIndex].count + sizes[i].count
      }
    }
  }
  return formattedSizes
}