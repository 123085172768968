import * as types from "./actionTypes";

const initialState = {
  confirmationModalIsOpen: false,
  confirmationModalTab: "",
  confirmationModalPayload: undefined,
  calculatorModalIsOpen: false,
  sidebarIsOpen: false,
  clientWidth: 1200,
  animationCartCounter: false,
  productModalIsOpen: false,
  colorPalletePageX: 0,
  colorPalletePageY: 0,
  colorPalleteColorToChange: null,
  commentIndex: 0,
  deliveryFormIsValid: false,
  calculatorActiveMethod: "silkscreen",
  colorPalleteIsOpen: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.OPEN_COLOR_PALLETE:
      return {
        ...state,
        colorPalleteIsOpen: action.flag,
        colorPalletePageX: action.pageX,
        colorPalletePageY: action.pageY,
        colorPalleteColorToChange: action.colorToChange
      };
    case types.DELIVERY_FORM_IS_VALID:
      return {
        ...state,
        deliveryFormIsValid: action.flag
      };
    case types.CHANGE_CALCULATOR_ACTIVE_METHOD:
      return {
        ...state,
        calculatorActiveMethod: action.activeMethod
      };

    case types.TOGGLE_PRODUCT_MODAL:
      return { ...state, productModalIsOpen: action.flag };
    case types.OPEN_CONFIRMATION_MODAL:
      return {
        ...state,
        confirmationModalIsOpen: true,
        confirmationModalTab: action.tab,
        confirmationModalPayload: action.payload
      };
    case types.CLOSE_CONFIRMATION_MODAL:
      return {
        ...state,
        confirmationModalIsOpen: false,
        confirmationModalPayload: undefined
      };
    case types.TOGGLE_CALCULATOR_MODAL:
      return {
        ...state,
        calculatorModalIsOpen: action.flag
      };
    case types.TOGGLE_SIDEBAR:
      return {
        ...state,
        sidebarIsOpen: action.flag
      };
    case types.SET_CLIENT_WIDTH:
      return {
        ...state,
        clientWidth: action.clientWidth
      };
    case types.BASKET_IS_ANIMATING:
      return {
        ...state,
        animationCartCounter: action.flag
      };
    default:
      return state;
  }
};
