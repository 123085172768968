import React from 'react';

import '../index.scss';
import close from '../../../img/application/close.svg';
import PreviewCarousel from '../../ApplicationPreviewSlider';

const Embroidery = ({ closeApplicationModal }) => (
  <div className="silk">
    <div className="silk__preview">
      <img
        className="silk__preview__close"
        src={close}
        onClick={closeApplicationModal} />
      <div className="silk__preview__content">
        <PreviewCarousel applicationMethod="vish" />
      </div>
    </div>
    <div className="silk__column">
      <div className="silk__column__content">
        <div className="silk__column__content__caption">
          Вышивка на заказ
        </div>
        <div className="silk__column__content__text">
          Машинная (компьютерная) вышивка — выполняется специальными автоматическими вышивальными машинами, по заданной дизайнером или конструктором программе. Применяется для нанесения на  футболки, платья, рубашки, куртки, толстовки, шапки, бейсболки, шарфы, сумки, часто используется для изготовления логотипа на корпоративной и спортивной одежде.
        </div>
        <div className="silk__column__content__title">
          Машинная вышивка в Ростове
        </div>
        <div className="silk__column__content__text">
          Мы профессионально выполняем вышивку на заказ по различным видам текстиля уже более 10 лет. Что бы именно Вы не заказали — футболку с вышивкой или эксклюзивную кепку&nbsp;-&nbsp;бейсболку, — качество нашей работы испытано годами!
        </div>
      </div>
    </div>
  </div>
)

export default Embroidery;
