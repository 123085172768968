import React, { Component } from 'react';
import axios from 'axios'
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

class Helmeted extends Component {

  state = {
    description: "",
    keywords: "",
    title: ""
  }

  componentDidMount() {
    axios.get(`/api/v1/seo?url=https://futbolka.market${this.props.location.pathname}`)
      .then(({ data }) => {
        if (data) {
          this.setState(data);
        }
      })
  }

  render() {
    const {
      description, keywords, title
    } = this.state;
    return (
      <Helmet>
        {description ? <meta name="description" content={description} /> : null}
        {keywords ? <meta name="keywords" content={keywords} /> : null}
        {title ? <title>{title}</title> : null}
      </Helmet>
    )
  }
}


export default withRouter(Helmeted);
