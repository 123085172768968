import React from "react";
import { bubble as Menu } from "react-burger-menu";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";

import "./index.scss";
import {
  toggleSidebar,
  openConfirmationModal
} from "../../store/helpers/actions";
import { ReactComponent as KMLogo } from "../../img/mainHeader/logo.svg";
import { ReactComponent as CloseSidebar } from "../../img/sidebar/close-sidebar.svg";
import { ReactComponent as Viber } from "../../img/mainHeader/viber.svg";
import { ReactComponent as Whatsup } from "../../img/mainHeader/whatsup.svg";
import { ReactComponent as Telegram } from "../../img/mainHeader/telegram.svg";
import { ReactComponent as Email } from "../../img/mainHeader/email.svg";

class Sidebar extends React.Component {
  state = {
    isOpen: false
  };

  isSidebarOpen(state) {
    this.props.sidebarIsOpen
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "auto");
    this.props.toggleSidebar(this.isOpen);
    return this.isOpen;
  }

  handleSideBarLink = (e, link) => {
    e.preventDefault();
    this.props.toggleSidebar(this.state.isOpen);
    this.props.history.push(link);
  };

  render() {
    return (
      <Menu
        onStateChange={state => this.isSidebarOpen(state)}
        isOpen={this.props.sidebarIsOpen}
        outerContainerId={"layout"}
        width={283}
        pageWrapId={"layout__wrapper"}
        className="sidebar"
        overlayClassName="sidebar__overlay"
      >
        <div className="sidebar__header">
          <CloseSidebar
            className="sidebar__header__close"
            onClick={() => this.props.toggleSidebar(false)}
          />
          <KMLogo className="sidebar__header__logo" />
        </div>
        <NavLink
          onClick={e => this.handleSideBarLink(e, "/jersey")}
          className="sidebar__link"
          activeClassName="sidebar__link-active"
          to="/jersey"
        >
          Футболки
        </NavLink>
        <NavLink
          onClick={e => this.handleSideBarLink(e, "/sweatshirt")}
          className="sidebar__link"
          activeClassName="sidebar__link-active"
          to="/sweatshirt"
        >
          Толстовки
        </NavLink>
        <NavLink
          onClick={e => this.handleSideBarLink(e, "/polo")}
          className="sidebar__link"
          activeClassName="sidebar__link-active"
          to="/polo"
        >
          Рубашки поло
        </NavLink>
        <NavLink
          onClick={e => this.handleSideBarLink(e, "/application")}
          className="sidebar__link"
          activeClassName="sidebar__link-active"
          to="/application"
        >
          Нанесение
        </NavLink>
        <NavLink
          onClick={e => this.handleSideBarLink(e, "/custom-product")}
          className="sidebar__link"
          activeClassName="sidebar__link-active"
          to="/custom_product"
        >
          Изделия под заказ
        </NavLink>
        <NavLink
          onClick={e => this.handleSideBarLink(e, "/works")}
          className="sidebar__link"
          activeClassName="sidebar__link-active"
          to="/works"
        >
          Наши работы
        </NavLink>
        <NavLink
          onClick={e => this.handleSideBarLink(e, "/delivery")}
          className="sidebar__link"
          activeClassName="sidebar__link-active"
          to="/delivery"
        >
          Доставка
        </NavLink>
        <NavLink
          onClick={e => this.handleSideBarLink(e, "/contacts")}
          className="sidebar__link"
          activeClassName="sidebar__link-active"
          to="/contacts"
        >
          Контакты
        </NavLink>
        <NavLink
          onClick={e => this.handleSideBarLink(e, "/faq")}
          className="sidebar__link"
          activeClassName="sidebar__link-active"
          to="/faq"
        >
          Часто задаваемые вопросы
        </NavLink>
        <span onClick={() => this.props.contactManager("phone")} className="sidebar__manager">Связаться с менеджером</span>

        <div className="sidebar__footer">
          <div className="sidebar__footer__container">
            <Viber />
            <Whatsup />
            <Telegram />
            <Email />
          </div>
        </div>
      </Menu>
    );
  }
}

const mapStateToProps = ({ helpers }) => {
  return {
    sidebarIsOpen: helpers.sidebarIsOpen
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleSidebar: flag => dispatch(toggleSidebar(flag)),
    contactManager: tab => dispatch(openConfirmationModal(tab))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Sidebar);
