import React from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";

import Application from ".";
import ApplicationModal from "../../components/ApplicationModal";

const ApplicationResolver = ({ clientWidth }) => (
  <>
    <Route
      path="/application"
      exact={clientWidth < 940}
      component={Application}
    />
    <Route path="/application/:type" component={ApplicationModal} />
  </>
);

const mapStateToProps = ({ helpers }) => {
  return {
    clientWidth: helpers.clientWidth
  };
};

export default connect(mapStateToProps)(ApplicationResolver);
