import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import './index.scss';
import axios from 'axios';
import NewsItem from '../NewsItem';
import Button from '../Button';

class NewsBlock extends Component {

  state = {
    news : []

  }
  componentDidMount() {
    this.getNewsList();
  }

  getNewsList = () => {
    axios
      .get(`/api/v1/news/`)
      .then(({ data }) => {
          console.log('news list', data)
          this.setState({
            news: data
           });
         })
      }

  render() {
    return (
      <div className="newsBlock">
        <h4 className="newsBlock__title">новости</h4>
        <div className="newsBlock__container__wrapper">
          <div className="newsBlock__container">
            { this.state.news.slice(this.state.news.length - 3, this.state.news.length).map(post => <NewsItem key={post.pk} {...post} handler={() => this.props.history.push(`/news/${post.pk}`)} />) }
          </div>
        </div>
        <div className="newsBlock__button ">
          <Button handler={() => this.props.history.push('/news')}>
            Все новости
          </Button>
        </div>
      </div>
    )
  }
}
export default withRouter(NewsBlock);
