import React from 'react';

import '../index.scss';
import close from '../../../img/application/close.svg';
import PreviewCarousel from '../../ApplicationPreviewSlider';

const Sublimation = ({ openCalculatorModal, closeApplicationModal }) => (
  <div className="silk">
    <div className="silk__preview">
      <img
        className="silk__preview__close"
        src={close}
        onClick={closeApplicationModal} />
      <div className="silk__preview__content">
        <PreviewCarousel applicationMethod="sub" />
      </div>
    </div>
    <div className="silk__column">
      <div className="silk__column__content">
        <div className="silk__column__content__group">
          <div className="silk__column__content__caption">
            Сублимация
          </div>
        </div>
        <div className="silk__column__content__text">
          <p>Сублимационная печать — позволяет получить яркие цвета, устойчивые к воздействию окружающей среды фотографического качества, подходит для создания одежды с полной запечаткой, флагов, штор, подушек, платков и т.д.</p>
          <p> Используемая ткань: синтетическая или смесовая минимум 65% полиэстера. Печать производится на белой или молочной ткани </p>
          <p> Рулонная печать: до 160 см в ширину, длина без ограничений. </p>
        </div>
      </div>
    </div>
  </div>
)

export default Sublimation;
