import React from "react";
import Modal from "react-modal";
import fromPairs from "lodash.frompairs";
import { connect } from "react-redux";
import { withRouter, Switch, Route } from "react-router-dom";
import axios from "axios";
import Helmet from "react-helmet";

import "./index.scss";
import { addBasketOrder, updateOrder } from "../../store/order/actions";
import {
  openConfirmationModal,
  toggleProductModal,
  animationCartCounter,
  toggleColorPallete
} from "../../store/helpers/actions";

import {
  setCatalogueColorFilter,
  setCurrentModel,
  setEmptyModel
} from "../../store/catalog/actions";

import ColorPallete from "../../components/ColorPallete";
import ProductAmountLabel from "../../components/ProductAmountLabel";
import Button from "../../components/Button";
import Info from "../../components/ProductModalInfo";
import Sizes from "../../components/ProductModalSizes";
import { ReactComponent as GoBack } from "../../img/productModal/go-back.svg";
import { ReactComponent as CloseModal } from "../../img/productModal/close-modal.svg";
import { formatPrice } from "../../helpers";
import Helmeted from "../../components/Helmeted";

const productModalStyles = {
  content: {
    background: ""
  },
  overlay: {
    position: "fixed",
    top: "0px",
    left: "0px",
    right: "0px",
    bottom: "0px",
    backgroundColor: "rgba(0, 0, 0, 0.28)",
    zIndex: "666"
  }
};

Modal.setAppElement("#root");

class ProductModal extends React.Component {
  container = document.createElement("div");
  state = {
    colorsInStock: [],
    userHasEnteredAmount: false
  };

  closeModal = () => {
    const orderSizesArray = Object.keys(this.props.order.sizes).map(
      key => this.props.order.sizes[key]
    );
    const total = orderSizesArray
      .map(
        sizes => sizes.reduce((acc, value) => Number(acc) + Number(value)),
        0
      )
      .reduce((acc, value) => {
        return acc + Number(value);
      }, 0);

    if (total !== 0) {
      this.props.openConfirmationModal("quit");
    } else {
      const fromBasket = !!(
        this.props.location.state && this.props.location.state.fromBasket
      );
      fromBasket
        ? this.props.history.push("/basket")
        : this.props.history.push(`/${this.props.order.productType}`);
    }
  };

//выбор фоновой картинки в зависимости от гендера
  handleModalBackground = () => {
    let gender = this.props.match.params.gender;
    return `${gender}-background`;
  };


  componentDidMount() {
    this.props.toggleColorPallete(false);
    this.getPalleteColors();
    let color = null;

    const fromBasket = !!(
      this.props.location.state && this.props.location.state.fromBasket
    );
    fromBasket
      ? (color = Object.keys(this.props.order.sizes)[0])
      : (color = color ? color : this.props.catalogueColorFilter);

    let model = this.props.match.params.model;
    let gender = this.props.match.params.gender;
    let productType = this.props.match.params.productType;
    this.props.loadCurrentModel(
      model,
      color,
      fromBasket,
      model,
      gender,
      productType
    );
  }

  componentDidUpdate(prevProps) {
    if (!this.state.userHasEnteredAmount) {
      const editingOrder = !!(
        this.props.location.state &&
        this.props.location.state.fromBasket
      );
      if (prevProps.order.productsCounter === 0 && this.props.order.productsCounter > 0 || editingOrder) {
        this.setState({ userHasEnteredAmount: true });
      }
    }
  }

  handleInfoRedirect = () => {
    let model = this.props.match.params.model;
    let gender = this.props.match.params.gender;
    let productType = this.props.match.params.productType;
    this.props.history.push(`/${productType}/${gender}/${model}/sizes`, {
      isProductModal: true
    });
  };

  validateOrder = () => {
    let order = this.props.order;
    if (!order.productsCounter) {
    }
    const entries = function (obj) {
      var ownProps = Object.keys(obj),
        i = ownProps.length,
        resArray = new Array(i);
      while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]];

      return resArray;
    };
    let validatedSizesArray = entries(order.sizes).filter(orderedColor => {
      let total = orderedColor[1].reduce(
        (acc, value) => Number(acc) + Number(value)
      );
      return total;
    });
    let validatedSizes = fromPairs(validatedSizesArray);
    let validatedOrder = Object.assign({}, order, { sizes: validatedSizes });
    return JSON.parse(JSON.stringify(validatedOrder));
  };

  handleSizesRedirect = () => {
    let { basketOrders, clientWidth } = this.props;
    let order = this.validateOrder();
    let updateBasketOrders = basketOrders.orders.filter(
      basketOrder => basketOrder.id !== order.id
    );
    updateBasketOrders.push(order);

    let updatedGeneralOrder = Object.assign({}, basketOrders, {
      orders: updateBasketOrders
    });
    this.props.addBasketOrder(updatedGeneralOrder);
    if (clientWidth > 940) {
      const fromBasket = !!(
        this.props.location.state && this.props.location.state.fromBasket
      );

      this.props.history.push("/" + this.props.match.params.productType);
      this.props.openConfirmationModal("needMore");
      this.props.animationCartCounterToggle(true);

    } else {
      this.props.history.push("/basket");
    }
  };

  handleRedirect = () => {
    let path = this.props.location.pathname;
    let currentPath = path.substr(path.lastIndexOf("/") + 1);

    switch (currentPath) {
      case "info":
        return this.handleInfoRedirect();
      case "sizes":
        return this.handleSizesRedirect();
      default:
        return null;
    }
  };

//надпись на кнопке в зависимоти от выбранного шага заказа
  handleButtonTitle = () => {
    let path = this.props.location.pathname;
    let currentPath = path.substr(path.lastIndexOf("/") + 1);
    switch (currentPath) {
      case "info":
        return "Выбрать размеры";
      case "sizes":
        return this.props.location.state
          ? this.props.location.state.fromBasket
            ? "Сохранить"
            : "В корзину"
          : "В корзину";
      default:
        return "Выбрать размеры";
    }
  };

//скрывает кнопку заказа если в любой строке размеров меньше 25 штук товара

  handleDisableButton = () => {
    const path = this.props.location.pathname;
    const order = this.props.order;
    if (path.indexOf("sizes") !== -1) {
      
      const isLessThen25 =
        Object.keys(order.sizes)
          .map(
            color => order.sizes[color].reduce((acc, value) => acc + value, 0)
          )
          .findIndex(amount => {
            return amount < 25;
          }) !== -1;
      return isLessThen25;
    } else {
      return false;
    }
  };
//получение цветовой палитры в зависимости от типа товаров
  getPalleteColors = () => {
    let path = this.props.location.pathname;
    let type;
    if (path.indexOf("jersey") !== -1) {
      type = 1;
    } else if (path.indexOf("sweatshirt") !== -1) {
      type = 2;
    } else if (path.indexOf("polo") !== -1) {
      type = 3;
    }
    axios
      .get(`/api/v1/catalog/types/${type}/colors`)
      .then(response => this.setState({ colorsInStock: response.data }));
  };

  colorPalleteHandler = color => {
    let colorToChange = this.props.colorPalleteColorToChange;
    if (colorToChange) {
      this.changeOrderColor(color, colorToChange);
    } else {
      this.addColorToOrder(color);
    }
  };

  changeOrderColor = (colorToAdd, colorToDelete) => {
    let order = Object.assign({}, this.props.order);
    let deletingColorProducts = order.sizes[colorToDelete];
    delete order.sizes[colorToDelete];
    if (colorToAdd in order.sizes) {
      let updatedColorSizes = order.sizes[colorToAdd].map((size, index) => {
        return Number(size) + Number(deletingColorProducts[index]);
      });
      let updatedSizes = Object.assign({}, order.sizes, {
        [colorToAdd]: updatedColorSizes
      });
      let updatedOrder = Object.assign({}, order, { sizes: updatedSizes });
      this.props.updateOrder(updatedOrder);
      this.props.toggleColorPallete(false);
    } else {
      let updatedSizes = Object.assign({}, order.sizes, {
        [colorToAdd]: deletingColorProducts
      });
      let updatedOrder = Object.assign({}, order, { sizes: updatedSizes });
      this.props.updateOrder(updatedOrder);
      this.props.toggleColorPallete(false);
    }
  };

  addColorToOrder = color => {
    const order = this.props.order;
    if (color in order.sizes) {
      return;
    }
    const emptySizesRow = order.sizes[Object.keys(order.sizes)[0]].map(
      size => 0
    );
    const updatedSizes = { ...order.sizes, [color]: emptySizesRow };
    const updatedOrder = Object.assign({}, order, { sizes: updatedSizes });
    this.props.addEmptyOrder(updatedOrder);
    this.props.toggleColorPallete(false);
  };

  handleGoBack = () => {
    const productType = this.props.match.params.productType;
    const gender = this.props.match.params.gender;
    const model = this.props.match.params.model;
    this.props.history.push(`/${productType}/${gender}/${model}/info`, {
      fromSizes: true,
      isProductModal: true
    });
  };
//надпись перед название товара в зависимоти от выбранного типа
  getRusProductType = () => {
    let productType = this.props.match.params.productType;
    switch (productType) {
      case "jersey":
        return "Футболка";
      case "sweatshirt":
        return "Толстовка";
      case "polo":
        return "Поло";
      default:
        return "";
    }
  };

  render() {
    const path = this.props.location.pathname;
    const currentPath = path.substr(path.lastIndexOf("/") + 1);
    const { colorsInStock } = this.state;
    const {
      currentModel,
      colorPalleteIsOpen,
      loadingModel,
      order,
      clientWidth
    } = this.props;

    const isLessThen25 = Object.keys(order.sizes)
      .map(
        color => order.sizes[color].reduce((acc, value) => acc + value),
        0
      ).findIndex(amount => {
        return amount < 25;
      }) !== -1;


    return (
      <div>
        <Helmeted />
        {colorPalleteIsOpen ? (
          <ColorPallete
            colorsInStock={colorsInStock}
            handler={this.colorPalleteHandler}
          />
        ) : null}
        {clientWidth > 940 ? (
          <Modal
            isOpen={true}
            onRequestClose={this.closeModal}
            style={productModalStyles}
            className={["MainModal", this.handleModalBackground()].join(" ")}
            closeTimeoutMS={700}
            overlayClassName={{
              base: "myOverlayClass",
              afterOpen: "myOverlayClass_after-open",
              beforeClose: "myOverlayClass_before-close"
            }}
          >
            <div className="productModal__header">
              <span className="productModal__header__title">
                {!loadingModel
                  ? `${this.getRusProductType()} ${currentModel.name}`
                  : ""}
              </span>
              <CloseModal
                className="productModal__close"
                onClick={this.closeModal}
              />
            </div>
            <Switch>
              <Route
                path="/:productType/:gender/:model/info"
                component={Info}
              />
              <Route
                path="/:productType/:gender/:model/sizes"
                render={props => (
                  <Sizes
                    {...props}
                    colorsInStock={colorsInStock}
                    error={this.state.error}
                  />
                )}
              />
            </Switch>
            {loadingModel ? null : (
              <div className="productModal__footer">
                {currentPath !== "info" && (
                  <div
                    className="productModal__footer__goback"
                    onClick={this.handleGoBack}
                  >
                    <GoBack className="productModal__footer__goback__icon" />
                    <span>Назад</span>
                  </div>
                )}
                {currentPath === "sizes" && (
                  <div className="productModal__price">
                    <span className="productModal__price__label">
                      Стоимость:
                    </span>
                    <span className="productModal__price__value">
                      {formatPrice(order.totalPrice)} руб
                    </span>
                  </div>
                )}

                <div className="productModal__footer__buttonwr">
                  <Button
                    disabled={this.handleDisableButton()}
                    handler={this.handleRedirect}
                  >
                    {this.handleButtonTitle()}
                  </Button>
                </div>
                <div className={[
                  "productModal__hint__wrapper",
                  this.state.userHasEnteredAmount && isLessThen25 ? "productModal__hint--show" : "productModal__hint--hide"
                ].join(" ")}>
                  <span className="productModal__hint__warning">!</span>
                  <span className="productModal__hint__text">Минимальный заказ в одном цвете 25 шт</span>
                </div>
              </div>
            )}
          </Modal>
        ) : (
            <div className="productModal__mobile">
              <Switch>
                <Route
                  path="/:productType/:gender/:model/info"
                  component={Info}
                />
                <Route
                  path="/:productType/:gender/:model/sizes"
                  render={props => (
                    <Sizes
                      {...props}
                      colorsInStock={this.state.colorsInStock}
                      showHint={this.state.userHasEnteredAmount && isLessThen25} />
                  )}
                />
              </Switch>
              {loadingModel ? null : (
                <div className="productModal__footer">
                  {currentPath === "sizes" && (
                    <div className="productModal__footer__counter">
                      <span>В заказе:</span>
                      <ProductAmountLabel amount={order.productsCounter} />
                    </div>
                  )}

                  {currentPath === "sizes" && (
                    <div className="productModal__price">
                      <span className="productModal__price__label">
                        Стоимость:
                    </span>
                      <span className="productModal__price__value">
                        {formatPrice(order.totalPrice)} руб
                    </span>
                    </div>
                  )}

                  <div className="productModal__footer__buttonwr">
                    <Button
                      disabled={this.handleDisableButton()}
                      handler={this.handleRedirect}
                    >
                      {this.handleButtonTitle()}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}
      </div>
    );
  }
}

const mapStateToProps = ({ order, catalog, helpers }) => {
  return {
    productModalIsOpen: helpers.productModalIsOpen,
    order: order.order,
    basketOrders: order.basketOrders,
    deliveryFormIsValid: helpers.deliveryFormIsValid,
    clientWidth: helpers.clientWidth,
    animationCartCounter: helpers.animationCartCounter,
    currentModel: catalog.currentModel,
    catalogueColorFilter: catalog.catalogueColorFilter,
    colorPalleteIsOpen: helpers.colorPalleteIsOpen,
    loadingModel: catalog.loadingModel,
    colorPalleteColorToChange: helpers.colorPalleteColorToChange
  };
};

const mapDispatchToProps = dispatch => {
  return {
    animationCartCounterToggle: flag => dispatch(animationCartCounter(flag)),
    openConfirmationModal: tab => dispatch(openConfirmationModal(tab)),
    toggleModalProductIsOpen: flag => dispatch(toggleProductModal(flag)),
    addBasketOrder: order => dispatch(addBasketOrder(order)),
    addEmptyOrder: order => dispatch(updateOrder(order)),
    loadCurrentModel: (modelId, hex, fromBasket, model, gender, productType) =>
      dispatch(
        setCurrentModel(modelId, hex, fromBasket, model, gender, productType)
      ),
    setEmptyModel: () => dispatch(setEmptyModel()),
    setEmptyOrder: updatedOrder => dispatch(updateOrder(updatedOrder)),
    setCatalogueColorFilter: color => dispatch(setCatalogueColorFilter(color)),
    toggleColorPallete: flag => dispatch(toggleColorPallete(flag)),
    updateOrder: order => dispatch(updateOrder(order))
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ProductModal)
);
