import React from 'react';
import { withRouter } from 'react-router-dom';

import './index.scss';
import Color from '../../components/Color';
import { ReactComponent as FilterIcon } from '../../img/catalogue/mobile-filter.svg';
import colorPicker from '../../img/catalogue/color.png';

class MobileFilters extends React.Component {
  filters = React.createRef();
  state = {
    currentFilter: null,
    showCustomColors: false
  }

  componentDidMount() {
    document.addEventListener('click', this.handleOutsideClick, false);
  }
  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick, false);
  }
  // static getDerivedStateFromProps(nextProps, prevState) {
  //   console.log(nextProps, prevState);
  //   if (nextProps.colorsInStock.length === 0) {
  //     return { showCustomColors: true }
  //   }

  //   return null;
  // }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      if (prevProps.colorsInStock.length > 0) {
        this.setState({ showCustomColors: true })
      }
    }
  }

  handleColorFilter = () => {
    if (this.state.currentFilter === 'color') {
      this.setState({ currentFilter: null })
    } else {
      this.setState({ currentFilter: 'color' })
    }
  }

  handleSizeFilter = () => {
    if (this.state.currentFilter === 'filter') {
      this.setState({ currentFilter: null })
    } else {
      this.setState({ currentFilter: 'filter' })
    }
  }

  handleOutsideClick = (e) => {
    if (this.filters) {
      if (this.filters.current.contains(e.target)) {
        return
      } else {
        this.setState({ currentFilter: null })
      }
    }
  }

  handleColorPick = hex => {
    this.props.changeColor(hex);
    this.setState({ currentFilter: null })
  }

  handleGenderPick = gender => {
    this.props.changeGender(gender);
    this.setState({ currentFilter: null })
  }

  render() {
    const { currentFilter, showCustomColors } = this.state;
    const { color, gender, colorsInStock, customColors } = this.props;

    return (
      <div className="mobileFilters__menu">
        <div ref={this.filters}>
          <div
            className="mobileFilters__item mobileFilters__item-first" >
            <div
              onClick={() => this.handleColorFilter('color')}
              className={[
                "mobileFilters__item__block",
                currentFilter === 'color' ? "mobileFilters__item-showColors" : ""
              ].join(" ")}>
              {!color ?
                <img src={colorPicker} className="mobileFilters__item__icon" /> :
                <Color color={color} />}
              <span className="mobileFilters__item__label">
                Цвет изделия
              </span>
              <div
                className={[
                  "mobileFilters__item__footer",
                  currentFilter === 'color' ? "mobileFilters__item__footer-first" : ""
                ].join(" ")}></div>
            </div>
            <div
              className={[
                "mobileFilters__item__block",
                currentFilter === 'filter' ? "mobileFilters__item-showFilters" : ""
              ].join(" ")}
              onClick={() => this.handleSizeFilter('filter')}
              style={{
                zIndex: currentFilter === 'filter' ? "mobileFilters__item-showFilters" : ""
              }}>
              <FilterIcon className="mobileFilters__item__icon-filter" />
              <span className="mobileFilters__item__label">
                Фильтры
              </span>
              <div className={[
                "mobileFilters__item__footer",
                currentFilter === 'filter' ? "mobileFilters__item__footer-second" : ""
              ].join(" ")}></div>
            </div>
          </div>
          <div className="mobileFilters__colorPallete"
            style={{
              display: currentFilter === 'color' ? 'block' : 'none'
            }}>
            <div className="mobileFilters__colorPallete__header">
              {colorsInStock.length > 0 && <span
                onClick={() => this.setState({ showCustomColors: false })}
                className={[
                  "mobileFilters__colorPallete__header__label",
                  !showCustomColors ? "mobileFilters__colorPallete__header__label--active" : "",
                  "mobileFilters__colorPallete__header__label__instock"].join(" ")}>В наличии</span>}
              {customColors.length > 0 && <span
                onClick={() => this.setState({ showCustomColors: true })}
                className={[
                  "mobileFilters__colorPallete__header__label",
                  showCustomColors ? "mobileFilters__colorPallete__header__label--active" : "",
                  "mobileFilters__colorPallete__header__label__custom"].join(" ")}>Под заказ</span>}
            </div>
            {!showCustomColors && <div className="mobileFilters__colorPallete__colors">
              {colorsInStock.map(color =>
                <Color
                  handler={() => this.handleColorPick(color.hex)}
                  color={color.hex}
                  key={color.id} />)}
            </div>}
            {showCustomColors && <div className="mobileFilters__colorPallete__colors">
              {customColors.map(color =>
                <Color
                  handler={() => this.handleColorPick(color.hex)}
                  color={color.hex}
                  key={color.id} />)}
            </div>}
          </div>
          <div
            className="mobileFilters__filter__wrapper"
            style={{
              display: currentFilter === 'filter' ? 'block' : 'none'
            }}>
            <div
              onClick={() => this.handleGenderPick('male')}
              className={[
                "mobileFilters__filter mobileFilters__item__label",
                gender === 'male' ? "catalogue__filters__item-active" : ''
              ].join(" ")}>
              Мужские
            </div>
            <div
              onClick={() => this.handleGenderPick('female')}
              className={[
                "mobileFilters__filter mobileFilters__item__label",
                gender === 'female' ? "catalogue__filters__item-active" : ''
              ].join(" ")}>
              Женские
            </div>
            <div
              onClick={() => this.handleGenderPick('children')}
              className={[
                "mobileFilters__filter mobileFilters__item__label",
                gender === 'children' ? "catalogue__filters__item-active" : ''
              ].join(" ")}>
              Детские
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default withRouter(MobileFilters);