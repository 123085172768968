import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import "./index.scss";
import { updateOrder, addBasketOrder } from "../../store/order/actions";
import {
  toggleProductModal,
  openConfirmationModal
} from "../../store/helpers/actions";
import OrderTable from "../OrderTable";
import AmountLabel from "../ProductAmountLabel";
import PreviewProductSmall from "../PreviewProductSmall";
import { ReactComponent as DeleteButton } from "../../img/basket/delete.svg";
import { ReactComponent as ScrollPointer } from "../../img/productModal/scroll-pointer.svg";
import { formatPrice } from "../../helpers";

class TabletBasketItem extends React.Component {
  deleteOrder = orderId => {
    const { basketOrders } = this.props;
    const updatedBasketOrders = basketOrders.orders.filter(
      order => order.id !== orderId
    );
    const updatedGeneralOrder = Object.assign({}, basketOrders, {
      orders: updatedBasketOrders
    });
    this.props.updateBasketOrders(updatedGeneralOrder);
  };

  redirectToEditOrder = () => {
    if (this.props.location.pathname === "/basket") {
      this.props.setEditingOrder(this.props.order);
      this.props.openProductModal(true);
      let id = this.props.order.model;
      let gender = this.props.order.gender;
      let productType = this.props.order.productType;
      this.props.history.push(`/${productType}/${gender}/${id}/sizes`, {
        fromBasket: true,
        isProductModal: true
      });
    }
  };

  getRusProductType = () => {
    return "";
    const productType = this.props.order.productType;
    switch (productType) {
      case "jersey":
        return "Футболка";
      case "sweatshirt":
        return "Толстовка";
      case "polo":
        return "Поло";
      default:
        return "";
    }
  };

  render() {
    let { order, basket, orderItem } = this.props;
    let sizes = order.sizes;
    let sizesArrayLength = Object.keys(sizes).map(key => sizes[key])[0].length;
    return (
      <div
        className="tabletBasketItem__wrapper-main"
        style={{ cursor: basket ? "pointer" : "default" }}
      >
        {orderItem ? null : (
          <DeleteButton
            className="tabletBasketItem__delete"
            onClick={() => this.deleteOrder(order.id)}
          />
        )}
        {sizesArrayLength > 7 ? (
          <div className="tabletBasketItem__tables__scrollContainer">
            <div>
              <ScrollPointer className="tabletBasketItem__tables__scrollContainer__arrow" />
              <ScrollPointer className="tabletBasketItem__tables__scrollContainer__arrow" />
            </div>
          </div>
        ) : null}
        <div
          onClick={this.redirectToEditOrder}
          className={[
            "tabletBasketItem",
            orderItem ? "tabletBasketItem__orderItem" : ""
          ].join(" ")}
        >
          <div className="tabletBasketItem__header">
            <span className="tabletBasketItem__title">
              {`${this.getRusProductType()} ${order.modelName}`}
            </span>
            <div className="tabletBasketItem__order">
              <span className="tabletBasketItem__label">В заказе:</span>
              <AmountLabel amount={order.productsCounter} />
            </div>
          </div>
          <div className="tabletBasketItem__wrapper">
            <div
              className={[
                "tabletBasketItem__preview",
                orderItem ? "tabletBasketItem__orderItemPreview" : ""
              ].join(" ")}
            >
              <PreviewProductSmall
                previews={order.layeredPreviews}
                orderItem={orderItem}
              />
            </div>
            <div className="tabletBasketItem__body">
              <div
                className={[
                  "tabletBasketItem__tables",
                  orderItem ? "tableOrder" : "tableBasket",
                  sizesArrayLength > 7
                    ? "tabletBasketItem__tables__childrenTable"
                    : ""
                ].join(" ")}
              >
                <OrderTable
                  sizesTitles={order.sizesTitles}
                  sizes={order.sizes}
                  small
                  basket={basket}
                  orderItem={orderItem}
                />
              </div>
              <div className="tabletBasketItem__prints__wrapper">
                <div className="tabletBasketItem__prints" />
                <div className="tabletBasketItem__prints__price">
                  <span className="tabletBasketItem__prints__price__label">
                    Стоимость:
                  </span>
                  <span className="tabletBasketItem__prints__price__value">
                    {formatPrice(order.totalPrice)} руб
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ order, helpers }) => {
  return {
    basketOrders: order.basketOrders,
    clientWidth: helpers.clientWidth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setEditingOrder: orderToEdit => dispatch(updateOrder(orderToEdit)),
    updateBasketOrders: updatedBasketOrders =>
      dispatch(addBasketOrder(updatedBasketOrders)),
    openProductModal: flag => dispatch(toggleProductModal(flag)),
    openConfirmationModal: (tab, payload) =>
      dispatch(openConfirmationModal(tab, payload))
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(TabletBasketItem)
);
