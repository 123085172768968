import {
  takeEvery,
  call,
  put,
  all,
  select
} from "redux-saga/effects";
import {
  delay
} from "redux-saga";

import {
  api
} from "../services";
import {
  setEmptyOrder
} from "../store/order/actions";
import * as actionTypes from "../store/catalog/actionTypes";
import {
  formatTypes
} from "../helpers";
import {
  getColorFilter,
  getGenderFilter,
  getTextFilter,
  getModels,
  getTypes,
  getType,
  getAllColors
} from "./selectors";

function* loadCatalog(action) {
  yield put({
    type: actionTypes.GET_FILTERED_MODELS,
    filteredModels: []
  });
  yield put({
    type: actionTypes.SET_CATALOGUE_COLOR_FILTER,
    color: null
  });
  yield put({
    type: actionTypes.SET_GENDER_FILTER,
    genderFilter: ""
  });
  yield all([call(fetchTypes), call(fetchColors)]);
  yield call(setTypeFilter, action);
}

function* fetchTypes() {
  const types = yield call(api.fetchTypes);
  const formattedTypes = formatTypes(types);
  yield put({
    type: actionTypes.FETCH_TYPES_SUCCESS,
    types: formattedTypes
  });
}

function* fetchColors() {
  const colors = yield call(api.fetchColors);

  yield put({
    type: actionTypes.FETCH_COLORS_SUCCESS,
    colors: colors
  });
}

function* fetchColorsPerType() {
  const colors = yield select(getAllColors);
  const type = yield select(getType);
  const colorsInStock = yield call(api.fetchTypeColors, type);
  const customColors = colors.filter(color => {
    return (
      colorsInStock.findIndex(colorInStock => colorInStock.id === color.id) ===
      -1
    );
  });

  yield put({
    type: actionTypes.FETCH_TYPE_COLORS,
    colorsInStock: colorsInStock,
    customColors: customColors
  });
}

function* fetchColoredModels(action) {
  yield put({
    type: actionTypes.SET_CATALOGUE_COLOR_FILTER,
    color: action.color
  });
  yield call(fetchModels);
}

function* fetchModels() {
  const color = yield select(getColorFilter);
  const type = yield select(getType);
  const models = yield call(api.fetchModels, type, color);
  yield put({
    type: actionTypes.FETCH_MODELS_SUCCESS,
    models: models
  });

  yield call(filterCatalog);
}

function* fetchModel(action) {
  yield put({
    type: actionTypes.MODEL_IS_LOADING,
    flag: true
  });

  const model = yield call(api.fetchModel, action.id, action.hex);
  yield put({
    type: actionTypes.SET_CURRENT_MODEL,
    model: model
  });
  if (!action.fromBasket) {
    yield put(
      setEmptyOrder({
        hex: action.hex ? action.hex : model.preview.hex ? model.preview.hex : "#ffffff",
        previews: model.preview.images,
        price_grid: model.price_grid,
        size_info: model.size_info,
        modelName: model.name,
        model: action.model,
        gender: action.gender,
        productType: action.productType
      })
    );
  }
  yield put({
    type: actionTypes.MODEL_IS_LOADING,
    flag: false
  });
}

function* setTypeFilter(action) {
  yield put({
    type: actionTypes.GET_FILTERED_MODELS,
    filteredModels: []
  });
  // yield put({
  //   type: actionTypes.SET_CATALOGUE_COLOR_FILTER,
  //   color: null
  // });
  // yield put({
  //   type: actionTypes.SET_GENDER_FILTER,
  //   genderFilter: ""
  // });
  const types = yield select(getTypes);
  let typeFilter;

  if (action.typeFilter === "polo") {
    typeFilter = 3;
  } else {
    typeFilter = types.filter(type => type.title === action.typeFilter)[0].id;
  }

  yield put({
    type: actionTypes.SET_TYPE_FILTER,
    typeFilter: typeFilter
  });

  yield all([call(fetchColorsPerType), call(fetchModels)]);
}

function* filterCatalog() {
  const genderFilter = yield select(getGenderFilter);
  const textFilter = yield select(getTextFilter);
  const models = yield select(getModels);

  const filteredModels = models.filter(model =>
    genderFilter ?
    model.gender === genderFilter :
    true && model.name.toLowerCase().indexOf(textFilter.toLowerCase()) !== -1
  );

  yield put({
    type: actionTypes.GET_FILTERED_MODELS,
    filteredModels: filteredModels
  });
}

function* fetchOurWorks() {
  try {
    const ourWorks = yield call(api.fetchOurWorks);
    yield put({
      type: actionTypes.FETCH_OUR_WORKS_SUCCESS,
      ourWorks: ourWorks
    });
  } catch (error) {
    throw error;
  }
}

function* animateBasket() {
  yield put({
    type: "BASKET_IS_ANIMATING",
    flag: true
  });
  yield call(delay, 2000);
  yield put({
    type: "BASKET_IS_ANIMATING",
    flag: false
  });
}

const watchFetchColors = takeEvery(actionTypes.FETCH_COLORS, fetchColors);
const watchFetchOurWorks = takeEvery(
  actionTypes.FETCH_OUR_WORKS,
  fetchOurWorks
);
const watchFetchTypes = takeEvery(actionTypes.FETCH_TYPES, loadCatalog);
const watchGenderFilterChange = takeEvery(
  actionTypes.SET_GENDER_FILTER,
  filterCatalog
);
const watchTextFilterChange = takeEvery(
  actionTypes.SET_TEXT_FILTER,
  filterCatalog
);

const watchColorFilterChange = takeEvery(
  actionTypes.FETCH_COLORED_MODELS,
  fetchColoredModels
);

const watchTypeFilterChange = takeEvery(
  actionTypes.REQUEST_TYPE,
  setTypeFilter
);

const watchGetModel = takeEvery(actionTypes.FETCH_MODEL, fetchModel);

const watchBasketAnimation = takeEvery("ANIMATE_BASKET", animateBasket);

export default function* rootSaga() {
  yield all([
    watchFetchColors,
    watchFetchOurWorks,
    watchFetchTypes,
    watchGenderFilterChange,
    watchTextFilterChange,
    watchTypeFilterChange,
    watchColorFilterChange,
    watchGetModel,
    watchBasketAnimation
  ]);
}
