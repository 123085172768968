import React, { Component } from "react";

import "./index.scss";
import Button from "../Button";
import { ReactComponent as GoBack } from "../../img/productModal/go-back.svg";

class CalculatorContacts extends Component {
  state = {
    contacts: {
      phone: ""
    },
    touched: {
      phone: false
    },
    valid: {
      phone: false
    }
  };

  handlePhoneChange = e => {
    let value = e.target.value;
    if (/^(?=.*[+0-9])[- +()0-9]+$/i.test(value) || value === "") {
      this.setState(
        { contacts: { ...this.state.contacts, phone: e.target.value } },
        () => {
          this.checkInputsValidity();
        }
      );
    }
  };

  validateContacts = () => {
    const contacts = this.state.contacts;
    return (
      [
        contacts.phone.length > 6
      ].indexOf(false) === -1
    );
  };

  checkInputsValidity = () => {
    const { contacts } = this.state;
    const phonePattern = /^[0-9,.()-+]*$/;
    this.setState({
      valid: {
        phone: contacts.phone.length >= 11 && phonePattern.test(contacts.phone)
      }
    });
  };

  submitOrder = () => {
    if (this.validateContacts()) {
      this.props.submitOrder(this.state.contacts.phone);
    }
  };

  handleTouched = label => {
    this.setState({
      touched: {
        ...this.state.touched,
        [label]: true
      }
    });
  };

  render() {
    const { user, phone, email } = this.state.contacts;
    const { touched, valid } = this.state;
    return (
      <div className="calculator__frame">
        <div className="calculator__contacts">
          <div
            className="calculator__contacts__goback"
            onClick={this.props.goback}
          >
            <GoBack className="calculator__contacts__goback-icon" />
            <span>Назад</span>
          </div>
          <h4 className="calculator__contacts__title">Контактная информация</h4>
          <div className="calculator__contacts__input__group">
            {/* <input
              placeholder="Имя"
              className={[
                "calculator__contacts__input",
                touched.user && !valid.user
                  ? "calculator__contacts__input--invalid"
                  : ""
              ].join(" ")}
              type="text"
              value={user}
              onBlur={() => this.handleTouched("user")}
              onChange={this.handleNameChange} />
            <input
              placeholder="Email"
              className={[
                "calculator__contacts__input",
                touched.email && !valid.email
                  ? "calculator__contacts__input--invalid"
                  : ""
              ].join(" ")}
              type="email"
              value={email}
              onBlur={() => this.handleTouched("email")}
              onChange={this.handleEmailChange}
            /> */}
            <span className="calculator__contacts__input__label">Номер телефона</span>
            <input
              className={[
                "calculator__contacts__input",
                touched.phone && !valid.phone
                  ? "calculator__contacts__input--invalid"
                  : ""
              ].join(" ")}
              type="tel"
              value={phone}
              maxLength="11"
              onBlur={() => this.handleTouched("phone")}
              onChange={this.handlePhoneChange}
            />
          </div>
          <div className="calculator__contacts__footer">
            <div className="calculator__contacts__footer__info">
              * Менеджер свяжется с Вами в самое ближайшее время
            </div>
            <Button handler={this.submitOrder}>Отправить</Button>
          </div>
        </div>
      </div>
    );
  }
}

export default CalculatorContacts;
