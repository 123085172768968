import React from "react";

import "./index.scss";
import { ReactComponent as PhotoNotAvailable } from "../../img/shirtPhotoNotAvailable.svg";

const Model = ({
  preview,
  model,
  cost,
  getModelInfo,
  productType,
  gender,
  id,
  rusProductType
}) => (
    <div onClick={() => getModelInfo(productType, gender, id)} className="model">
      <div className="model__image">
        {preview && preview.length > 1 ? (
          <img src={preview} alt={model} />
        ) : (
            <div className="model__nopreview">
              <PhotoNotAvailable className="model__nopreview__icon" />
              <span className="model__nopreview__label">
                Данная модель есть в другом цвете
          </span>
            </div>
          )}
      </div>
      <div className="model__caption">
        <span className="model__caption__title">{model}</span>
      </div>
      <div className="model__price">
        <span className="model__price__value">{cost} Руб</span>
      </div>
    </div>
  );

export default Model;
