import React from 'react';

import '../index.scss';

const Contacts = ({ submitForm, inputHandler, cancel }) => (
  <div className="modalContacts">
    <div className="modalContacts__header">
      Контактная информация для связи с нами
    </div>
    <form
      className="modalContacts__form"
      onSubmit={submitForm}>
      <input
        type="text"
        className="modalContacts__input"
        placeholder="Имя"
        onChange={(e) => inputHandler(e, 'name')}
        required />
      <input
        type="email"
        className="modalContacts__input"
        placeholder="E-mail"
        onChange={(e) => inputHandler(e, 'email')}
        required />
      <input
        type="tel"
        className="modalContacts__input"
        placeholder="Телефон"
        onChange={(e) => inputHandler(e, 'phone')}
        required />
      <div className="modalContacts__wrapper">
        <button
          onClick={cancel}
          className="closeModal__button">
          Отмена
        </button>
        <button
          className="closeModal__button">
          Готово
        </button>
      </div>
    </form>
  </div>
)

export default Contacts;