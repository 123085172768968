import React from 'react';

import '../index.scss';
import Button from '../../Button';

const Gratitude = ({ close }) => (
  <div className="gratitude">
    <span className="gratitude__title">
      Заказ отправлен в корзину
    </span>
    <Button
      handler={close}
      className="gratitude__button">
      Ок
    </Button>
  </div>
)

export default Gratitude;