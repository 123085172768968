import React from "react";

import "./index.scss";
import { ReactComponent as Claim } from "../../img/customProduct/claim.svg";
import { ReactComponent as Consultation } from "../../img/customProduct/consultation.svg";
import { ReactComponent as Deal } from "../../img/customProduct/deal.svg";
import { ReactComponent as Manufacture } from "../../img/customProduct/manufacture.svg";
import { ReactComponent as Delivery } from "../../img/customProduct/delivery.svg";
import { ReactComponent as Next } from "../../img/customProduct/next.svg";

const WorkScheme = () => (
  <div className="workScheme">
    <h3 className="workScheme__title">схема нашей работы</h3>
    <div className="workScheme__items">
      <div className="workScheme__item">
        <Claim />
        <span className="workScheme__item__label">1. заявка</span>
      </div>
      <Next className="workScheme__next" />
      <div className="workScheme__item">
        <Consultation />
        <span className="workScheme__item__label">
          2. консультация и расчет
        </span>
      </div>
      <Next className="workScheme__next" />
      <div className="workScheme__item">
        <Deal />
        <span className="workScheme__item__label">3. договор и оплата</span>
      </div>
      <Next className="workScheme__item__icon workScheme__next" />
      <div className="workScheme__item workScheme__item-manufacture">
        <Manufacture />
        <span className="workScheme__item__label">4. производство</span>
      </div>
      <Next className="workScheme__next" />
      <div className="workScheme__item workScheme__item-delivery">
        <Delivery />
        <span className="workScheme__item__label">5. доставка</span>
      </div>
    </div>
  </div>
);

export default WorkScheme;
