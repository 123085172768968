import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import "./index.scss";
import { updateOrder, addBasketOrder } from "../../store/order/actions";
import {
  toggleProductModal,
  openConfirmationModal
} from "../../store/helpers/actions";
import MobileBasketItemRow from "../MobileBasketRow";
import AmountLabel from "../ProductAmountLabel";
import PreviewProductSmall from "../PreviewProductSmall";
import { ReactComponent as DeleteButton } from "../../img/productModal/delete-small.svg";
import { formatPrice } from "../../helpers";

class MobileBasketItem extends React.Component {
  deleteOrder = orderId => {
    const { basketOrders } = this.props;
    const updatedBasketOrders = basketOrders.orders.filter(
      order => order.id !== orderId
    );
    const updatedGeneralOrder = Object.assign({}, basketOrders, {
      orders: updatedBasketOrders
    });
    this.props.updateBasketOrders(updatedGeneralOrder);
  };

  redirectToEditOrder = () => {
    if (this.props.location.pathname === "/basket") {
      this.props.setEditingOrder(this.props.order);
      this.props.openProductModal(true);
      let id = this.props.order.model;
      let gender = this.props.order.gender;
      let productType = this.props.order.productType;
      this.props.history.push(`/${productType}/${gender}/${id}/sizes`, {
        fromBasket: true
      });
    }
  };

  getMobileSize = () => {
    let { order } = this.props;
    let orderColors = Object.keys(order.sizes);
    let mobileBasketItemRows = [];

    orderColors
      .map(key => order.sizes[key])
      .forEach((sizesArray, colorIndex) => {
        sizesArray.forEach((sizeAmount, sizeAmountIndex) => {
          if (sizeAmount > 0) {
            mobileBasketItemRows.push({
              size: sizeAmountIndex,
              amount: sizeAmount,
              color: orderColors[colorIndex]
            });
          }
        });
      });
    return mobileBasketItemRows;
  };

  getSizesLabel = index => {
    return this.props.order.sizesTitles[index];
  };
  getRusProductType = () => {
    return "";
    const productType = this.props.order.productType;
    switch (productType) {
      case "jersey":
        return "Футболка";
      case "sweatshirt":
        return "Толстовка";
      case "polo":
        return "Поло";
      default:
        return "";
    }
  };

  render() {
    const { order, orderItem } = this.props;

    return (
      <div
        className="mobileBasketItem__wrapper"
        style={{
          cursor:
            this.props.location.pathname === "/basket" ? "pointer" : "default"
        }}
      >
        {orderItem ? null : (
          <DeleteButton
            className="mobileBasketItem__delete"
            onClick={() => this.deleteOrder(order.id)}
          />
        )}
        <div
          onClick={this.redirectToEditOrder}
          className={[
            "mobileBasketItem",
            orderItem ? "mobileBasketItem__orderItem" : ""
          ].join(" ")}
        >
          <div className="mobileBasketItem__header">
            <span className="mobileBasketItem__title">
              {`${this.getRusProductType()} ${order.modelName}`}
            </span>
            <div className="mobileBasketItem__order">
              <span className="mobileBasketItem__label">В заказе:</span>
              <AmountLabel amount={order.productsCounter} />
            </div>
          </div>
          <div className="mobileBasketItem__wrapper">
            <div
              className={[
                "mobileBasketItem__preview",
                orderItem ? "mobileBasketItem__orderItemPreview" : ""
              ].join(" ")}
            >
              <PreviewProductSmall
                previews={order.layeredPreviews}
                orderItem={orderItem}
              />
            </div>
            <div className="mobileBasketRow__header">
              <span className="mobileBasketRow__header__label">Цвет</span>
              <span className="mobileBasketRow__header__label">Размер</span>
              <span className="mobileBasketRow__header__label">Кол-во</span>
            </div>
            {this.getMobileSize().map((size, index) => (
              <MobileBasketItemRow
                {...size}
                size={this.getSizesLabel(size.size)}
                key={this.getSizesLabel(size.size) + index}
              />
            ))}
          </div>
          <div className="mobileBasketItem__summ">
            <span className="mobileBasketItem__summ__label">Стоимость:</span>
            <div>
              <span className="mobileBasketItem__summ__value">
                {formatPrice(order.totalPrice)}
              </span>
              <span className="mobileBasketItem__summ__currency">руб</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ order }) => {
  return {
    basketOrders: order.basketOrders
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setEditingOrder: orderToEdit => dispatch(updateOrder(orderToEdit)),
    updateBasketOrders: updatedBasketOrders =>
      dispatch(addBasketOrder(updatedBasketOrders)),
    openProductModal: flag => dispatch(toggleProductModal(flag)),
    openConfirmationModal: (tab, payload) =>
      dispatch(openConfirmationModal(tab, payload))
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MobileBasketItem)
);
