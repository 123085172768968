import React from "react";
import { connect } from "react-redux";

import "./index.scss";
import { openConfirmationModal } from "../../store/helpers/actions";
import ReviewCarousel from "../../components/ReviewCarousel";
import OurWorks from "../../components/OurWorks";
import Facilities from "../../components/Facilities";
import WorkScheme from "../../components/WorkScheme";
import CustomOrder from "../../components/CustomOrder";
import Helmeted from "../../components/Helmeted";

const CustomProduct = ({ ourWorks, openConfirmationModal }) => (
  <div className="customProduct page">
    <Helmeted />
    <div className="customProduct__info">
      <h3 className="customProduct__info__title">Изделия под заказ</h3>
      <p className="customProduct__info__description">
        Компания Futbolka Market предлагает широкий ассортимент изделий любых
        моделей и размеров. Наличие собственного производства, оборудованного по
        современным технологиям, позволяет нашей компании выполнять заказы по
        пошиву в короткие сроки, качественно и по доступным ценам. Пошив
        происходит с использованием самой лучшей фурнитуры и тканей, как по
        собственным лекалам, так и по эскизам заказчика.
      </p>
    </div>
    <Facilities />
    <WorkScheme />
    <OurWorks ourWorks={ourWorks.slice(0, 5)} />
    <CustomOrder
      openCalculatorModal={() => openConfirmationModal("calculateOrder")}
    />
    <ReviewCarousel />
  </div>
);

const mapStateToProps = ({ catalog }) => {
  return {
    ourWorks: catalog.ourWorks
  };
};

const mapDispatchToProps = dispatch => {
  return {
    openConfirmationModal: tab => dispatch(openConfirmationModal(tab))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomProduct);
