import React from 'react';

import './index.scss';
import Color from '../Color';

const MobileBasketRow = ({ size, amount, color }) => (
  <div className="mobileBasketRow">
    <div className="mobileBasketRow__item">
      <div className="mobileBasketRow__item__box">
        <Color color={color} />
      </div>
      <div className="mobileBasketRow__item__box">
        {size}
      </div>
      <div className="mobileBasketRow__item__box">
        {amount} шт
      </div>
    </div>
  </div>);

export default MobileBasketRow;