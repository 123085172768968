import React from 'react';
import Slider from 'react-slick';

import './index.scss';
import Review from './Review';
import reviewList from './reviews.json';

const sliderSettings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  initialSlide: 0,
  arrows: false,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 780,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

const Reviews = () => (
  <section className="reviewCarousel__reviews">
    <h3 className="reviewCarousel__reviews__title">
      отзывы о нас
    </h3>
    <div className="reviewCarousel__reviews__wrapper">
      <Slider {...sliderSettings}>
        {reviewList.map(review =>
          <div
            className="review__wrapper"
            key={review.author}>
            <Review {...review} />
          </div>)}
      </Slider>
    </div>
  </section>
);

export default Reviews;