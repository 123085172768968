import React from 'react';

import '../index.scss';
import Button from '../../Button';

const Claim = ({ closeModal }) => (
  <div className="claimSuccess manager">
    <h4 className="manager__title">
      Спасибо за вашу заявку!
    </h4>
    <p className="manager__description claimSuccess__description">
      Менеджер свяжется с Вами с самое ближайшее время
    </p>
    <div className="manager__button-wrapper">
      <Button handler={closeModal}>
        Oк
      </Button>
    </div>
  </div>
)

export default Claim;