import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import "./index.scss";
import {
  setCatalogueColorFilter,
  getColors,
  getTypes,
  setGenderFilter,
  setTextFilter,
  setTypeFilter
} from "../../store/catalog/actions";
import {
  toggleColorPallete,
  toggleProductModal
} from "../../store/helpers/actions";
import ColorPallete from "../ColorPallete";
import Color from "../Color";
import MobileFilters from "../MobileFIlters";
import { ReactComponent as ClearInput } from "../../img/delete.svg";
import { ReactComponent as ColorPicker } from "../../img/catalogue/color.svg";
import { ReactComponent as Loop } from "../../img/catalogue/loop.svg";

class Filters extends Component {
  state = {
    cataloguePallete: false
  };

  componentDidMount() {
    const type = this.props.match.path.replace("/", "");
    this.props.getTypes(type);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      const type = this.props.match.path.replace("/", "");
      this.props.setTypeFilter(type);
    }
  }

  findModelByName = e => {
    this.props.setTextFilter(e.target.value);
  };

  handleInputKeyDown = e => {
    e.persist();
    if (e.keyCode === 27) {
      this.props.setTextFilter("");
    }
  };

  handlePallete = () => {
    this.setState({ cataloguePallete: !this.props.colorPalleteIsOpen });
    let pageX, pageY;
    if (this.props.clientWidth > 1600) {
      pageX = 259;
      pageY = 32;
    } else {
      pageX = 199;
      pageY = 30;
    }
    this.props.toggleColorPallete(!this.props.colorPalleteIsOpen, pageX, pageY);
  };

  handleColorFilter = color => {
    this.props.toggleColorPallete(false);
    this.props.setCatalogueColorFilter(color);
  };

  customColorHandler = color => {
    const customColor = "#f0f0ec";
    this.props.toggleColorPallete(false);
    this.props.setCatalogueColorFilter(color);
    this.setState({ color }, () => {
      this.getModels();
    });
  };

  handleGenderFilter = genderFilter => {
    let gender = genderFilter === this.props.genderFilter ? "" : genderFilter;
    this.props.setGenderFilter(gender);
  };

  render() {
    const { cataloguePallete } = this.state;
    const {
      catalogueColorFilter,
      colorPalleteIsOpen,
      colorsInStock,
      customColors,
      genderFilter,
      textFilter
    } = this.props;

    return (
      <>
        <div className="catalogue__filters__menu catalogue__filters__menu-mock" />
        <div className="catalogue__filters__menu">
          <div className="catalogue__filters__search">
            <Loop className="catalogue__filters__loop" />
            <div
              onClick={() => this.props.setTextFilter("")}
              className={[
                "catalogue__filters__input__delete",
                textFilter !== ""
                  ? "catalogue__filters__input__delete-show"
                  : ""
              ].join(" ")}
            >
              <ClearInput />
            </div>
            <input
              onChange={this.findModelByName}
              onKeyDown={this.handleInputKeyDown}
              value={textFilter}
              className="catalogue__filters__input"
              type="text"
              placeholder="Поиск по модели"
            />
          </div>
          <div className="catalogue__filters__items">
            <div
              className="catalogue__filters__item catalogue__filters__item-first"
              onClick={e => this.handlePallete(e)}
            >
              Выбор цвета:
              {catalogueColorFilter ? (
                <Color color={catalogueColorFilter} />
              ) : (
                  <ColorPicker className="catalogue__filters__icon" />
                )}
              {colorPalleteIsOpen && cataloguePallete ? (
                <ColorPallete
                  colorsInStock={colorsInStock}
                  customColors={customColors}
                  catalogue
                  handler={this.handleColorFilter}
                  customColorHandler={this.customColorHandler}
                />
              ) : null}
            </div>
            <div
              onClick={() => this.handleGenderFilter("male")}
              className={[
                "catalogue__filters__item",
                genderFilter === "male" ? "catalogue__filters__item-active" : ""
              ].join(" ")}
            >
              Мужские
            </div>
            <div
              onClick={() => this.handleGenderFilter("female")}
              className={[
                "catalogue__filters__item",
                genderFilter === "female"
                  ? "catalogue__filters__item-active"
                  : ""
              ].join(" ")}
            >
              Женские
            </div>
            <div
              onClick={() => this.handleGenderFilter("children")}
              className={[
                "catalogue__filters__item",
                genderFilter === "children"
                  ? "catalogue__filters__item-active"
                  : ""
              ].join(" ")}
            >
              Детские
            </div>
          </div>
        </div>
        <div
          style={{ height: "54px", width: "100%" }}
          className="catalogue__filters__mobile__wrapper"
        >
          <div className="catalogue__filters__mobile" ref={this.mobileFilters}>
            <MobileFilters
              customColors={customColors}
              gender={genderFilter}
              changeGender={gender => this.handleGenderFilter(gender)}
              changeColor={color => this.handleColorFilter(color)}
              color={catalogueColorFilter}
              colorsInStock={colorsInStock}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ order, helpers, catalog }) => {
  return {
    order: order.order,
    colorPalleteIsOpen: helpers.colorPalleteIsOpen,
    clientWidth: helpers.clientWidth,
    catalogueColorFilter: catalog.catalogueColorFilter,
    colors: catalog.colors,
    colorsInStock: catalog.colorsInStock,
    customColors: catalog.customColors,
    textFilter: catalog.textFilter,
    genderFilter: catalog.genderFilter
  };
};

const mapDispatchToPros = dispatch => {
  return {
    toggleColorPallete: (flag, pageX, pageY) =>
      dispatch(toggleColorPallete(flag, pageX, pageY)),
    openProductModal: flag => dispatch(toggleProductModal(flag)),
    setCatalogueColorFilter: color => dispatch(setCatalogueColorFilter(color)),
    getColors: () => dispatch(getColors()),
    getTypes: type => dispatch(getTypes(type)),
    setGenderFilter: genderFilter => dispatch(setGenderFilter(genderFilter)),
    setTextFilter: textFilter => dispatch(setTextFilter(textFilter)),
    setTypeFilter: typeFilter => dispatch(setTypeFilter(typeFilter))
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToPros
  )(Filters)
);
