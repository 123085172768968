import React from 'react';

import './index.scss';

const Review = ({ text, company, author }) => (
  <div className="review">
    <div className="review__description">
      {text}
    </div>
    <div className="review__author__wrapper">
      <div className="review__author__block">
        {/* <span className="review__author">
          {company}
        </span> */}
        <span className="review__date">
          {author}
        </span>
      </div>
    </div>
  </div>
)

export default Review;