import React from 'react';

import '../index.scss';
import close from '../../../img/application/close.svg';
import PreviewCarousel from '../../ApplicationPreviewSlider';

const StraightPrinting = ({ openCalculatorModal, closeApplicationModal }) => (
  <div className="silk">
    <div className="silk__preview">
      <img
        className="silk__preview__close"
        src={close}
        onClick={closeApplicationModal} />
      <div className="silk__preview__content">
        <PreviewCarousel applicationMethod="termo" />
      </div>
    </div>
    <div className="silk__column">
      <div className="silk__column__content">
        <div className="silk__column__content__group">
          <div className="silk__column__content__caption">
            Прямая печать
          </div>
          <div
            className="silk__column__content__button"
            onClick={() => openCalculatorModal('straightPrinting')}>
            Рассчитать нанесение
          </div>
        </div>
        <div className="silk__column__content__text">
          Прямая (цифровая) печать - на готовых изделиях  (футболках, толстовках и т.д)
          Для печати подходят натуральные ткани и изделия.
          Печать возможна на любом цвете ткани.
          Такой метод печати дает фотографическое качество картинки с высокой детализацией.
          Подходит для печати небольших тиражей.
          Максимальный размер печати 60х40 см.
        </div>
      </div>
    </div>
  </div>
)

export default StraightPrinting;
