import React from "react";
import { connect } from "react-redux";

import "./index.scss";
import OurWork from "../../components/OurWork";
import Helmeted from "../../components/Helmeted";

const OurWorks = ({ ourWorks }) => (
  <div className="works page">
    <Helmeted />
    <div className="works__body">
      <div className="works__container">
        {/*и тут была ошибка*/}
        {
          ourWorks
          ?
          ourWorks.map(ourWork => (
             <OurWork key={ourWork.id} image={ourWork.image} />
           ))
          :
          null
        }

      </div>
    </div>
  </div>
);

const mapStateToProps = ({ catalog }) => {
  return {
    ourWorks: catalog.ourWorks
  };
};

export default connect(mapStateToProps)(OurWorks);
