import React from "react";
import { connect } from "react-redux";

import "./index.scss";
import { updateOrder } from "../../store/order/actions";
import { toggleColorPallete } from "../../store/helpers/actions";
import Color from "../Color";

class ColorPallete extends React.Component {
  colorPallete = React.createRef();

  handleTopOffset = () => {
    let windowHeight = window.innerHeight;
    const top = this.props.colorPalletePageY;
    if (windowHeight - top > 300) {
      return top;
    } else {
      return top - 300;
    }
  };

  componentWillUnmount() {
    document.removeEventListener("click", this.handleOutsideClick, false);
  }
  componentDidMount() {
    document.addEventListener("click", this.handleOutsideClick, false);
  }

  handleOutsideClick = e => {
    if (this.colorPallete.current) {
      if (this.colorPallete.current.contains(e.target)) {
        return;
      } else {
        this.props.toggleColorPallete(!this.props.colorPalleteIsOpen);
      }
    }
  };

  getColorsOnDemand = () => {
    const { colorsInStock, colors } = this.props;
    const filteredColors = colors.filter(color => {
      return (
        colorsInStock.findIndex(
          colorInStock => colorInStock.id === color.id
        ) === -1
      );
    });
    return filteredColors;
  };

  render() {
    const { colorsInStock, colors } = this.props;

    return (
      <div
        className="colorPallete"
        ref={this.colorPallete}
        style={{
          left: this.props.colorPalletePageX,
          top: this.handleTopOffset()
        }}
      >
        <div className="colorPallete__label">В наличии</div>
        <div className="colorPallete__row colorPallete__row-instock">
          {colorsInStock.map(color => (
            <Color
              key={color.id}
              color={color.hex}
              handler={() => this.props.handler(color.hex)}
            />
          ))}
        </div>
        <div className="colorPallete__label colorPallete__label-second">
          Под заказ
        </div>
        <div className="colorPallete__body">
          <div className="colorPallete__row">
            {this.getColorsOnDemand().map(color => (
              <Color
                key={color.id}
                color={color.hex}
                handler={() => this.props.handler(color.hex)}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ order, helpers, catalog }) => {
  return {
    order: order.order,
    colorPalleteIsOpen: helpers.colorPalleteIsOpen,
    colorPalletePageX: helpers.colorPalletePageX,
    colorPalletePageY: helpers.colorPalletePageY,
    colors: catalog.colors
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeOrderColors: changedOrder => dispatch(updateOrder(changedOrder)),
    toggleColorPallete: flag => dispatch(toggleColorPallete(flag))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ColorPallete);
