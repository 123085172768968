import React from "react";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";

import "./index.scss";
import { toggleSidebar } from "../../store/helpers/actions";
import { ReactComponent as Cart } from "../../img/mainHeader/basket.svg";
import { ReactComponent as SidebarMenu } from "../../img/sidebar/sidebar-menu.svg";
import { ReactComponent as ActiveLink } from "../../img/mainHeader/active-link.svg";
import { ReactComponent as Star } from "../../img/mainHeader/star.svg";
import { ReactComponent as FMLogo } from "../../img/mainHeader/logo.svg";
import { ReactComponent as Viber } from "../../img/mainHeader/viber.svg";
import { ReactComponent as Whatsup } from "../../img/mainHeader/whatsup.svg";
import { ReactComponent as Telegram } from "../../img/mainHeader/telegram.svg";
import { ReactComponent as Email } from "../../img/mainHeader/email.svg";

const Header = ({
  path,
  animationCartCounter,
  basketOrders,
  toggleSidebar
}) => {
  const animateActiveLink = link => {
    return link === path
      ? "header__navigation-link__container__line--active"
      : "header__navigation-link__container__line";
  };

  return (
    <header className="header">
      <div className="header__wrapper">
        <SidebarMenu
          className="header__sidebar"
          onClick={() => toggleSidebar(true)}
        />
        <div className="header__contacts">
          <div className="header__contacts-wrapper">
            <a href="tel:+7 928 226 53 81" className="header__contacts-phone">
              +7 (928) 226-53-81
            </a>
            <a className="header__contacts__icon" href="viber://chat?number=79282265381">
              <Viber />
            </a>
            <a className="header__contacts__icon" href="https://api.whatsapp.com/send?phone=79282265381" target="_blank">
              <Whatsup className="header__contacts__icon" />
            </a>
            <a className="header__contacts__icon" href="tg://resolve?domain=futbolka_market_official">
              <Telegram className="header__contacts__icon" />
            </a>
            <a className="header__contacts__icon" href="mailto:mail@futbolka.market">
              <Email className="header__contacts__icon" />
            </a>
          </div>
        </div>
        <div className="header__group">
          <Link to="/basket" className="header__group__links">
            <Cart
              className={[
                "header__group__cart",
                animationCartCounter ? "header__group__cart--animate" : ""
              ].join(" ")}
            />
            <div
              className={
                animationCartCounter
                  ? "header__group__counter--animate"
                  : "header__group__counter"
              }
            >
              {basketOrders.orders.length}
            </div>
          </Link>
        </div>
      </div>
      <div className="header__line" />
      <div className="header__navigation__wrapper">
        <Link to="/" className="header__titleGroup">
          <FMLogo />
        </Link>
        <nav className="header__navigation">
          <NavLink to="/jersey" className="header__navigation-link">
            <span>Футболки</span>
            <ActiveLink className={animateActiveLink("jersey")} />
          </NavLink>
          <NavLink to="/sweatshirt" className="header__navigation-link">
            <span>Толстовки</span>
            <ActiveLink className={animateActiveLink("sweatshirt")} />
          </NavLink>
          <NavLink to="/polo" className="header__navigation-link">
            <span>Рубашки поло</span>
            <ActiveLink className={animateActiveLink("polo")} />
          </NavLink>
          <NavLink
            to="/application"
            className="header__navigation-link header__navigation-link-custom"
          >
            <span className="header__navigation-link__label">
              Нанесение
              <Star className="header__navigation-link--img" />
            </span>
            <ActiveLink className={animateActiveLink("application")} />
          </NavLink>
          <NavLink to="/custom-product" className="header__navigation-link header__navigation-link__bespoke">
            <span>Изделия под заказ</span>
            <ActiveLink className={animateActiveLink("custom-product")} />
          </NavLink>
          <NavLink to="/works" className="header__navigation-link">
            <span>Наши работы</span>
            <ActiveLink className={animateActiveLink("works")} />
          </NavLink>
        </nav>
      </div>
    </header>
  );
};

const mapStateToProps = ({ order, helpers }) => {
  return {
    basketOrders: order.basketOrders,
    animationCartCounter: helpers.animationCartCounter
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleSidebar: flag => dispatch(toggleSidebar(flag))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
