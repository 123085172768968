import React from "react";
import { connect } from "react-redux";

import "./index.scss";
import Partners from "../../components/Partners";
import MainCarousel from "../../components/MainCarousel";
import ReviewCarousel from "../../components/ReviewCarousel";
import cart from "../../img/main/cart.png";
import ok from "../../img/main/ok.png";
import tshirt from "../../img/main/tshort.png";
import experience from "../../img/main/experience.svg";
import logistics from "../../img/main/logistics.png";
import Button from "../../components/Button";
import OurWorks from "../../components/OurWorks";
import Helmeted from "../../components/Helmeted";

class Main extends React.Component {
  carouselApplicationHandler = () => {
    this.props.history.push("/application");
  };

  redirectToCatalogue = () => {
    let productType;
    this.props.order.productType
      ? (productType = this.props.order.productType)
      : (productType = "jersey");
    this.props.history.push(`/${productType}`);
  };

  render() {
    return (
      <div className="main page">
        <Helmeted />
        <MainCarousel
          handler={this.redirectToCatalogue}
          applicationHandler={this.carouselApplicationHandler}
        />
        <div className="main__button">
          <Button handler={this.redirectToCatalogue}>В каталог</Button>
        </div>
        {/* тут была ошибка*/}
        {
          this.props.ourWorks
          ?
          <OurWorks ourWorks={this.props.ourWorks.slice(0, 5)} />
          :
          null
        }

        <div className="main__advantage">
          <div className="main__advantage__container">
            <div className="main__advantage__text">наши преимущества</div>
            <div className="main__advantage__row">
              <div className="main__advantage__row__column">
                <img
                  className="main__advantage__row__column__img"
                  src={ok}
                  alt="купить оптом футболки"
                />
                <div className="main__advantage__row__column__caption">
                  отличное качество
                </div>
                <div className="main__advantage__row__column__text">
                  Мы осуществляем контроль качества на каждом этапе производства
                </div>
              </div>
              <div className="main__advantage__row__column">
                <img
                  className="main__advantage__row__column__img"
                  src={cart}
                  alt="купить футболки дешево"
                />
                <div className="main__advantage__row__column__caption">
                  большой выбор
                </div>
                <div className="main__advantage__row__column__text">
                  У нас огромный ассортимент футболок, толстовок и рубашек поло
                </div>
              </div>
              <div className="main__advantage__row__column">
                <img
                  className="main__advantage__row__column__img"
                  src={tshirt}
                  alt="купить футболку с принтом"
                />
                <div className="main__advantage__row__column__caption">
                  принт
                </div>
                <div className="main__advantage__row__column__text">
                  Собственное принтовочное производство
                </div>
              </div>
              <div className="main__advantage__row__column">
                <img
                  className="main__advantage__row__column__img"
                  src={experience}
                  alt="толстовки оптом"
                />
                <div className="main__advantage__row__column__caption">
                  Большой опыт работы
                </div>
                <div className="main__advantage__row__column__text">
                  Опыт работы наших сотрудников более 5 лет
                </div>
              </div>
              <div className="main__advantage__row__column">
                <img
                  className="main__advantage__row__column__img"
                  src={logistics}
                  alt="купить опт рубашки-поло"
                />
                <div className="main__advantage__row__column__caption">
                  доставка
                </div>
                <div className="main__advantage__row__column__text">
                  Мы доставляем товар в максимально короткие сроки
                </div>
              </div>
            </div>
          </div>
        </div>
        <ReviewCarousel />
        <Partners />
      </div>
    );
  }
}

const mapStateToProps = ({ catalog, order }) => {
  return {
    ourWorks: catalog.ourWorks,
    order: order.order
  };
};

export default connect(mapStateToProps)(Main);
