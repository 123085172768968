import * as types from "./actionTypes";

export const toggleProductModal = flag => {
  return {
    type: types.TOGGLE_PRODUCT_MODAL,
    flag
  };
};

export const openConfirmationModal = (tab, payload) => {
  return {
    type: types.OPEN_CONFIRMATION_MODAL,
    tab,
    payload
  };
};

export const closeConfirmationModal = () => {
  return {
    type: types.CLOSE_CONFIRMATION_MODAL
  };
};

export const toggleCalculatorModal = flag => {
  return {
    type: types.TOGGLE_CALCULATOR_MODAL,
    flag
  };
};

export const toggleSidebar = flag => {
  return {
    type: types.TOGGLE_SIDEBAR,
    flag
  };
};

export const setClientWidth = clientWidth => {
  return {
    type: types.SET_CLIENT_WIDTH,
    clientWidth
  };
};

export const animationCartCounter = flag => {
  return {
    type: types.ANIMATE_BASKET,
    flag
  };
};

export const toggleColorPallete = (flag, pageX, pageY, colorToChange) => {
  return {
    type: types.OPEN_COLOR_PALLETE,
    flag,
    pageX,
    pageY,
    colorToChange
  };
};

export const validateDeliveryForm = flag => {
  return {
    type: types.DELIVERY_FORM_IS_VALID,
    flag
  };
};

export const handleCalculatorActiveMethod = activeMethod => {
  return {
    type: types.CHANGE_CALCULATOR_ACTIVE_METHOD,
    activeMethod
  };
};
