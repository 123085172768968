import axios from "axios";

export function fetchColors() {
  return axios
    .get("/api/v1/catalog/all_colors/")
    .then(response => response.data)
    .catch(error => console.log(error));
}

// export function fetchOurWorks() {
//   return axios
//     .get("/api/v1/catalog/our_jobs/")
//     .then(response => response.data)
//     .catch(error => console.log(error));
// }

export function fetchOurWorks() {
  return fetch("/api/v1/catalog/our_jobs/",
  { method: 'GET',
    headers: {
      'User-Agent': 'Console app',
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }})
    .then((response) => response.json())
    .then(response => response)
    .catch(error => console.log(error));
}

export function fetchTypes() {
  return axios
    .get("/api/v1/catalog/types/")
    .then(response => response.data)
    .catch(error => console.log(error));
}

export function fetchTypeColors(typeId) {
  return axios
    .get(`/api/v1/catalog/types/${typeId}/colors`)
    .then(response => response.data);
}

export function fetchModels(typeId, color) {
  const params = [
    `type=${typeId}`,
    color ? `&hex=${color.replace("#", "")}` : ""
  ].join("");

  return axios
    .get(`/api/v1/catalog/main/?${params}`)
    .then(response => response.data);
}

export function fetchModel(id, hex) {
  const hexParam = hex ? `?hex=${hex.replace("#", "")}` : "";

  return axios
    .get(`/api/v1/catalog/main/${id}/${hexParam}`)
    .then(response => response.data)
    .catch(error => console.log(error));
}
