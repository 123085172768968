import React from "react";
import { connect } from "react-redux";

import "./index.scss";
import { toggleColorPallete } from "../../store/helpers/actions";
import { updateOrder } from "../../store/order/actions";
import OrderTableRow from "../OrderTableRow";
import Color from "../Color";
import { ReactComponent as DeleteX } from "../../img/productModal/delete-x.svg";

class OrderTable extends React.Component {

  countTotal = sizeArray => {
    let total = sizeArray.reduce((acc, value) => {
      return acc + Number(value);
    }, 0);
    return total === 0 ? "" : total;
  };

  deleteColor = color => {
    if (Object.keys(this.props.sizes).length > 1) {
      let updatedOrderColors = this.props.sizes;
      delete updatedOrderColors[color];
      let updatedOrder = Object.assign({}, this.props.order, {
        sizes: updatedOrderColors
      });
      this.props.deleteColorFromOrder(updatedOrder);
    } else {
      let epmtySizeArray = this.props.order.sizes[
        Object.keys(this.props.sizes)[0]
      ].map(size => 0);
      let updatedOrder = Object.assign({}, this.props.order, {
        sizes: { [Object.keys(this.props.sizes)[0]]: epmtySizeArray }
      });
      this.props.deleteColorFromOrder(updatedOrder);
    }
  };

  handleColorPalleteClick = (e, color) => {
    e.persist();
    let pageX = e.pageX;
    let pageY = e.pageY;
    this.props.toggleColorPallete(
      !this.props.colorPalleteIsOpen,
      pageX,
      pageY,
      color
    );
  };

  render() {
    const {
      sizesTitles,
      sizesTable,
      small,
      orderItem,
      basket,
      sizes
    } = this.props;
    const colors = Object.keys(sizes);
    const sizesGrid = Object.keys(sizes).map(key => sizes[key]);

    return (
      <div
        className={[
          "OrderTable__wrapper",
          small ? "OrderTable__wrapper-small" : "",
          orderItem ? "OrderTable__wrapper-orderItem" : ""
        ].join(" ")}
      >
        <div className="OrderTable__color">
          {colors.map(color => (
            <Color
              handler={e => this.handleColorPalleteClick(e, color)}
              key={color}
              sizesTable={sizesTable}
              color={color}
            />
          ))}
        </div>
        <div
          className="OrderTable__tableWrapper"
          ref={this.props.forwardedRef}>
          <table
            className={["OrderTable", small ? "OrderTable__small" : ""].join(
              " "
            )}
          >
            <thead>
              <tr className="OrderTable__row">
                {sizesTitles.map(size => (
                  <td key={size} className="OrderTable__label">
                    {size}
                  </td>
                ))}
              </tr>
            </thead>
            <tbody>
              {colors.map((color, index) => (
                <OrderTableRow
                  key={color}
                  color={color}
                  sizes={sizesGrid[index]}
                  small={small}
                  basket={basket}
                  orderItem={orderItem}
                />
              ))}
            </tbody>
          </table>
        </div>
        <div className="OrderTable__summ">
          {colors.map((color, index) => (
            <div
              className="OrderTable__summ__item__wrapper"
              key={color + index}
            >
              <div
                className={[
                  "OrderTable__summ__item",
                  this.props.small ? "OrderTable__summ__item-small" : ""
                ].join(" ")}
              >
                {this.countTotal(sizesGrid[index])}
              </div>
              {this.props.small ? null : (
                <div
                  className="OrderTable__summ__delete"
                  onClick={() => this.deleteColor(colors[index])}
                >
                  <DeleteX />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ order, helpers }) => {
  return {
    order: order.order,
    clientWidth: helpers.clientWidth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateOrderSizes: updatedOrderSizes =>
      dispatch(updateOrder(updatedOrderSizes)),
    deleteColorFromOrder: updatedOrder => dispatch(updateOrder(updatedOrder)),
    toggleColorPallete: (flag, pageX, pageY, colorToChange) =>
      dispatch(toggleColorPallete(flag, pageX, pageY, colorToChange))
  };
};

const ConnectedOrderTable = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderTable);

export default React.forwardRef((props, ref) => <ConnectedOrderTable {...props} forwardedRef={ref} />);

