import React from "react";
import { connect } from "react-redux";

import "./index.scss";
import { updateOrder } from "../../store/order/actions";

class OrderTableRow extends React.Component {
  state = {
    orderSizes: [0, 0, 0, 0, 0, 0, 0],
    deleteIsShown: false
  };

  static getDerivedStateFromProps(nextProps) {
    return {
      orderSizes: nextProps.sizes.slice(0)
    };
  }

  handleSizesCounterChange = (event, index) => {
    let value = Number(event.target.value);
    if (!/^\d+$/.test(value) || value > 999) return;
    let updatedOrderSizes = this.state.orderSizes;
    updatedOrderSizes[index] = value;
    this.updateOrderSizes(updatedOrderSizes);
  };

  updateOrderSizes = updatedOrderSizesArray => {
    let { color, order } = this.props;
    let orderSizes = order.sizes;
    // let updatedOrderSizes = Object.assign({}, orderSizes, { [color]: this.state.orderSizes });
    let updatedOrderSizes = Object.assign({}, orderSizes, {
      [color]: updatedOrderSizesArray
    });
    let updatedOrder = Object.assign({}, order, { sizes: updatedOrderSizes });
    this.props.updateOrderSizes(updatedOrder);
  };

  render() {
    let { color, small, basket, orderItem } = this.props;
    let orderSizes = this.state.orderSizes;

    return (
      <tr
        className="OrderTableRow"
        onMouseEnter={this.showDeleteRow}
        onMouseLeave={this.hideDeleteRow}
      >
        {orderSizes.map((size, index) => (
          <td className="OrderTableRow__quantity" key={color + "size" + index}>
            <input
              onChange={event => this.handleSizesCounterChange(event, index)}
              className={[
                "OrderTableRow__quantity__input",
                small ? "OrderTableRow__quantity__disabled" : "",
                basket ? "OrderTableRow__basketTable" : "",
                orderItem ? "OrderTableRow__orderTable" : ""
              ].join(" ")}
              type="tel"
              maxLength="3"
              value={size > 0 ? size : ""}
              disabled={small}
            />
          </td>
        ))}
      </tr>
    );
  }
}

const mapStateToProps = ({ order }) => {
  return {
    order: order.order
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateOrderSizes: updatedOrderSizes =>
      dispatch(updateOrder(updatedOrderSizes))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderTableRow);
