import React from "react";
import { connect } from "react-redux";

import "./index.scss";
import { toggleCalculatorModal } from "../../store/helpers/actions";
import OurWorks from "../../components/OurWorks";
import ReviewCarousel from "../../components/ReviewCarousel";
import Partners from "../../components/Partners";
import Button from "../../components/Button";
import NewsBlock from "../../components/NewsBlock";
import TechnicalRequirement from "../../components/TechnicalRequirement";
import Why from "../../components/Why";
import ApplicationTypes from "../../components/ApplicationTypes";
import Helmeted from "../../components/Helmeted";

class Application extends React.Component {
  whyBackground = React.createRef();

  componentDidMount() {
    document.addEventListener("scroll", this.handleParallax, false);
  }
  componentWillUnmount() {
    document.removeEventListener("scroll", this.handleParallax, false);
  }

  handleParallax = () => {
    var slider = this.whyBackground.current;

    var yPos = window.pageYOffset;
    let delta = this.props.clientWidth > 940 ? 0.8 : 0.4;
    yPos = (-(yPos - 1800) * delta) / 4;
    window.requestAnimationFrame(() => {
      slider.style.transform = `translate3d(0,${yPos.toFixed(1)}px,0)`;
    });
  };

  openCalculatorModal = () => {
    this.props.toggleCalculatorModal(true);
  };

  handleApplicatonModalOpen = type => {
    this.props.history.push(`/application/${type}`, {
      isApplicationModal: true
    });
  };
  render() {
    return (
      <div className="application page">
        <Helmeted />
        <div className="application__block">
          <div className="application__block-background">
            <div className="application__block-background__text">
              <h1 className="application__block-background__text__caption">
                Все виды печати на текстиле
              </h1>
              <div className="application__block-background__text__title">
                Разработаем дизайн. Выполним любое нанесение
              </div>
            </div>
          </div>
        </div>
        <div className="application__calc">
          <Button handler={this.openCalculatorModal}>
            Рассчитать нанесение
          </Button>
        </div>
        <ApplicationTypes
          handleApplicatonModalOpen={this.handleApplicatonModalOpen}
        />
        <Why ref={this.whyBackground} />
        <div className="application__text">
          <div className="application__text__content">
            <div className="application__text__content__item1">
              Обладая собственным печатным производством и высококлассными
              специалистами мы выполняем заказы любых объёмов, качественно и в
              срок.
            </div>
          </div>
        </div>
        {/*}<OurWorks ourWorks={this.props.ourWorks.slice(0, 5)} />
        <TechnicalRequirement /> */}
        <NewsBlock />
        <ReviewCarousel />
        <Partners />
      </div>
    );
  }
}

const mapStateToProps = ({ helpers, catalog }) => {
  return {
    clientWidth: helpers.clientWidth,
    ourWorks: catalog.ourWorks
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleCalculatorModal: flag => dispatch(toggleCalculatorModal(flag))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Application);
