import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from 'react-helmet';

import "./index.scss";
import { toggleCalculatorModal } from "../../store/helpers/actions";
import { getOurWorks } from "../../store/catalog/actions";
import Sidebar from "../../components/Sidebar";
import ScrollToTop from "../scrollToTop";
import CalculatorModal from "../../components/CalculatorModal";
import ContactPhone from "../../components/ContactPhone";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import ConfirmationModal from "../../components/ConfirmationModal";

class Layout extends React.Component {

  componentDidMount() {
    this.props.getOurWorks();
    this.props.toggleCalculatorModal(false);
  }

  render() {
    return (
      <div id="layout__wrapper">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Futbolka Market - оптовый пошив изделий - оптовый пошив текстиля</title>
        </Helmet>
        <Sidebar history={this.props.history} />
        <div className="layout" id="layout">
          <Header path={this.props.location.pathname.replace("/", "")} />
          <ContactPhone />
          <ConfirmationModal />
          <CalculatorModal location={this.props.location} />
          <ScrollToTop>{this.props.children}</ScrollToTop>
          <Footer />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getOurWorks: () => dispatch(getOurWorks()),
    toggleCalculatorModal: flag => dispatch(toggleCalculatorModal(flag))
  };
};

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(Layout)
);
