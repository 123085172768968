import React from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import axios from "axios";

import "./index.scss";
import {
  toggleCalculatorModal,
  openConfirmationModal,
  handleCalculatorActiveMethod
} from "../../store/helpers/actions";
import Calculator from "./calculator";
import CalculatorContacts from "./contacts";
import MobileCalculator from "./mobileCalculator";

const straightPrintingSizes = ["A2", "A3", "A4", "A5", "A6"];

class CalculatorModal extends React.Component {
  dropdown = React.createRef();
  state = {
    ordering: null,
    activeMethod: "silkscreen",
    silkscreen: {
      sumItems: 0,
      sizeHeight: 0,
      sizeWidth: 0,
      chromacity: 0,
      totalPriceApplication: 0
    },
    straightPrinting: {
      sumItems: 0,
      size: "A2",
      totalPriceApplication: 0
    },
    sublimation: {
      sumItems: 0,
      sizeHeight: 0,
      sizeWidth: 0,
      totalPriceApplication: 0
    },
    showStraigthPrintingOptions: false,
    contacts: {
      user: "",
      email: "",
      phone: ""
    }
  };

  componentDidMount() {
    document.addEventListener("click", this.handleOutsideClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleOutsideClick, false);
  }

  handleOutsideClick = e => {
    if (this.dropdown.current) {
      if (this.dropdown.current.contains(e.target)) {
        return;
      } else {
        this.setState({ showStraigthPrintingOptions: false });
      }
    }
  };

  closeConfirmationModal = () => {
    this.setState({ ordering: null });
    this.props.toggleCalculatorModal(false);
  };

  orderPrint = () => {
    if (
      this.state[this.props.calculatorActiveMethod].totalPriceApplication === 0
    )
      return;
    this.setState({ ordering: !this.state.ordering });
  };

  handlePrintMethod = method => {
    this.props.handleCalculatorActiveMethod(method);
    this.totalPriceApplication();
  };

  handleStraigthPrintingSizeChange = value => {
    this.setState(
      {
        straightPrinting: { ...this.state.straightPrinting, size: value },
        showStraigthPrintingOptions: !this.state.showStraigthPrintingOptions
      },
      () => {
        this.totalPriceApplication();
      }
    );
  };

  showStraigthPrintingOptions = () => {
    this.setState({
      showStraigthPrintingOptions: !this.state.showStraigthPrintingOptions
    });
  };

  handleCalculateApplication = (event, value) => {
    let inputValue = event.target.value ? Number(event.target.value) : 0;
    if (!/^\d+$/.test(inputValue) || inputValue > 999) return;
    this.setState(
      {
        [this.props.calculatorActiveMethod]: {
          ...this.state[this.props.calculatorActiveMethod],
          [value]: inputValue
        }
      },
      () => {
        this.totalPriceApplication();
      }
    );
  };

  getSublimationPricePerMeter = (sizeHeight, sizeWidth, sumItems) => {
    let sqMeters = ((sizeHeight / 100) * (sizeWidth / 100) * sumItems).toFixed(
      2
    );
    let price;
    if (sqMeters < 50) {
      price = 750;
    } else if (sqMeters >= 50 && sqMeters < 100) {
      price = 500;
    } else if (sqMeters >= 100 && sqMeters < 300) {
      price = 450;
    } else if (sqMeters >= 300) {
      price = 400;
    }
    if (sqMeters < 1 && sumItems !== 0 && sizeHeight !== 0 && sizeWidth !== 0) {
      return 750;
    } else {
      return (sqMeters * price).toFixed(0);
    }
  };

  getSilkscreenPrintingPrice = (productAmount, width, height, chromacity) => {
    const pricesLessThen20 = [
      [50, 2500, 4000, 5500, 7500, 9000, 11000],
      [100, 50, 80, 110, 150, 180, 220],
      [300, 45, 60, 75, 90, 100, 120],
      [500, 25, 45, 55, 65, 75, 80],
      [700, 20, 30, 40, 45, 50, 55],
      [7000000, 15, 25, 35, 40, 43, 45]
    ];
    const priceMoreThen20 = [
      [50, 3000, 5000, 6900, 8000, 9500, 12000],
      [100, 60, 100, 138, 160, 190, 240],
      [300, 55, 70, 85, 100, 110, 130],
      [500, 35, 55, 65, 75, 85, 95],
      [700, 30, 40, 50, 60, 65, 75],
      [7000000, 20, 30, 40, 50, 55, 60]
    ];

    let priceGrid;
    width >= 21 || height >= 21
      ? (priceGrid = priceMoreThen20)
      : (priceGrid = pricesLessThen20);
    let priceGridRow;

    priceGrid.reduce((acc, priceRow, index) => {
      if (productAmount < priceRow[0] && productAmount >= acc) {
        priceGridRow = priceRow;
      } else if (
        index === priceGrid.length - 1 &&
        productAmount >= priceRow[0]
      ) {
        priceGridRow = priceRow;
      } else {
        return priceRow[0];
      }
    }, 0);
    if (productAmount < 50 && productAmount !== 0 && chromacity !== 0) {
      return priceGridRow[chromacity];
    } else if (productAmount >= 50 && chromacity !== 0) {
      return priceGridRow[chromacity] * productAmount;
    } else {
      return 0;
    }
  };

  handleChromacityChange = e => {
    let chromacity = e.target.value ? Number(e.target.value) : 0;
    if (
      chromacity >= 0 &&
      chromacity <= 6 &&
      chromacity.toString().length === 1
    ) {
      this.setState(
        {
          [this.props.calculatorActiveMethod]: {
            ...this.state[this.props.calculatorActiveMethod],
            chromacity
          }
        },
        () => {
          this.totalPriceApplication();
        }
      );
    } else if (chromacity === "") {
      this.setState(
        {
          [this.props.calculatorActiveMethod]: {
            ...this.state[this.props.calculatorActiveMethod],
            chromacity
          }
        },
        () => {
          this.totalPriceApplication();
        }
      );
    } else {
      return;
    }
  };

  getStraigthPrintingPrice = sumItems => {
    const getPriceTableColumn = () => {
      let column;
      if (sumItems <= 5) {
        column = 1;
      } else if (sumItems >= 6 && sumItems <= 10) {
        column = 2;
      } else if (sumItems >= 11 && sumItems <= 25) {
        column = 3;
      } else {
        column = 4;
      }
      return column;
    };
    let straightPrintingPrices = [
      ["A2", 700, 650, 600, 550],
      ["A3", 650, 600, 550, 500],
      ["A4", 600, 550, 500, 450],
      ["A5", 550, 500, 450, 400],
      ["A6", 500, 450, 400, 350]
    ];
    let priceRow = straightPrintingPrices.filter(
      priceRow => priceRow[0] === this.state.straightPrinting.size
    )[0];
    return priceRow[getPriceTableColumn()] * sumItems;
  };

  calculateSublimationPrice = () => {
    let { sumItems, sizeHeight, sizeWidth } = this.state.sublimation;
    let price = this.getSublimationPricePerMeter(
      sizeHeight,
      sizeWidth,
      sumItems
    );
    let sublimation = {
      ...this.state.sublimation,
      totalPriceApplication: price
    };
    this.setState({ sublimation });
  };

  calculateSilkscreenPrice = () => {
    let { sumItems, sizeHeight, sizeWidth, chromacity } = this.state.silkscreen;
    let price = this.getSilkscreenPrintingPrice(
      sumItems,
      sizeWidth,
      sizeHeight,
      chromacity
    );
    let silkscreen = {
      ...this.state.silkscreen,
      totalPriceApplication: price
    };
    this.setState({ silkscreen });
  };

  calculateStraightPrintingPrice = () => {
    const { sumItems } = this.state.straightPrinting;
    const price = this.getStraigthPrintingPrice(sumItems);
    const straightPrinting = {
      ...this.state.straightPrinting,
      totalPriceApplication: price
    };
    this.setState({ straightPrinting });
  };

  totalPriceApplication = () => {
    let activeMethod = this.props.calculatorActiveMethod;
    if (activeMethod === "sublimation") {
      this.calculateSublimationPrice();
    } else if (activeMethod === "silkscreen") {
      this.calculateSilkscreenPrice();
    } else if (activeMethod === "straightPrinting") {
      this.calculateStraightPrintingPrice();
    }
  };

  orderIsValid = () => {
    return (
      this.state[this.props.calculatorActiveMethod].totalPriceApplication > 0
    );
  };

  getRussianApplicationType = () => {
    const { calculatorActiveMethod } = this.props;
    switch (calculatorActiveMethod) {
      case "silkscreen":
        return "Шелкография";
      case "straightPrinting":
        return "Прямая печать";
      case "sublimation":
        return "Сублимация";
    }
  };

  getApplicatinSize = () => {
    const { calculatorActiveMethod } = this.props;
    const { silkscreen, straightPrinting, sublimation } = this.state;
    switch (calculatorActiveMethod) {
      case "silkscreen":
        return `${silkscreen.sizeWidth}x${silkscreen.sizeHeight}`;
      case "straightPrinting":
        return straightPrinting.size;
      case "sublimation":
        return `${sublimation.sizeWidth}x${sublimation.sizeHeight}`;
    }
  };

  submitOrder = phone => {
    if (this.orderIsValid()) {
      const { calculatorActiveMethod } = this.props;
      const order = this.state[this.props.calculatorActiveMethod];
      const form = {
        phone: phone,
        type: this.getRussianApplicationType(),
        size: this.getApplicatinSize(),
        colors_count:
          calculatorActiveMethod === "silkscreen"
            ? this.state.silkscreen.chromacity
            : 0,
        item_count: Number(order.sumItems)
      };
      axios.post("/api/v1/application", form).then(response => {
        this.setState({ ordering: null });
        this.props.toggleCalculatorModal(false);
        this.props.openConfirmationModal("manager");
      });
    }
  };

  render() {
    const { clientWidth, calculatorActiveMethod } = this.props;

    return (
      <div className="calculator">
        <Modal
          isOpen={this.props.calculatorModalIsOpen}
          className="calculatorModal"
          overlayClassName="calculatorModal__overlay"
          onRequestClose={this.closeConfirmationModal}
        >
          {clientWidth > 800 ? (
            <div
              className={[
                "calculator__wrapper",
                this.state.ordering === true
                  ? "calculator__wrapper-forward"
                  : this.state.ordering === false
                    ? "calculator__wrapper-back"
                    : ""
              ].join(" ")}
            >
              <Calculator
                handlePrintMethod={this.handlePrintMethod}
                calculatorActiveMethod={calculatorActiveMethod}
                handleCalculateApplication={this.handleCalculateApplication}
                state={this.state}
                showStraigthPrintingOptions={this.showStraigthPrintingOptions}
                handleStraigthPrintingSizeChange={this.handleStraigthPrintingSizeChange}
                handleChromacityChange={this.handleChromacityChange}
                orderPrint={this.orderPrint}
                ref={this.dropdown}
                straightPrintingSizes={straightPrintingSizes}
              />
              <CalculatorContacts
                goback={this.orderPrint}
                submitOrder={this.submitOrder}
              />
            </div>
          ) : (
              <MobileCalculator
                state={this.state}
                handlePrintMethod={this.handlePrintMethod}
                calculatorActiveMethod={calculatorActiveMethod}
                handleCalculateApplication={this.handleCalculateApplication}
                handleChromacityChange={this.handleChromacityChange}
                showStraigthPrintingOptions={this.showStraigthPrintingOptions}
                handleStraigthPrintingSizeChange={this.handleStraigthPrintingSizeChange}
                orderPrint={this.orderPrint}
                submitOrder={this.submitOrder}
                ref={this.dropdown}
                straightPrintingSizes={straightPrintingSizes}
                handleNameChange={this.handleNameChange}
                handlePhoneChange={this.handlePhoneChange}
                handleEmailChange={this.handleEmailChange}
                name={this.state.contacts.user}
                phone={this.state.contacts.phone}
                email={this.state.contacts.email}
              />
            )}
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = ({ helpers }) => {
  return {
    calculatorModalIsOpen: helpers.calculatorModalIsOpen,
    clientWidth: helpers.clientWidth,
    calculatorActiveMethod: helpers.calculatorActiveMethod
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleCalculatorModal: flag => dispatch(toggleCalculatorModal(flag)),
    openConfirmationModal: tab => dispatch(openConfirmationModal(tab)),
    handleCalculatorActiveMethod: activeMethod =>
      dispatch(handleCalculatorActiveMethod(activeMethod))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CalculatorModal);
