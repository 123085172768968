import React from "react";

import "./index.scss";

const ApplicationTypes = ({ handleApplicatonModalOpen }) => (
  <div className="applicationtypes">
    <div className="applicationtypes__container">
      <div className="applicationtypes__text">Мы умеем все</div>
      <div className="applicationtypes__block">
        <div className="applicationtypes__block__column">
          <div className="applicationtypes__block__column__container">
            <div
              className="applicationtypes__block__img applicationtypes__block__img-silk"
              onClick={() => handleApplicatonModalOpen("silkscreen")}
            />
          </div>
          <div className="applicationtypes__block__column__item">
            <div className="applicationtypes__block__column__container">
              <div
                className="applicationtypes__block__img applicationtypes__block__img-termo"
                onClick={() => handleApplicatonModalOpen("straight-printing")}
              />
            </div>
            <div className="applicationtypes__block__column__container">
              <div
                className="applicationtypes__block__img applicationtypes__block__img-special"
                onClick={() => handleApplicatonModalOpen("special-effects")}
              />
            </div>
          </div>
        </div>
        <div className="applicationtypes__block__column">
          <div
            className="applicationtypes__block__img applicationtypes__block__img-sub"
            onClick={() => handleApplicatonModalOpen("embroidery")}
          />
          <div
            className="applicationtypes__block__img applicationtypes__block__img-vush"
            onClick={() => handleApplicatonModalOpen("sublimation")}
          />
        </div>
      </div>
    </div>
  </div>
);

export default ApplicationTypes;
