import React, { Component } from 'react';

import './index.scss';
import axios from 'axios';
import NewsItem from '../../components/NewsItem';
import Helmeted from '../../components/Helmeted';


class News extends Component {

  state = {
    news : []

  }
  componentDidMount() {
    this.getNewsList();
  }

  getNewsList = () => {
    axios
      .get(`/api/v1/news/`)
      .then(({ data }) => {
          console.log('news list', data)
          this.setState({
            news: data
           });
         })
      }



  render() {
    return (
      <div className="news page">
        <Helmeted />
        <div className="news__container">
          { this.state.news.map(post => <NewsItem key={post.pk} {...post} handler={() => this.props.history.push(`/news/${post.pk}`)} />) }
        </div>
      </div>
    )
  }
}

export default News;
