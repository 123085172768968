import React from "react";
import Toggle from "react-toggle";
import Map from "../Map";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import "./index.scss";
import { validateDeliveryForm } from "../../store/helpers/actions";
import { addBasketOrder } from "../../store/order/actions";
import { ReactComponent as Package } from "../../img/productModal/delivery-package.svg";
import { ReactComponent as Cdek } from "../../img/delivery/cdek.svg";
import { ReactComponent as Del } from "../../img/delivery/del.svg";
import { ReactComponent as Pek } from "../../img/delivery/pek.svg";
import { ReactComponent as Dimex } from "../../img/delivery/dimex.svg";

class Delivery extends React.Component {
  state = {
    delivery: false,
    deliveryCompany: "",
    customerInfo: {
      phone: "",
      city: ""
    },
    touched: {
      phone: false,
      city: false
    },
    valid: {
      phone: false,
      city: false
    },
    formIsValid: false
  };

  componentDidMount() {
    if (this.props.basketOrders.customerInfo) {
      this.setState({ customerInfo: this.props.basketOrders.customerInfo });
    }
    this.validateForms();
  }

  handleFormInput = (e, label) => {
    e.persist();

    const updatedCustomerInfo = this.state.customerInfo;
    if (label === "phone") {
      if (
        /^(?=.*[0-9])[- +()0-9]+$/i.test(e.target.value) ||
        e.target.value === ""
      ) {
        const phone = e.target.value;
        updatedCustomerInfo.phone = phone;
        this.setState({ customerInfo: updatedCustomerInfo }, () => {
          this.validateInputs();
        });
      } else {
        return;
      }
    } else {
      updatedCustomerInfo[label] = e.target.value;
      this.setState({ customerInfo: updatedCustomerInfo }, () => {
        this.validateInputs();
      });
    }
  };

  handleBlur = field => evt => {
    let updatedTouchedInputs = this.state.touched;
    updatedTouchedInputs[field] = true;
    this.setState({
      touched: updatedTouchedInputs
    });
  };

  validateForms = () => {
    let formIsValid =
      Object.keys(this.state.valid)
        .map(key => this.state.valid[key])
        .findIndex(input => input === false) === -1;
    let noDeliveryFormIsValid =
      Object.keys(this.state.valid)
        .filter(validatedInput => validatedInput !== "city")
        .map(validatedInput => this.state.valid[validatedInput])
        .find(input => input === false) === undefined;
    if (this.state.delivery) {
      let updatedBasketOrders = Object.assign({}, this.props.basketOrders, {
        customerInfo: this.state.customerInfo,
        delivery: this.state.delivery,
        deliveryCompany: this.state.deliveryCompany
      });
      this.props.addCustomerInfo(updatedBasketOrders);
      this.props.validateDeliveryForm(
        formIsValid && this.state.deliveryCompany !== ""
      );
    } else if (this.state.delivery === false) {
      let updatedBasketOrders = Object.assign({}, this.props.basketOrders, {
        customerInfo: this.state.customerInfo,
        delivery: this.state.delivery
      });
      this.props.addCustomerInfo(updatedBasketOrders);
      this.props.validateDeliveryForm(noDeliveryFormIsValid);
    }
  };

  validateInputs = () => {
    let validatedInputs = this.state.valid;
    validatedInputs = {
      phone: this.state.customerInfo.phone.length > 10,
      city: this.state.customerInfo.city.trim() !== ""
    };

    let formIsValid =
      Object.keys(validatedInputs)
        .map(key => validatedInputs[key])
        .find(input => input === false) === undefined;
    let noDeliveryFormIsValid =
      Object.keys(validatedInputs)
        .filter(validatedInput => validatedInput !== "city")
        .map(validatedInput => this.state.valid[validatedInput])
        .find(input => input === false) === undefined;
    if (this.state.delivery) {
      this.setState(
        {
          valid: validatedInputs,
          formIsValid: formIsValid && this.state.deliveryCompany !== ""
        },
        () => this.validateForms()
      );
    } else {
      this.setState(
        {
          valid: validatedInputs,
          formIsValid: noDeliveryFormIsValid
        },
        () => this.validateForms()
      );
    }
  };

  handleDeliveryToggle = flag => {
    this.setState({ delivery: flag }, () => this.validateForms());
    let order = this.props.basketOrders;
    order.delivery = flag;
    this.props.addCustomerInfo(order);
  };

  handleDeliveryCompanyPick = deliveryCompany => {
    let pickedDeliveryCompany =
      this.state.deliveryCompany === deliveryCompany ? "" : deliveryCompany;
    this.setState({ deliveryCompany: pickedDeliveryCompany }, () =>
      this.validateInputs()
    );
  };

  render() {
    const { basketOrders } = this.props;
    const { delivery, valid, touched, customerInfo } = this.state;

    return (
      <div className="delivery">
        {basketOrders.orders.length > 0 ? null : <Redirect to="/basket" />}
        <span className="delivery__title">доставка</span>
        <div className="delivery__top">
          <div className="delivery__top__group">
            <span className="delivery__top__title">доставка</span>
            <div className="delivery__toggle">
              <div className="delivery__toggle__group">
                <label className="delivery__toggle__label">
                  <Toggle
                    checked={!delivery}
                    icons={false}
                    onChange={() => this.handleDeliveryToggle(!delivery)}
                    className="delivery__toggle__item"
                  />
                  <span
                    className={delivery ? "" : "delivery__toggle__label-active"}
                  >
                    Самовывоз
                  </span>
                </label>
                <label className="delivery__toggle__label">
                  <Toggle
                    checked={delivery}
                    icons={false}
                    onChange={() => this.handleDeliveryToggle(!delivery)}
                    className="delivery__toggle__item"
                  />
                  <span
                    className={
                      !delivery ? "" : "delivery__toggle__label-active"
                    }
                  >
                    Транспортная компания
                  </span>
                </label>
              </div>
              {delivery ? (
                <input
                  className={[
                    "delivery__input",
                    !valid.city && touched.city ? "delivery__input-invalid" : ""
                  ].join(" ")}
                  type="text"
                  placeholder="Адрес назначения"
                  onInput={e => this.handleFormInput(e, "city")}
                  onBlur={this.handleBlur("city")}
                  required
                  value={customerInfo.city}
                />
              ) : null}
            </div>
          </div>

          <div className="delivery__package">
            <Package className="delivery__package__image" />
            <div className="delivery__package__wrapper">
              <div className="delivery__package__group">
                <span className="delivery__package__label">Габариты, см</span>
                <span className="delivery__package__label">
                  (60х40х50) х {basketOrders.boxes}
                </span>
              </div>
              <div className="delivery__package__group">
                <span className="delivery__package__label">Вес, кг</span>
                <span className="delivery__package__label">
                  {basketOrders.orderWeight}
                </span>
              </div>
            </div>
          </div>
        </div>

        {!delivery ? (
          <div>
            <span className="delivery__address">
              Ростов-на-Дону ул. Доватора, 142 литА
            </span>
            <div className="delivery__map__wrapper">
              <Map
                isMarkerShown
                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyB0MTmNsfs_5DVszAgxQSJ9ldm9wrb79uI&language=ru&v=3.exp&libraries=geometry,drawing,places"
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div className="delivery__map" />}
                mapElement={<div style={{ height: `100%` }} />}
              />
            </div>
          </div>
        ) : (
            <div>
              <div className="payment__body__address__title delivery__methods__title">
                Выберите транспортную компанию:
            </div>
              <div className="delivery__methods">
                <div
                  onClick={() => this.handleDeliveryCompanyPick("ПЭК")}
                  className={[
                    "delivery__method",
                    basketOrders.deliveryCompany === "ПЭК"
                      ? "delivery__method-active"
                      : ""
                  ].join(" ")}
                >
                  <Pek className="delivery__method__logo" />
                </div>
                <div
                  onClick={() => this.handleDeliveryCompanyPick("Деловые линии")}
                  className={[
                    "delivery__method",
                    basketOrders.deliveryCompany === "Деловые линии"
                      ? "delivery__method-active"
                      : ""
                  ].join(" ")}
                >
                  <Del className="delivery__method__logo" />
                </div>
                <div
                  onClick={() => this.handleDeliveryCompanyPick("СДЭК")}
                  className={[
                    "delivery__method",
                    basketOrders.deliveryCompany === "СДЭК"
                      ? "delivery__method-active"
                      : ""
                  ].join(" ")}
                >
                  <Cdek className="delivery__method__logo" />
                </div>
                <div
                  onClick={() => this.handleDeliveryCompanyPick("DIMEX")}
                  className={[
                    "delivery__method",
                    basketOrders.deliveryCompany === "DIMEX"
                      ? "delivery__method-active"
                      : ""
                  ].join(" ")}
                >
                  <Dimex className="delivery__method__logo" />
                </div>
              </div>
            </div>
          )}
        {delivery ? (
          <span className="delivery__info">
            * Оплата за доставку производится по факту получения и рассчитывается транспортной компанией
          </span>
        ) : null}
        <span className="payment__body__address__title">Получатель груза</span>
        <form className="payment__body__address__group">
          <input
            placeholder="Номер телефона"
            required
            onChange={e => this.handleFormInput(e, "phone")}
            onBlur={this.handleBlur("phone")}
            value={customerInfo.phone}
            maxLength="11"
            className={[
              "payment__body__address__input",
              !valid.phone && touched.phone ? "delivery__input-invalid" : ""
            ].join(" ")}
          />
        </form>
      </div>
    );
  }
}

const mapStateToProps = ({ order }) => {
  return {
    basketOrders: order.basketOrders
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addCustomerInfo: updatedOrder => dispatch(addBasketOrder(updatedOrder)),
    validateDeliveryForm: flag => dispatch(validateDeliveryForm(flag))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Delivery);
