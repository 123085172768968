import React from 'react';
import { Link, NavLink } from "react-router-dom";
import '../index.scss';

const NeedMore = ({ closeModal }) => (
  <div className="need">
    <span className="need__title">
     Товар добавлен в корзину
    </span>
    <div className="need__group">
      <button onClick={closeModal} className="need__group__button">Продолжить покупки</button>
      <NavLink to="/basket" className="need__group__button" onClick={closeModal}>Перейти в корзину</NavLink>
    </div>
  </div>
)

export default NeedMore;
