import React from 'react';

import './index.scss';
import { ReactComponent as PhotoNotAvailable } from '../../img/shirtPhotoNotAvailable.svg';

const PreviewProductSmall = ({ orderItem, previews }) => (
  <div className="previewSm">
    {previews.length > 0 ? previews.slice(0, 4).map((preview, index) => <img
      className={["previewSm__product", orderItem ? 'previewSm__orderItem' : ''].join(" ")}
      alt=""
      key={preview}
      src={preview}
      style={{
        transform: `scale(${index === 0 ? 1 : 1 - index * 0.05})`,
        zIndex: 100 - index
      }} />) :
      <PhotoNotAvailable className={[
        "previewSm__product-nophoto",
        orderItem ? 'previewSm__orderItem' : ''
      ].join(" ")} />}
  </div>
)

export default PreviewProductSmall;