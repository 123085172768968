import React from 'react';

import '../index.scss';
import Button from '../../Button';

const Manager = ({ closeModal }) => (
  <div className="manager">
    <h4 className="manager__title">
      Благодарим за выбор нашей компании!
    </h4>
    <p className="manager__description">
      Менеджер свяжется с Вами в ближайшее время.
    </p>
    <div className="manager__button-wrapper">
      <Button handler={closeModal}>
        Oк
      </Button>
    </div>
  </div>
)

export default Manager;