export function countBaskerOrderBoxes(basketOrder) {
  let jerseysAmount = 0;
  let sweatshirtsAmount = 0;
  basketOrder.orders.forEach(order => {
    if (order.productType === 'jersey') {
      jerseysAmount += order.productsCounter;
    } else if (basketOrder.productType === 'sweatshirt') {
      sweatshirtsAmount += order.productsCounter;
    }
  })
  let jerseySize = 1 / 80;
  let sweatshirtsSize = 1 / 25;
  let boxes = Number((jerseysAmount * jerseySize).toFixed(2)) + Number((sweatshirtsAmount * sweatshirtsSize).toFixed(2));
  return Math.ceil(boxes);
}