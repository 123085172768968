import React from 'react';

import '../index.scss';
import invoice from '../../../img/payment/invoice.png';
import visa from '../../../img/payment/visa.png';

const Payment = ({ handleBill }) => (
  <div className="payment__modal">
    <div className="payment__modal__header">
      Выберите способ оплаты
    </div>
    <div className="payment__modal__body">
      <div className="payment__modal__body__content">
        <div className="payment__modal__body__content__item">
          <img
            src={visa}
            alt="visa"
            className="payment__modal__body__content__item__img" />
          <div className="payment__modal__body__content__item__text">
            Банковские карты
          </div>
        </div>
        <div
          className="payment__modal__body__content__item"
          onClick={handleBill}>
          <img
            src={invoice}
            alt="invoice"
            className="payment__modal__body__content__item__img payment__modal__body__content__item__image" />
          <div className="payment__modal__body__content__item__text">
            Выставить счет
          </div>
          <div className="payment__modal__body__content__item__text__coution">
            Только для юридических лиц
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Payment;