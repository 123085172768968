import React, { Component } from 'react';
import axios from 'axios';

import './index.scss';
import { ReactComponent as Arrow } from '../../img/news/arrow.svg';
import Helmeted from '../../components/Helmeted';

class NewsPage extends Component {
  state = {
    post: {}
  }
  componentDidMount() {
    // debugger;
    axios.get(`/api/v1/news/${this.props.match.params.title}/`)
    .then(response => {
      // const indexOfNews = response.data.findIndex(news => news.id == this.props.match.params.title);
      // if (indexOfNews === -1) {
      //   return this.props.history.push('/news');
      // }
      let post = response.data;
      // const news = response.data[indexOfNews]
      this.setState({
        post: post
      })
    })
  }

  handleGoBack = () => {
    this.props.history.push('/news');
  }

  render() {
    return (
      <div className="newsPage page">
        <Helmeted />
        <div className="newsPage__body" >
        <h4 className="newsPage__title">{this.state.post.title}</h4>
         <div dangerouslySetInnerHTML={{__html: this.state.post.body}}>
         </div>
         <div
           onClick={this.handleGoBack}
           className="newsPage__goback__button">
           <Arrow />
           <span>Все новости</span>
         </div>
        {/*
          <h4 className="newsPage__title">{title}</h4>
          <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.</p>
          <div className="newsPage__images">
            <img src={image} alt="" />
            <img src={image} alt="" />
          </div>
          <p>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.
          </p>
          <div className="newsPage__images">
            <img src={image} alt="" />
          </div>
          <div className="newsPage__goback">



          </div>
          */}
        </div>
      </div>
    )
  }
}

export default NewsPage;
