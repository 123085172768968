import React from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  closeConfirmationModal,
  openConfirmationModal
} from "../../store/helpers/actions";
import {
  updateOrder,
  addBasketOrder,
  deleteBasketOrders
} from "../../store/order/actions";
import axios from "axios";

import "./index.scss";
import { formatOrder } from "../../helpers/formatOrder";
import Quit from "./tabs/quit";
import NeedMore from "./tabs/needMore";
import Contact from "./tabs/contact";
import Gratitude from "./tabs/gratitude";
import Payment from "./tabs/payment";
import Phone from "./tabs/phone";
import Manager from "./tabs/manager";
import DeleteOrder from "./tabs/deleteOrder";
import Claim from "./tabs/claim";
import OrderForm from "../OrderForm";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0",
    boxShadow: "0 0 26px 0 rgba(76,76,76,0.3)",
    border: "none"
  },
  overlay: {
    zIndex: 9991,
    background: "rgba(0, 0, 0, 0.25)"
  }
};

Modal.setAppElement("#root");

class ConfirmationModal extends React.Component {
  state = {
    contacts: {
      name: "",
      email: "",
      phone: ""
    },
    managerContact: "",
    managerContactIsValid: false,
    managerContactTouched: false
  };

  closeConfirmationModal = () => {
    this.props.closeConfirmationModal();
  };

  handleDeleteModal = () => {
    const location = this.props.location;
    const fromBasket = !!(location.state && location.state.fromBasket);
    fromBasket
      ? this.props.history.push("/basket")
      : this.props.history.push(`/${this.props.order.productType}`);
    this.closeConfirmationModal();
  };

  handleCloseContactModal = e => {
    e.preventDefault();
    let order = this.props.order;
    let updatedOrder = Object.assign({}, order, {
      contacts: this.state.contacts
    });
    this.props.updateOrder(updatedOrder);
    this.props.openConfirmationModal("gratitude");
  };

  handleCloseGratitudeModal = () => {
    const location = this.props.location;
    const fromBasket = !!(location.state && location.state.fromBasket);
    let productType = this.props.order.productType;
    fromBasket
      ? this.props.history.push("/basket")
      : this.props.history.push(`/${productType}`);
    this.closeConfirmationModal();
  };

  handleContactsInputChange = (e, label) => {
    let contactForm = this.state.contacts;
    let updatedContactForm = { ...contactForm, [label]: e.target.value };
    this.setState({ contacts: updatedContactForm });
  };

  deleteOrder = () => {
    const orderIdToDelete = this.props.confirmationModalPayload;
    const updatedBasketOrders = this.props.basketOrders.orders.filter(
      order => order.id !== orderIdToDelete
    );
    const updatedGeneralOrder = Object.assign({}, this.props.basketOrders, {
      orders: updatedBasketOrders
    });

    this.props.addBasketOrder(updatedGeneralOrder);
    this.closeConfirmationModal();
  };

  handleManagerButton = e => {
    e.preventDefault();
    if (this.state.managerContactIsValid) {
      axios
        .post("/api/v1/callback", {
          phone: this.state.managerContact
        })
        .then(response => {
          console.log(response);
          this.props.openConfirmationModal("manager");
        });
      this.setState({
        managerContact: "",
        managerContactIsValid: false,
        managerContactTouched: false
      });
    }
  };

  handleContactManagerInput = e => {
    e.persist();
    let value = e.target.value;
    if (/^(?=.*[0-9])[- +()0-9]+$/i.test(value) || value === "") {
      this.setState({
        managerContact: e.target.value,
        managerContactIsValid: e.target.value.length > 10
      });
    }
  };

  handleContactManagerBlur = () => {
    this.setState({
      managerContactTouched: true
    });
  };

  submitOrder = () => {
    const finalOrder = formatOrder(this.props.basketOrders);
    console.log(finalOrder);
    axios
      .post("/api/v1/orders/", finalOrder)
      .then(response => {
        console.log(response);
        this.props.deleteBasketOrders();
        this.props.history.push("/basket");
        this.props.openConfirmationModal("manager");
      })
      .catch(error => console.log(error));
  };

  handleCurrentTab = tab => {
    switch (tab) {
      case "quit":
        return (
          <Quit
            cancel={this.closeConfirmationModal}
            closeModal={this.handleDeleteModal}
          />
        );
        case "needMore":
          return (
            <NeedMore
              cancel={this.closeConfirmationModal}
              closeModal={this.handleDeleteModal}
            />
          );
      case "contact":
        return (
          <Contact
            submitForm={this.handleCloseContactModal}
            inputHandler={this.handleContactsInputChange}
            cancel={this.closeConfirmationModal}
          />
        );
      case "gratitude":
        return <Gratitude close={this.handleCloseGratitudeModal} />;
      case "payment":
        return <Payment handleBill={this.submitOrder} />;
      case "phone":
        return (
          <Phone
            handlePhoneInput={this.handleContactManagerInput}
            handlePhoneBlur={this.handleContactManagerBlur}
            inputIsValid={this.state.managerContactIsValid}
            inputTouched={this.state.managerContactTouched}
            submitCallbackRequest={this.handleManagerButton}
            value={this.state.managerContact}
          />
        );
      case "manager":
        return <Manager closeModal={this.closeConfirmationModal} />;
      case "deleteOrder":
        return (
          <DeleteOrder
            deleteOrder={this.deleteOrder}
            closeModal={this.closeConfirmationModal}
          />
        );
      case "claim":
        return <Claim closeModal={this.closeConfirmationModal} />;
      case "calculateOrder":
        return <OrderForm />;
      default:
        return null;
    }
  };
  render() {
    let { confirmationModalIsOpen, confirmationModalTab } = this.props;
    return (
      <Modal
        isOpen={confirmationModalIsOpen}
        style={customStyles}
        onRequestClose={this.closeConfirmationModal}
      >
        {this.handleCurrentTab(confirmationModalTab)}
      </Modal>
    );
  }
}

const mapStateToProps = ({ helpers, order }) => {
  return {
    confirmationModalIsOpen: helpers.confirmationModalIsOpen,
    confirmationModalTab: helpers.confirmationModalTab,
    confirmationModalPayload: helpers.confirmationModalPayload,
    order: order.order,
    commentIndex: helpers.commentIndex,
    basketOrders: order.basketOrders,
    productModalIsOpen: helpers.productModalIsOpen
  };
};

const mapDispatchToProps = dispatch => {
  return {
    closeConfirmationModal: () => dispatch(closeConfirmationModal()),
    openConfirmationModal: tab => dispatch(openConfirmationModal(tab)),
    updateOrder: updatedOrder => dispatch(updateOrder(updatedOrder)),
    addBasketOrder: order => dispatch(addBasketOrder(order)),
    deleteBasketOrders: () => dispatch(deleteBasketOrders())
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ConfirmationModal)
);
