import React from "react";
import { connect } from "react-redux";
import uuidv1 from "uuid/v1";

import MobileSizesRow from "../MobileSizes";
import { ReactComponent as AddColor } from "../../img/productModal/add-color.svg";

class MobileSizesController extends React.Component {
  state = {
    mobileSizes: []
  };

  componentDidMount() {
    this.getMobileSizes();
  }

  colorsInState = () => {
    return this.state.mobileSizes.map(mobileSize => mobileSize.color);
  };

  getMobileSizes = () => {
    const sizesArray = [];
    const mobileColors = Object.keys(this.props.order.sizes);
    Object.keys(this.props.order.sizes)
      .map(key => this.props.order.sizes[key])
      .forEach((sizeArray, arrayIndex) => {
        sizeArray.forEach((sizeAmount, index) => {
          if (sizeAmount) {
            sizesArray.push({
              size: index,sizeAmount,
              color: mobileColors[arrayIndex],
              id: uuidv1()
            });
          }
        });
      });
    sizesArray.length !== 0
      ? this.setState({ mobileSizes: sizesArray })
      : this.setState({
        mobileSizes: [
          {
            size: 0,
            color: Object.keys(this.props.order.sizes)[0],
            id: uuidv1()
          }
        ]
      });
  };

  addMobileSize = () => {
    const updatedMobileSizes = this.state.mobileSizes;
    updatedMobileSizes.push({
      size: 0,
      sizeAmount: 0,
      color: Object.keys(this.props.order.sizes)[0],
      id: uuidv1()
    });
    this.setState({ mobileSizes: updatedMobileSizes });
  };

  handleColorChange = (id, color) => {
    let updatedMobileSize = this.state.mobileSizes.filter(
      mobileSize => mobileSize.id === id
    )[0];
    updatedMobileSize.color = color;
    let updatedMobileSizes = this.state.mobileSizes.map(mobileSize => {
      if (mobileSize.id === id) {
        return updatedMobileSize;
      } else {
        return mobileSize;
      }
    });
    this.setState({ mobileSizes: updatedMobileSizes });
  };

  handleDeleteRow = id => {
    console.log(id);
    let updatedMobileSizes = this.state.mobileSizes.filter(
      mobileSize => mobileSize.id !== id
    );
    this.setState({ mobileSizes: updatedMobileSizes });
  };

  render() {
    return (
      <div>
        {this.state.mobileSizes.map((size, index) => (
          <MobileSizesRow
            {...size}
            colorsInState={this.colorsInState()}
            key={size.id}
            changeColor={this.handleColorChange}
            colorsInStock={this.props.colorsInStock}
            deleteRow={this.handleDeleteRow}
          />
        ))}
        <span className={["sizes__minimum", this.props.showHint ? "sizes__minimum--show" : "sizes__minimum--hide"].join(" ")}>
          Минимальный заказ в одном цвете 25 шт
          <span className="sizes__minimum__warning">!</span>
        </span>
        <AddColor
          className="sizes__colorPallete__addColor"
          onClick={this.addMobileSize}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ order }) => {
  return {
    order: order.order
  };
};

export default connect(mapStateToProps)(MobileSizesController);
