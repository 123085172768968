export function countOrderWeight(basketOrders) {
  let orderWeight = basketOrders.orders.map(order => {
    const sizesArray = Object.keys(order.sizes).map(function (e) {
      return order.sizes[e]
    })
    let weights = sizesArray.map(sizes => {
      let weightPerColor = sizes.map((size, index) => {
        return Number(size) * order.size_info[index].brutto_weight;
      }).reduce((acc, value) => acc + value, 0);
      return weightPerColor;
    }).reduce((acc, value) => acc + value, 0);
    return weights
  }).reduce((acc, value) => acc + value, 0);

  return (orderWeight / 1000).toFixed(2)
}