import React from "react";
import { connect } from "react-redux";
import Spinner from "react-spinkit";
import axios from "axios";

import "./index.scss";
import { updateOrder } from "../../store/order/actions";
import { toggleColorPallete } from "../../store/helpers/actions";
import LayeredPreview from "../LayeredPreview";
import OrderTable from "../OrderTable";
import MobileSizesController from "../MobileSizesController";
import { ReactComponent as ScrollPointer } from "../../img/productModal/scroll-pointer.svg";
import colorPicker from "../../img/catalogue/add-color.svg";

class Sizes extends React.Component {
  orderTable = React.createRef();

  state = {
    previews: []
  };

  componentDidMount() {
    this.props.toggleColorPallete(false);
    this.getPreviews();
  }


  handleColorPalleteClick = e => {
    e.persist();
    let pageX = e.pageX;
    let pageY = e.pageY;
    this.props.toggleColorPallete(!this.props.colorPalleteIsOpen, pageX, pageY);
  };

  getPreviews = () => {
    axios
      .get(
        `/api/v1/catalog/main/${this.props.match.params.model}/previews_list/`
      )
      .then(response => this.setState({ previews: response.data.previews }));
  };

  getRusProductType = () => {
    let productType = this.props.match.params.productType;
    switch (productType) {
      case "jersey":
        return "Футболка";
      case "sweatshirt":
        return "Толстовка";
      case "polo":
        return "Поло";
      default:
        return "";
    }
  };

  scrollRight = () => {
    // const scroller = this.orderTable.current;
    // console.log(scroller.offsetWidth, scroller.scrollWidth);
    // const maxScroll = scroller.offsetWidth - scroller.scrollWidth;
    // let scrollAmount = 0;
    // setInterval(() => {
    //   scroller.scrollLeft += scrollAmount;
    //   scrollAmount += 10;
    //   if (scrollAmount >= maxScroll) {
    //     return
    //   }
    // }, 100);

  }

  scrollLEft = () => {

  }

  render() {
    const {
      order,
      loadingModel,
      currentModel,
      clientWidth,
      colorsInStock
    } = this.props;
    const { previews } = this.state;

    return (
      <div>
        {!loadingModel ? (
          <div className="sizes">
            <div className="info__header">
              <span className="info__header__title">
                {`${this.getRusProductType()} ${currentModel.name}`}
              </span>
            </div>
            <LayeredPreview
              alreadyLoadedPreview={order.previews ? order.previews[0] : null}
              previews={previews}
            />
            <div className="sizes__wrapper">
              <OrderTable
                ref={this.orderTable}
                sizesTable
                sizesTitles={order.sizesTitles}
                sizes={order.sizes}
              />
              <div className="sizes__colors">
                <img
                  src={colorPicker}
                  alt=""
                  onClick={e => this.handleColorPalleteClick(e)}
                  className="sizes__colors__icon"
                />
                <span className="sizes__colors__description">
                  В поле размера введите необходимое количество
                </span>
                {Object.keys(order.sizes).map(key => order.sizes[key])[0]
                  .length > 7 ? (
                    <div className="sizes__colors__scrollPointers__wrapper">
                      <ScrollPointer
                        onClick={this.scrollLeft}
                        className="sizes__colors__scrollPointers" />
                      <ScrollPointer
                        onClick={this.scrollRight}
                        className="sizes__colors__scrollPointers" />
                    </div>
                  ) : null}
              </div>
            </div>
            <div className="sizes__mobile">
              <div className="mobileSizes__filters">
                <span>Цвет</span>
                <span>Размер</span>
                <span>Кол-во</span>
              </div>
              {clientWidth < 940 ? (
                <MobileSizesController colorsInStock={colorsInStock} showHint={this.props.showHint} />
              ) : null}
            </div>
          </div>
        ) : (
            <Spinner
              className="productModal__spinner"
              name="ball-clip-rotate-multiple"
            />
          )}
      </div>
    );
  }
}

const mapStateToProps = ({ order, helpers, catalog }) => {
  return {
    order: order.order,
    colorPalleteIsOpen: helpers.colorPalleteIsOpen,
    basketOrders: order.basketOrders,
    currentModel: catalog.currentModel,
    clientWidth: helpers.clientWidth,
    loadingModel: catalog.loadingModel
  };
};

const mapDispatchToPros = dispatch => {
  return {
    toggleColorPallete: (flag, pageX, pageY) =>
      dispatch(toggleColorPallete(flag, pageX, pageY)),
    updateOrder: updatedOrder => dispatch(updateOrder(updatedOrder))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToPros
)(Sizes);
