import React from 'react';
import Slider from 'react-slick';

import './index.scss';
import vish1 from '../../img/applicationModal/vyshivka.png';
import vish3 from '../../img/applicationModal/vnvn.png';
import vish4 from '../../img/applicationModal/5wr.png';
import silk1 from '../../img/applicationModal/shelkografia.png';
import silk2 from '../../img/applicationModal/shelkografia2.png';
import silk3 from '../../img/applicationModal/frbsfb.png';
import silk4 from '../../img/applicationModal/xthj.png';
import sub1 from '../../img/applicationModal/sub.png';
import sub2 from '../../img/applicationModal/sjsj.png';
import sub4 from '../../img/applicationModal/tdhht.png';
import termo1 from '../../img/applicationModal/termo.png';
import termo2 from '../../img/applicationModal/termo2.png';
import termo3 from '../../img/applicationModal/ bnm8dc7.png';
import special1 from '../../img/applicationModal/special.png';
import special2 from '../../img/applicationModal/special2.png';
import special4 from '../../img/applicationModal/3-min.png';
import special5 from '../../img/applicationModal/4-min.png';
import special6 from '../../img/applicationModal/5-min.png';
import special9 from '../../img/applicationModal/8-min.png';
import special12 from '../../img/applicationModal/11-min.png';
import special14 from '../../img/applicationModal/13-min.png';
import special15 from '../../img/applicationModal/14-min.png';
import special16 from '../../img/applicationModal/15-min.png';
import special17 from '../../img/applicationModal/16-min.png';
import special20 from '../../img/applicationModal/19-min.png';
import special21 from '../../img/applicationModal/20-min.png';
import special22 from '../../img/applicationModal/21-min.png';

const getApplicationTypeImage = applicationMethod => {
  switch (applicationMethod) {
    case 'vish':
      return [vish1, vish3, vish4]
    case 'silk':
      return [silk1, silk2, silk3, silk4];
    case 'sub':
      return [sub1, sub2, sub4]
    case 'termo':
      return [termo1, termo2, termo3]
    case 'special':
      return [special2, special1, special4, special5, special6, special9, special12, special14, special15, special16, special17, special20, special21, special22]
    default:
      return [];
  }
}

const settings = {
  arrows: false,
  infinite: true,
  speed: 300,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true
};

const ApplicationPreviewSlider = ({ applicationMethod }) => (
  <Slider {...settings}>
    {getApplicationTypeImage(applicationMethod).map(image =>
      <div
        className="applicationPreview__image__wrapper"
        key={image}>
        <img src={image} alt="вышивка" />
      </div>)}
  </Slider>
)

export default ApplicationPreviewSlider;
