import React from "react";

import "./index.scss";
import { ReactComponent as Production } from "../../img/customProduct/production.svg";
import { ReactComponent as Staff } from "../../img/customProduct/staff.svg";
import { ReactComponent as Experience } from "../../img/customProduct/experience.svg";
import { ReactComponent as Time } from "../../img/customProduct/time.svg";

const Facilities = () => (
  <div className="facilities">
    <div className="facilities__item">
      <Production />
      <span className="facilities__item__title">собственное производство</span>
    </div>
    <div className="facilities__item">
      <Staff />
      <span className="facilities__item__title">качественные материалы</span>
    </div>
    <div className="facilities__item">
      <Experience />
      <span className="facilities__item__title">большой опыт работы</span>
    </div>
    <div className="facilities__item">
      <Time />
      <span className="facilities__item__title">короткие сроки</span>
    </div>
  </div>
);

export default Facilities;
