import React from "react";
import toPairs from "lodash.topairs";
import fromPairs from "lodash.frompairs";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import "./index.scss";
import { openConfirmationModal } from "../../store/helpers/actions";
import { updateOrder, addBasketOrder } from "../../store/order/actions";
import OrderTable from "../OrderTable";
import AmountLabel from "../ProductAmountLabel";
import PreviewProductSmall from "../PreviewProductSmall";
import { ReactComponent as DeleteButton } from "../../img/basket/delete.svg";
import { formatPrice } from "../../helpers";

class BasketItem extends React.Component {
  getSizesForTable = index => {
    let sizes = this.props.order.sizes;
    let sizesArray = toPairs(sizes);
    let firstHalf = Math.round(sizesArray.length / 2);

    let firstTableSizesArray = sizesArray.slice(0, firstHalf);
    let secondTableSizesArray = sizesArray.slice(firstHalf);

    let firstHalfSizes = fromPairs(firstTableSizesArray);
    let secondHalfSizes = fromPairs(secondTableSizesArray);
    if (index === 1) {
      return firstHalfSizes;
    }
    return secondHalfSizes;
  };

  redirectToEditOrder = () => {
    if (this.props.location.pathname === "/basket") {
      this.props.setEditingOrder(this.props.order);
      let { productType, model, gender } = this.props.order;
      this.props.history.push(`/${productType}/${gender}/${model}/sizes`, {
        fromBasket: true,
        isProductModal: this.props.clientWidth > 940 ? true : false
      });
    }
  };

  deleteOrder = orderId => {
    const { basketOrders } = this.props;
    const updatedBasketOrders = basketOrders.orders.filter(
      order => order.id !== orderId
    );
    const updatedGeneralOrder = Object.assign({}, basketOrders, {
      orders: updatedBasketOrders
    });

    this.props.updateBasketOrders(updatedGeneralOrder);
  };

  getRusProductType = () => {
    return "";
    const productType = this.props.order.productType;
    switch (productType) {
      case "jersey":
        return "Футболка";
      case "sweatshirt":
        return "Толстовка";
      case "polo":
        return "Поло";
      default:
        return "";
    }
  };

  render() {
    const { order, basket, orderItem, checkout } = this.props;
    const pickedColorsQuantity = Object.keys(order.sizes).length;
    const productSizesQuantity = Object.keys(order.sizes).map(
      key => order.sizes[key]
    )[0].length;

    return (
      <div
        className="basketItem__wrapper-main"
        style={{
          cursor: !orderItem || checkout ? "pointer" : "default",
          pointerEvents: orderItem || checkout ? "none" : "auto"
        }}
      >
        {orderItem || checkout ? null : (
          <DeleteButton
            className="basketItem__delete"
            onClick={() => this.deleteOrder(order.id)}
          />
        )}
        <div
          onClick={this.redirectToEditOrder}
          className={[
            "basketItem",
            orderItem ? "basketItem__orderItem" : ""
          ].join(" ")}
        >
          <div className="basketItem__header">
            <span className="basketItem__title">
              {`${this.getRusProductType()} ${order.modelName}`}
            </span>
            <div className="basketItem__order">
              <span className="basketItem__label">В заказе:</span>
              <AmountLabel amount={order.productsCounter} />
            </div>
          </div>
          <div className="basketItem__wrapper">
            <div
              className={[
                "basketItem__preview",
                orderItem ? "basketItem__orderItemPreview" : ""
              ].join(" ")}
            >
              <PreviewProductSmall
                previews={order.layeredPreviews}
                orderItem={orderItem}
              />
            </div>
            <div className="basketItem__body">
              <div
                className={[
                  "basketItem__tables",
                  orderItem ? "tableOrder" : "tableBasket"
                ].join(" ")}
              >
                {productSizesQuantity > 7 ? (
                  <OrderTable
                    sizes={order.sizes}
                    small
                    basket={basket}
                    orderItem={orderItem}
                    sizesTitles={order.sizesTitles}
                  />
                ) : null}

                {productSizesQuantity <= 7 ? (
                  <OrderTable
                    sizes={this.getSizesForTable(1)}
                    small
                    basket={basket}
                    orderItem={orderItem}
                    sizesTitles={order.sizesTitles}
                  />
                ) : null}

                {productSizesQuantity <= 7 && pickedColorsQuantity > 1 ? (
                  <OrderTable
                    sizes={this.getSizesForTable(2)}
                    small
                    basket={basket}
                    orderItem={orderItem}
                    sizesTitles={order.sizesTitles}
                  />
                ) : null}
              </div>
              <div className="basketItem__prints__wrapper">
                <div className="basketItem__prints" />
                <div className="basketItem__prints__price">
                  <span className="basketItem__prints__price__label">
                    Стоимость:
                  </span>
                  <span className="basketItem__prints__price__value">
                    {formatPrice(order.totalPrice)} руб
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ order, helpers }) => {
  return {
    basketOrders: order.basketOrders,
    clientWidth: helpers.clientWidth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setEditingOrder: orderToEdit => dispatch(updateOrder(orderToEdit)),
    updateBasketOrders: updatedBasketOrders =>
      dispatch(addBasketOrder(updatedBasketOrders)),
    openConfirmationModal: (tab, payload) =>
      dispatch(openConfirmationModal(tab, payload))
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BasketItem)
);
