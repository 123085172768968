import React from "react";

import "./index.scss";
import { ReactComponent as MapPin } from "../../img/contacts/map-pin.svg";
import { ReactComponent as PhoneIcon } from "../../img/contacts/phone.svg";
import { ReactComponent as AtSign } from "../../img/contacts/at-sign.svg";
import { ReactComponent as Viber } from "../../img/contacts/viber.svg";
import { ReactComponent as Telegram } from "../../img/contacts/telegram.svg";
import { ReactComponent as Whatsap } from "../../img/contacts/whatsap.svg";

import Map from "../../components/Map";
import Helmeted from "../../components/Helmeted";

const Contacts = () => (
  <div className="contacts page">
    <Helmeted />
    <div className="contacts__title">Контакты</div>
    <div className="contacts__body">
      <div className="contacts__group">
        <div className="contacts__group__item">
          <MapPin className="contacts__group__image" />
          <div>
            <span className="contacts__group__label">Адрес</span>
            <span className="contacts__group__value">
              Ростов-на-Дону ул. Доватора, 142 литА
            </span>
          </div>
        </div>
        <div className="contacts__group__item">
          <PhoneIcon className="contacts__group__image" />
          <div>
            <span className="contacts__group__label">Телефон</span>
            <span className="contacts__group__value">+7 (928) 226-53-81</span>
          </div>
        </div>
        <div className="contacts__group__item">
          <AtSign className="contacts__group__image" />
          <div>
            <span className="contacts__group__label">Email</span>
            <span className="contacts__group__value">
              mail@futbolka.market
            </span>
          </div>
        </div>

        <div className="contacts__messengers">
          <span className="contacts__messengers__title">Напишите нам:</span>
          <div className="contacts__messengers__icons">
            <a href="viber://chat?number=79282265381"
              className="contacts__messengers__item">
              <Viber />
            </a>
            <a href="tg://resolve?domain=futbolka_market_official"
              className="contacts__messengers__item">
              <Telegram />
            </a>
            <a href="https://api.whatsapp.com/send?phone=79282265381"
              target="_blank"
              className="contacts__messengers__item">
              <Whatsap />
            </a>
          </div>
        </div>
      </div>
      <Map
        isMarkerShown
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyB0MTmNsfs_5DVszAgxQSJ9ldm9wrb79uI&language=ru&v=3.exp&libraries=geometry,drawing,places"
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={
          <div
            className="contacts__map"
            style={{ height: `406px`, width: `748px` }}
          />
        }
        mapElement={<div style={{ height: `100%` }} />}
      />
    </div>
  </div>
);

export default Contacts;
