import React from 'react';

import './index.scss';
import { ReactComponent as Label } from '../../img/productModal/amount-label.svg';

const ProductAmountLabel = ({ amount }) => (
  <div className="amount-label">
    <div className="amount-label__group">
      <span className="amount-label__amount">
        {amount}
      </span>
      <span className="amount-label__text">
        шт
      </span>
    </div>
    <Label className="amount-label__image" />
  </div>
);

export default ProductAmountLabel;