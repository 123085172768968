import React from 'react';

import '../index.scss';
import close from '../../../img/application/close.svg';
import PreviewCarousel from '../../ApplicationPreviewSlider';

const SpecialEffects = ({ closeApplicationModal }) => (
  <div className="silk">
    <div className="silk__preview">
      <img
        className="silk__preview__close"
        src={close}
        onClick={closeApplicationModal} />
      <div className="silk__preview__content">
        <PreviewCarousel applicationMethod="special" />
      </div>
    </div>
    <div className="silk__column">
      <div className="silk__column__content">
        <div className="silk__column__content__group">
          <div className="silk__column__content__caption">
            Спецэффекты
          </div>
        </div>
        <div className="silk__column__content__text">
          На данный момент мы предоставляем возможность нанесения на ткань спецэффектов таких видов, как:
        </div>
        <div className="silk__column__content__row">
          <div className="silk__column__content__column">
            <div className="silk__column__content__column__item">
              <div className="silk__column__content__column__item--circle" />
              Фольгирование
              </div>
            <div className="silk__column__content__column__item">
              <div className="silk__column__content__column__item--circle" />
                Термохром
              </div>
            <div className="silk__column__content__column__item">
              <div className="silk__column__content__column__item--circle" />
              Пуфф
              </div>
            <div className="silk__column__content__column__item">
              <div className="silk__column__content__column__item--circle" />

              Лак
              </div>
            <div className="silk__column__content__column__item">
              <div className="silk__column__content__column__item--circle" />
              Фосфорные
              </div>
            <div className="silk__column__content__column__item">
              <div className="silk__column__content__column__item--circle" />

              Светоотражающие
              </div>
            <div className="silk__column__content__column__item">
              <div className="silk__column__content__column__item--circle" />
              Перламутр
              </div>
            <div className="silk__column__content__column__item">
              <div className="silk__column__content__column__item--circle" />
              Замш
              </div>
          </div>
          <div className="silk__column__content__column">
            <div className="silk__column__content__column__item">
              <div className="silk__column__content__column__item--circle" />
              Фотохром
              </div>
            <div className="silk__column__content__column__item">
              <div className="silk__column__content__column__item--circle" />
              Флокирование
              </div>
            <div className="silk__column__content__column__item">
              <div className="silk__column__content__column__item--circle" />
              Флуоресцентные
              </div>
            <div className="silk__column__content__column__item">
              <div className="silk__column__content__column__item--circle" />
              Глиттер
              </div>
            <div className="silk__column__content__column__item">
              <div className="silk__column__content__column__item--circle" />
              Пенопласт
              </div>
            <div className="silk__column__content__column__item">
              <div className="silk__column__content__column__item--circle" />
              Пробка
              </div>
            <div className="silk__column__content__column__item">
              <div className="silk__column__content__column__item--circle" />
              Металлики
              </div>
            <div className="silk__column__content__column__item">
              <div className="silk__column__content__column__item--circle" />
              Ароматизированные
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default SpecialEffects;
