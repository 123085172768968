import { combineReducers } from "redux";
import helpersReducer from "./helpers/reducer";
import orderReducer from "./order/reducer";
import catalogueReducer from "./catalog/reducer";

export default combineReducers({
  helpers: helpersReducer,
  catalog: catalogueReducer,
  order: orderReducer
});
