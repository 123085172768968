export function formatTypes(types) {
  const formatType = function(title) {
    switch (title) {
      case "Головные уборы":
        return "cap";
      case "Футболки":
        return "jersey";
      case "Толстовки":
        return "sweatshirt";
      case "Рубашки поло":
        return "polo";
    }
  };
  return types.map(type =>
    Object.assign({}, type, { title: formatType(type.title) })
  );
}
