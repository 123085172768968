import React from 'react';

import './index.scss';
import switcher from '../../img/productModal/switcher-colored.png';

export default class Question extends React.Component {
  state = {
    questionIsOpen: false
  }
  render() {
    let { question, answer } = this.props
    let { questionIsOpen } = this.state;
    return (
      <div className={[
        "question",
        questionIsOpen ? 'shown' : 'hidden'
      ].join(" ")} >
        <div
          className="question__header"
          onClick={() => this.setState({ questionIsOpen: !questionIsOpen })}>
          <div>{question}</div>
          <img
            src={switcher}
            alt=""
            className="question__switcher"
            style={{
              transform: `rotate(${questionIsOpen ? 0 : 180}deg)`
            }} />
        </div>
        <div className="question__answer">
          <p>{answer}</p>
        </div>
      </div>
    )
  }
}
