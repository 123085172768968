import React from "react";
import { connect } from "react-redux";

import "./index.scss";
import { openConfirmationModal } from "../../store/helpers/actions";
import { ReactComponent as PhoneIcon } from "../../img/confirmationModal/contact-phone.svg";

const ContactPhone = ({ openPhoneModal }) => (
  <div
    className="contactPhone__wrapper"
    onClick={() => openPhoneModal("phone")}
  >
    <div className="contactPhone__oval">
      <PhoneIcon className="contactPhone" />
    </div>
    <div className="contactPhone__wave" />
  </div>
);

const mapDispatchToProps = dispatch => {
  return {
    openPhoneModal: tab => dispatch(openConfirmationModal(tab))
  };
};

export default connect(
  null,
  mapDispatchToProps
)(ContactPhone);
