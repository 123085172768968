import React from "react";
import { connect } from "react-redux";
import chunk from "lodash.chunk";

import "./index.scss";
import { updateOrder } from "../../store/order/actions";
import Color from "../Color";
import { ReactComponent as ToggleSelect } from "../../img/productModal/toggle-select.svg";
import { ReactComponent as DeleteButton } from "../../img/productModal/delete-small.svg";

class MobileSizesRow extends React.Component {
  colorPallete = React.createRef();
  sizesTable = React.createRef();
  colorPicker = React.createRef();
  state = {
    pickingColor: false,
    pickingSizes: false,
    pickedSize: 0,
    previousPickedSize: null,
    orderSizes: [0, 0, 0, 0, 0, 0, 0],
    previousColor: null,
    color: "#f0f0ec",
    deleted: false,
    previousSizeValue: 0,
    showCustomColors: false
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.colorsInStock.length === 0) {
      return { showCustomColors: true };
    }

    return null;
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleOutsideClick, false);
  }

  componentDidMount() {
    document.addEventListener("click", this.handleOutsideClick, false);
    let updatedSizes = this.state.orderSizes;
    updatedSizes[this.props.size] = this.props.sizeAmount;
    this.setState({
      orderSizes: updatedSizes,
      color: this.props.color,
      pickedSize: this.props.size
    });
  }

  handleOutsideClick = e => {
    if (this.state.pickingColor) {
      if (
        this.colorPicker.current.contains(e.target) ||
        this.colorPallete.current.contains(e.target)
      ) {
        return;
      } else {
        this.setState({ pickingColor: false });
      }
    } else if (this.state.pickingSizes) {
      if (this.sizesTable.contains(e.target)) {
        return;
      } else {
        this.setState({ pickingSizes: false });
      }
    }
  };

  handleColorPick = () => {
    this.setState({
      pickingColor: !this.state.pickingColor,
      pickingSizes: false
    });
  };

  handleSizesPick = () => {
    this.setState({
      pickingSizes: !this.state.pickingSizes,
      pickingColor: false
    });
  };

  getEmptyOrderSize = () => {
    return this.props.order.sizesTitles.map(size => 0);
  };

  handleSizeChoose = index => {
    let updatedOrderSizes = this.state.orderSizes;
    updatedOrderSizes[index] = updatedOrderSizes[this.state.pickedSize];
    updatedOrderSizes[this.state.pickedSize] = 0;

    this.setState(
      {
        pickedSize: index,
        previousPickedSize: this.state.pickedSize,
        orderSizes: updatedOrderSizes
      },
      () => {
        this.handleSizeChange();
      }
    );
  };

  handleSizeChange = () => {
    let { pickedSize, previousPickedSize, orderSizes } = this.state;
    let { order } = this.props;
    let updatedSizes = { ...this.props.order.sizes };
    updatedSizes[this.props.color][pickedSize] =
      updatedSizes[this.props.color][pickedSize] + orderSizes[pickedSize];
    updatedSizes[this.props.color][previousPickedSize] =
      updatedSizes[this.props.color][previousPickedSize] -
      orderSizes[pickedSize];

    let updatedOrder = Object.assign({}, order, { sizes: updatedSizes });
    this.props.updateOrderSizes(updatedOrder);
    if (orderSizes[previousPickedSize] && previousPickedSize !== pickedSize) {
    }
  };

  handleColorChange = color => {
    this.props.changeColor(this.props.id, color);
    // Сохраняем предыдущий цвет.
    this.setState({ previousColor: this.state.color }, () => {
      const { pickedSize, previousColor, orderSizes } = this.state;
      const { order, colorsInState } = this.props;
      if (color === previousColor) {
        return;
      }
      // Проверяем был ли измененный цвет уже в заказе
      if (color in this.props.order.sizes) {
        let previousColors = order.sizes;
        previousColors[previousColor][pickedSize] = previousColors[previousColor][pickedSize] - orderSizes[pickedSize];

        previousColors[color][pickedSize] =
          previousColors[color][pickedSize] + orderSizes[pickedSize];
        let noMobileSizesRowWithSameColor =
          colorsInState.filter(colorInState => colorInState === previousColor)
            .length === 0;
        if (noMobileSizesRowWithSameColor) {
          delete previousColors[previousColor];
        }
        let updatedOrder = Object.assign({}, this.props.order, {
          sizes: previousColors
        });
        this.props.updateOrderSizes(updatedOrder);
        this.setState({ color: color, pickingColor: false });
      } else {
        // Удаляем предыдущие значение размера из предыдущего цвета
        let previousValue = orderSizes[pickedSize];
        let updatedColors = order.sizes;
        updatedColors[previousColor][pickedSize] =
          updatedColors[previousColor][pickedSize] - orderSizes[pickedSize];
        let noMobileSizesRowWithSameColor =
          colorsInState.filter(colorInState => colorInState === previousColor)
            .length === 0;
        if (noMobileSizesRowWithSameColor) {
          delete updatedColors[previousColor];
        }
        // Создаем новые пустые размеры и вносим туда предыдущие значение
        let updatedOrderSize = this.getEmptyOrderSize();
        updatedOrderSize[this.state.pickedSize] = previousValue;
        let colorWithAddedColor = Object.assign({}, updatedColors, {
          [color]: updatedOrderSize
        });
        let updatedOrder = Object.assign({}, this.props.order, {
          sizes: colorWithAddedColor
        });
        this.props.updateOrderSizes(updatedOrder);

        this.setState({ color: color, pickingColor: false });
      }
    });
  };

  handleSizesCounterChange = event => {
    let value = Number(event.target.value);
    if (!/^\d+$/.test(value) || value > 999) return;
    let previousSizeValue = this.state.orderSizes[this.state.pickedSize];
    let updatedOrderSizes = this.state.orderSizes;

    updatedOrderSizes[this.state.pickedSize] = value;
    this.setState({ orderSizes: updatedOrderSizes, previousSizeValue }, () => {
      this.updateOrderSizes();
    });
  };

  updateOrderSizes = () => {
    let { color, pickedSize, orderSizes, previousSizeValue } = this.state;
    let { order } = this.props;
    let updatedSizeArray = [...order.sizes[color]];
    if (updatedSizeArray[pickedSize]) {
      updatedSizeArray[pickedSize] =
        updatedSizeArray[pickedSize] -
        (previousSizeValue - orderSizes[pickedSize]);
      let updatedSizes = Object.assign({}, order.sizes, {
        [color]: updatedSizeArray
      });
      let updatedOrder = Object.assign({}, order, { sizes: updatedSizes });
      this.props.updateOrderSizes(updatedOrder);
    } else {
      updatedSizeArray[pickedSize] =
        updatedSizeArray[pickedSize] -
        (updatedSizeArray[pickedSize] - orderSizes[pickedSize]);
      let updatedSizes = Object.assign({}, order.sizes, {
        [color]: updatedSizeArray
      });
      let updatedOrder = Object.assign({}, order, { sizes: updatedSizes });
      this.props.updateOrderSizes(updatedOrder);
    }
  };

  deleteColor = () => {
    const { order, colorsInState } = this.props;
    const { color, pickedSize, orderSizes } = this.state;

    if (colorsInState.length > 1) {
      this.props.deleteRow(this.props.id);
      let updatedOrderColors = order.sizes;
      updatedOrderColors[color][pickedSize] =
        updatedOrderColors[color][pickedSize] - orderSizes[pickedSize];
      let noMobileSizesRowWithSameColor =
        colorsInState.filter(colorInState => colorInState === color).length <=
        1;
      if (noMobileSizesRowWithSameColor) {
        delete updatedOrderColors[color];
      }
      let updatedOrder = Object.assign({}, order, {
        sizes: updatedOrderColors
      });
      this.props.deleteColorFromOrder(updatedOrder);
      this.setState({ deleted: true });
    }
  };

  getColorsOnDemand = () => {
    const { colorsInStock, colors } = this.props;
    const filteredColors = colors.filter(color => {
      return (
        colorsInStock.findIndex(
          colorInStock => colorInStock.id === color.id
        ) === -1
      );
    });
    return filteredColors;
  };

  render() {
    const {
      deleted,
      color,
      pickingSizes,
      pickingColor,
      orderSizes,
      pickedSize
    } = this.state;
    const { order, colorsInStock } = this.props;
    const customColors = this.getColorsOnDemand();
    const { showCustomColors } = this.state;
    if (deleted) return null;
    return (
      <div className="mobileSizes__wrapper">
        <div>
          <div className="mobileSizes">
            <div
              ref={this.colorPicker}
              className={["mobileSizes__item"].join(" ")}
              onClick={this.handleColorPick}
            >
              <Color color={color} onClick={this.handleColorPick} />
              <ToggleSelect className="mobileSizes__item__arrow" />
            </div>
            <div className="mobileSizes__item" onClick={this.handleSizesPick}>
              <div
                className="mobileSizes__item-sizes__wrapper"
                style={{ zIndex: pickingSizes ? 333 : 1 }}
                ref={node => (this.sizesTable = node)}
              >
                <div className="mobileSizes__item-sizes">
                  <span>{order.sizesTitles[pickedSize]}</span>
                  <ToggleSelect />
                </div>
                <div
                  className="mobileSizes__item-sizes__items"
                  style={{ display: pickingSizes ? "block" : "none" }}
                >
                  {order.sizesTitles.map((size, index) => (
                    <span
                      key={index}
                      className="mobileSizes__item-sizes__item"
                      onClick={() => this.handleSizeChoose(index)}
                    >
                      {size}
                    </span>
                  ))}
                </div>
              </div>
            </div>
            <div className="mobileSizes__item">
              <div className="mobileSizes__group">
                <input
                  type="tel"
                  maxLength={3}
                  onChange={e => this.handleSizesCounterChange(e)}
                  value={
                    orderSizes[pickedSize] === 0 ? "" : orderSizes[pickedSize]
                  }
                />
                <span>шт</span>
              </div>
            </div>
            <div onClick={this.deleteColor} className="sizes__delete">
              <DeleteButton />
            </div>
            <div
              className="sizes__colorPallete"
              style={{ display: pickingColor ? "block" : "none" }}
              ref={this.colorPallete}
            >
              <div className="mobileSizes__colorPallete__header">
                {colorsInStock.length > 0 && (
                  <span
                    onClick={() => this.setState({ showCustomColors: false })}
                    className={[
                      "mobileSizes__colorPallete__header__label",
                      !showCustomColors
                        ? "mobileSizes__colorPallete__header__label--active"
                        : "",
                      "mobileSizes__colorPallete__header__label__instock"
                    ].join(" ")}
                  >
                    В наличии
                  </span>
                )}
                {customColors.length > 0 && (
                  <span
                    onClick={() => this.setState({ showCustomColors: true })}
                    className={[
                      "mobileSizes__colorPallete__header__label",
                      showCustomColors
                        ? "mobileSizes__colorPallete__header__label--active"
                        : "",
                      "mobileSizes__colorPallete__header__label__custom"
                    ].join(" ")}
                  >
                    Под заказ
                  </span>
                )}
              </div>
              {!showCustomColors && (
                <div className="sizes__colorPallete__table">
                  {chunk(colorsInStock, 5).map((colorsRow, index) => (
                    <div
                      className="sizes__colorPallete__row"
                      key={index + "colorsRow"}
                    >
                      {colorsRow.map(color => (
                        <Color
                          color={color.hex}
                          key={color.id}
                          handler={() => this.handleColorChange(color.hex)}
                        />
                      ))}
                    </div>
                  ))}
                </div>
              )}
              {showCustomColors && (
                <div className="sizes__colorPallete__table">
                  {chunk(customColors, 5).map((colorsRow, index) => (
                    <div
                      className="sizes__colorPallete__row"
                      key={index + "colorsRow"}
                    >
                      {colorsRow.map(color => (
                        <Color
                          color={color.hex}
                          key={color.id}
                          handler={() => this.handleColorChange(color.hex)}
                        />
                      ))}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div
            className="sizes__colorPallete__conn"
            style={{ visibility: pickingColor ? "visible" : "hidden" }}
          >
            <span />
            <span />
            <span />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ order, catalog }) => {
  return {
    order: order.order,
    colors: catalog.colors
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateOrderSizes: updatedOrderSizes =>
      dispatch(updateOrder(updatedOrderSizes)),
    deleteColorFromOrder: updatedOrder => dispatch(updateOrder(updatedOrder))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileSizesRow);
