export const FETCH_TYPES = "FETCH_TYPES";

export const FETCH_TYPES_SUCCESS = "FETCH_TYPES_SUCCESS";

export const FETCH_TYPES_FAIL = "FETCH_TYPES_FAIL";

export const FETCH_MODELS = "FETCH_MODELS";

export const FETCH_MODELS_SUCCESS = "FETCH_MODELS_SUCCESS";

export const FETCH_MODELS_FAIL = "FETCH_MODELS_FAIL";

export const FETCH_TYPE_COLORS = "FETCH_TYPE_COLORS";

export const FETCH_COLORS = "FETCH_COLORS";

export const FETCH_COLORS_SUCCESS = "FETCH_COLORS_SUCCESS";

export const FETCH_COLORS_FAIL = "FETCH_COLORS_FAIL";

export const FETCH_OUR_WORKS = "FETCH_OUR_WORKS";

export const FETCH_OUR_WORKS_SUCCESS = "FETCH_OUR_WORKS_SUCCESS";

export const FETCH_OUR_WORKS_FAIL = "FETCH_OUR_WORKS_FAIL";

export const SET_CURRENT_MODEL = "SET_CURRENT_MODEL";

export const SET_EMPTY_MODEL = "SET_EMPTY_MODEL";

// PRODUCT MODAL //
export const MODEL_IS_LOADING = "MODEL_IS_LOADING";

export const FETCH_MODEL = "FETCH_MODEL";

export const FETCH_MODEL_SUCCESS = "FETCH_MODEL_SUCCESS";

export const FETCH_MODEL_FAIL = "FETCH_MODEL_FAIL";

// FILTERS //

export const SET_GENDER_FILTER = "SET_GENDER_FILTER";

export const SET_TEXT_FILTER = "SET_TEXT_FILTER";

export const REQUEST_TYPE = "REQUEST_TYPE";

export const SET_TYPE_FILTER = "SET_TYPE_FILTER";

export const FETCH_COLORED_MODELS = "FETCH_COLORED_MODELS";

export const SET_CATALOGUE_COLOR_FILTER = "SET_CATALOGUE_COLOR_FILTER";

export const GET_FILTERED_MODELS = "GET_FILTERED_MODELS";
