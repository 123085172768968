import React from "react";

import "./index.scss";
import { ReactComponent as Cdek } from "../../img/delivery/cdek.svg";
import { ReactComponent as Del } from "../../img/delivery/del.svg";
import { ReactComponent as Pek } from "../../img/delivery/pek.svg";
import { ReactComponent as Dimex } from "../../img/delivery/dimex.svg";
import Helmeted from "../../components/Helmeted";

const Delivery = () => (
  <div className="deliveryPage page">
    <Helmeted />
    <div className="contacts__title">Самовывоз</div>
    <div className="deliveryPage__body">
      <div className="deliveryPage__info">
        <div className="deliveryPage__group">
          <span className="deliveryPage__group__label">Время работы</span>
          <span className="deliveryPage__group__value">
            По будням с 09.00 до 18.00
          </span>
        </div>
        <div className="deliveryPage__group">
          <span className="deliveryPage__group__label">Адрес</span>
          <span className="deliveryPage__group__value">
            Ростов-на-Дону ул. Доватора, 142 литА
          </span>
        </div>
        <div className="deliveryPage__group">
          <span className="deliveryPage__group__label">Телефон</span>
          <span className="deliveryPage__group__value">+7 (928) 226-53-81</span>
        </div>
      </div>
      <div className="deliveryPage__methods">
        <span className="deliveryPage__methods__title">Доставка</span>
        <span className="deliveryPage__methods__subtitle">
          Осуществляется следующими транспортными компаниями
        </span>
      </div>
      <div className="deliveryPage__items">
        <div className="deliveryPage__item">
          <Pek />
        </div>
        <div className="deliveryPage__item">
          <Del />
        </div>
        <div className="deliveryPage__item">
          <Cdek />
        </div>
        <div className="deliveryPage__item">
          <Dimex />
        </div>
      </div>
    </div>
  </div>
);

export default Delivery;
