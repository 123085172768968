import React from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import { withRouter, Switch, Route, Redirect } from "react-router-dom";
import axios from 'axios';
import Spinner from 'react-spinkit';

import "./index.scss";
import { deleteBasketOrders } from '../../store/order/actions';
import { openConfirmationModal } from "../../store/helpers/actions";
import Delivery from "../../components/CheckoutModalDelivery";
import Payment from "../../components/CheckoutModalPaymentInfo";
import OrderInfo from "../../components/CheckoutModalOrderInfo";
import Button from "../../components/Button";
import ProductAmountLabel from "../../components/ProductAmountLabel";
import { ReactComponent as GoBack } from "../../img/productModal/go-back.svg";
import { ReactComponent as CloseModal } from "../../img/productModal/close-modal.svg";
import Helmeted from "../../components/Helmeted";
import { formatOrder, formatPrice } from '../../helpers';

const customStyles = {
  content: {
    background: `#F5F6F8`,
    boxShadow: "none"
  },
  overlay: {
    position: "fixed",
    top: "0px",
    left: "0px",
    right: "0px",
    bottom: "0px",
    backgroundColor: "rgba(0, 0, 0, 0.28)",
    zIndex: "200"
  }
};

class CheckoutModal extends React.Component {
  state = {
    sendingOrder: false
  }
  closeModal = () => {
    this.props.history.push("/basket");
  };

  handleRedirect = () => {
    let path = this.props.location.pathname;
    let currentPath = path.substr(path.lastIndexOf("/") + 1);
    switch (currentPath) {
      case "info":
        return this.props.history.push("/checkout/delivery", {
          isCheckingOutModal: true
        });
      case "delivery":
        // return this.handleOrderSubmit();
        return this.submitOrder();
      default:
        return null;
    }
  };

  handleOrderSubmit = () => {
    this.props.history.push("/basket");
    this.props.openConfirmationModal("payment");
  };

  handleButtonTitle = () => {
    let path = this.props.location.pathname;
    let currentPath = path.substr(path.lastIndexOf("/") + 1);
    switch (currentPath) {
      case "info":
        return "Далее";
      case "delivery":
        return "Заказать";
      case "payment":
        return "Оплатить";
      default:
        return "Далее";
    }
  };

  handleCheckoutTitle = () => {
    return this.props.basketOrders.id.slice(0, 8).toUpperCase();
  };

  handleDisableButton = () => {
    if (this.props.location.pathname === "/checkout/delivery") {
      return !this.props.deliveryFormIsValid;
    } else {
      return false;
    }
  };

  submitOrder = () => {
    const finalOrder = formatOrder(this.props.basketOrders);
    console.log(finalOrder);
    this.setState({ sendingOrder: true });
    axios
      .post("/api/v1/orders/", finalOrder)
      .then(response => {
        this.setState({ sendingOrder: false });
        this.props.deleteBasketOrders();
        this.props.history.push("/basket");
        this.props.openConfirmationModal("manager");
      })
      .catch(error => console.log(error));
  };

  render() {
    const { basketOrders, clientWidth } = this.props;
    const { sendingOrder } = this.state;
    const path = this.props.location.pathname;
    const currentPath = path.substr(path.lastIndexOf("/") + 1);

    if (basketOrders.orders.length === 0) {
      return <Redirect to="/basket" />;
    }

    return (
      <div>
        {sendingOrder && <div className="delivery__loading">
          <Spinner name="wave" color="aqua" />
        </div>}
        <Helmeted />
        {clientWidth > 940 ? (
          <Modal
            className="MainModal"
            onRequestClose={this.closeModal}
            style={customStyles}
            isOpen={true}
          >

            <div className="checkoutModal__header">
              <CloseModal
                className="checkoutModal__close"
                onClick={this.closeModal}
              />
              <span className="checkoutModal__header__title">
                Заказ {this.handleCheckoutTitle()}
              </span>
              <div className="checkoutModal__header__productsCounter-wrapper">
                <span className="checkoutModal__header__productsCounter">
                  В заказе:
                </span>
                <ProductAmountLabel amount={basketOrders.productsCounter} />
              </div>
            </div>
            <Switch>
              {" "}
              */}
              <Route path="/checkout/delivery" component={Delivery} />
              <Route path="/checkout/payment" component={Payment} />
            </Switch>
            <div className="checkoutModal__footer">
              {currentPath === "delivery" ? null : (
                <div
                  className="checkoutModal__footer__goback"
                  onClick={() => this.props.history.goBack()}
                >
                  <GoBack className="checkoutModal__footer__goback__icon" />
                  <span>Назад</span>
                </div>
              )}
              <div className="checkoutModal__footer__price">
                <span className="checkoutModal__footer__price__label">
                  Итого к оплате:
                </span>
                <span className="checkoutModal__footer__price__value">
                  {formatPrice(basketOrders.totalPrice)} руб
                </span>
              </div>
              <div className="checkoutModal__footer__buttonwr">
                <Button
                  handler={this.handleRedirect}
                  disabled={this.handleDisableButton()}
                >
                  {this.handleButtonTitle()}
                </Button>
              </div>
            </div>
          </Modal>
        ) : (
            <div className="checkoutModal__mobile">
              <div className="checkoutModal__header">
                <div className="checkoutModal__close" onClick={this.closeModal}>
                  <CloseModal />
                </div>
                <span className="checkoutModal__header__title">
                  Заказ {this.handleCheckoutTitle()}
                </span>
                <div className="checkoutModal__header__productsCounter-wrapper">
                  <span className="checkoutModal__header__productsCounter">
                    В заказе:
                </span>
                  <ProductAmountLabel amount={basketOrders.productsCounter} />
                </div>
              </div>

              <Switch>
                <Route path="/checkout/delivery" component={Delivery} />
                <Route path="/checkout/payment" component={Payment} />
              </Switch>

              <div className="checkoutModal__footer">
                {currentPath === "delivery" ? null : (
                  <div
                    className="checkoutModal__footer__goback"
                    onClick={() => this.props.history.goBack()}
                  >
                    <GoBack className="checkoutModal__footer__goback__icon" />
                    <span>Назад</span>
                  </div>
                )}

                <div className="checkoutModal__footer__wrapper">
                  <div className="checkoutModal__footer__counter">
                    <span>В заказе:</span>
                    <ProductAmountLabel amount={basketOrders.productsCounter} />
                  </div>
                  <div className="checkoutModal__footer__price">
                    <span className="checkoutModal__footer__price__label">
                      Итого к оплате:
                  </span>
                    <span className="checkoutModal__footer__price__value">
                      {formatPrice(basketOrders.totalPrice)} руб
                  </span>
                  </div>
                </div>
                <div className="checkoutModal__footer__buttonwr">
                  <Button
                    handler={this.handleRedirect}
                    disabled={this.handleDisableButton()}
                  >
                    {this.handleButtonTitle()}
                  </Button>
                </div>
              </div>
            </div>
          )}
      </div>
    );
  }
}

const mapStateToProps = ({ order, helpers }) => {
  return {
    order: order.order,
    basketOrders: order.basketOrders,
    deliveryFormIsValid: helpers.deliveryFormIsValid,
    clientWidth: helpers.clientWidth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    openConfirmationModal: tab => dispatch(openConfirmationModal(tab)),
    deleteBasketOrders: () => dispatch(deleteBasketOrders())
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CheckoutModal)
);
