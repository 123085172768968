import * as types from "./actionTypes";

export const setTypeFilter = typeFilter => {
  return {
    type: types.REQUEST_TYPE,
    typeFilter
  };
};

export const getTypes = typeFilter => {
  return {
    type: types.FETCH_TYPES,
    typeFilter: typeFilter
  };
};

export const getColors = () => {
  return {
    type: types.FETCH_COLORS
  };
};

export const getOurWorks = () => {
  return {
    type: types.FETCH_OUR_WORKS
  };
};

export const setEmptyModel = () => {
  return {
    type: types.SET_CURRENT_MODEL
  };
};

export const setGenderFilter = genderFilter => {
  return {
    type: types.SET_GENDER_FILTER,
    genderFilter
  };
};

export const setTextFilter = textFilter => {
  return {
    type: types.SET_TEXT_FILTER,
    textFilter
  };
};

export const setCatalogueColorFilter = color => {
  return {
    type: types.FETCH_COLORED_MODELS,
    color
  };
};

export const setCurrentModel = (
  id,
  hex,
  fromBasket,
  model,
  gender,
  productType
) => {
  return {
    type: types.FETCH_MODEL,
    id,
    hex,
    fromBasket,
    model,
    gender,
    productType
  };
};