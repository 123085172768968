import React, { Component } from 'react';
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import "./index.scss";
import Model from "../../components/Model";
import Filters from "../../components/Filters";
import axios from 'axios';
import Helmeted from '../../components/Helmeted';

class Catalogue extends Component {

  state = {
    metaData: {
      description: "",
      keywords: "",
      title: ""
    }
  }

  mobileFilters = React.createRef();

  componentDidMount() {
    this.getMetaData();
  }

  getMetaData = () => {
    axios.get(`/api/v1/seo?url=https://futbolka.market${this.props.location.pathname}`)
      .then(({ data }) => {
        if (data) {
          this.setState({ metaData: data });
        }
      })
  }

  handleModelPick = (productType, gender, id) => {
    this.setState({ cataloguePallete: false });
    this.props.history.push(`/${productType}/${gender}/${id}/info`, {
      isProductModal: true
    });
  };

  getRusProductType = () => {
    let productType = this.props.location.pathname.replace("/", "");
    switch (productType) {
      case "jersey":
        return "Футболка";
      case "sweatshirt":
        return "Толстовка";
      case "polo":
        return "Поло";
      default:
        return "";
    }
  };

  render() {

    const { filteredModels } = this.props;
    const productType = this.props.location.pathname.replace("/", "");
    const rusProductType = this.getRusProductType();

    return (
      <div className="catalogue page">
        <Helmeted />
        <div className="catalogue__body">
          <Filters />
          <div className="catalogue__models">
            {filteredModels.map(model => (
              <Model
                preview={model.preview}
                model={model.name}
                gender={model.gender}
                cost={model.cost}
                id={model.id}
                key={model.id}
                productType={productType}
                rusProductType={rusProductType}
                getModelInfo={this.handleModelPick}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ catalog }) => {
  return {
    filteredModels: catalog.filteredModels
  };
};

export default connect(mapStateToProps)(Catalogue);
