import React from 'react';

import './index.scss';

const SizesTableInfo = ({ sizes, sleeveLength }) => (
  sizes.length > 1 ?
    <table className="sizesInfo">
      <tbody className={["sizesInfo__body", "info__table-show"].join(" ")}>
        <tr className="sizesInfo__row">
          <td className="sizesInfo__row__title">
            Размер
          </td>
          {sizes.map((size, index) =>
            <td
              key={size.title + index}
              className="sizesInfo__col">
              {size.title}
            </td>)}
            { sleeveLength ? null : <td className="sizesInfo__row__image" rowSpan={sleeveLength ? "5" : "4"} />}
            { sleeveLength ? <td className="sizesInfo__row__image--sweetshot" rowSpan={sleeveLength ? "5" : "4"} /> : null}
        </tr>
        <tr className="sizesInfo__row">
          <td className="sizesInfo__row__title">
            Российский р-р
          </td>
          {sizes.map((size, index) =>
            <td
              key={size.rus_title + index + size.title}
              className="sizesInfo__col">
              {size.rus_title}
            </td>)}
        </tr>
        <tr className="sizesInfo__row">
          <td className="sizesInfo__row__title">
            Ширина, см (А)
          </td>
          {sizes.map((size, index) =>
            <td
              key={size.width + 2 * size.length + index}
              className="sizesInfo__col">
              {size.width}
            </td>)}
        </tr>
        <tr className="sizesInfo__row">
          <td className="sizesInfo__row__title">
            Длина, см (B)
          </td>
          {sizes.map((size, index) =>
            <td
              key={size.title + 2 * size.length + index}
              className="sizesInfo__col">
              {size.length}
            </td>)}
        </tr>
        {sleeveLength ? <tr className="sizesInfo__row">
          <td className="sizesInfo__row__title">
            Длина рукава, см (С)
          </td>
          {sizes.map((size, index) =>
            <td
              key={size.title + 2 * size.length + index}
              className="sizesInfo__col">
              {size.sleeve_length}
            </td>)}
        </tr>: null}
      </tbody>
    </table> : null
)

export default SizesTableInfo;
