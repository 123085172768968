import React from "react";
import { connect } from "react-redux";

import "./index.scss";
import BasketItem from "../../components/BasketItem";
import MobileBasketItem from "../../components/MobileBasketItem";
import TabletBasketItem from "../../components/TabletBasketItem";
import Button from "../../components/Button";
import EmptyBasket from "./emptyBasket";
import Helmeted from "../../components/Helmeted";
import { formatPrice } from '../../helpers';

class Basket extends React.Component {

  submitOrder = () => {
    this.props.history.push("/checkout/delivery", { isCheckingOutModal: true });
  };

  redirectToShirts = () => {
    let productType;
    this.props.order.productType
      ? (productType = this.props.order.productType)
      : (productType = "jersey");
    this.props.history.push(`/${productType}`);
  };

  handleCheckoutTitle = () => {
    return this.props.basketOrders.id.slice(0, 8).toUpperCase();
  };

  render() {
    const { basketOrders, clientWidth } = this.props;

    return (
      <div className="basket page">
        <Helmeted />
        {basketOrders.orders.length > 0 ? (
          <div className="basket__body">
            <div className="basket__order">
              <div className="basket__order__group">
                <span className="basket__order__title">Заказ {this.handleCheckoutTitle()}</span>
              </div>
              <div>
                <span className="basket__order__inorder">В заказе: </span>
                <span className="basket__order__amount">{basketOrders.productsCounter} шт.</span>
              </div>
            </div>
            {clientWidth > 540
              ? clientWidth <= 1100
                ? basketOrders.orders.map(order => (
                  <TabletBasketItem key={order.id} basket order={order} />
                ))
                : basketOrders.orders.map(order => (
                  <BasketItem key={order.id} basket order={order} />
                ))
              : basketOrders.orders.map(order => (
                <MobileBasketItem key={order.id} basket order={order} />
              ))}
            <div className="basket__footer">
              <div className="basket__footer__amount">
                <span className="basket__footer__amount__label">В заказе: </span>
                <span className="basket__footer__amount__value">{basketOrders.productsCounter} шт.</span>
              </div>
              <div className="basket__footer__summ">
                <span className="basket__footer__summ__label">Итого к оплате:</span>
                <span className="basket__footer__summ__value">{formatPrice(basketOrders.totalPrice)} руб</span>
              </div>
              <Button handler={this.submitOrder}>
                Оформить заказ
              </Button>
            </div>
          </div>
        ) : (
            <EmptyBasket redirectToShirts={this.redirectToShirts} />
          )}
      </div>
    );
  }
}

const mapStateToProps = ({ order, helpers }) => {
  return {
    order: order.order,
    basketOrders: order.basketOrders,
    clientWidth: helpers.clientWidth
  };
};

export default connect(mapStateToProps)(Basket);
