import React from 'react';

import './index.scss';

const Color = ({ color, handler, sizesTable }) => (
  <div
    onClick={handler}
    className="color"
    style={{
      backgroundColor: `${color}`,
      border: color === '#ffffff' ? '1px solid #DEDEDE' : 'none',
      width: sizesTable ? '28px' : '26px',
      height: sizesTable ? '28px' : '26px'
    }}>
    <div
      className="color__innerCircle"
      style={{
        borderColor: color === '#ffffff' ? '#dedede' : '#ffffff',
        display: sizesTable ? 'none' : 'block',
        bottom: color === '#ffffff' ? '1px' : '0',
      }} />
  </div>
);

export default Color;