import React from "react";
import { connect } from "react-redux";
import difference from "lodash.difference";

import "./index.scss";
import { updateOrder } from "../../store/order/actions";
import { ReactComponent as PhotoNotAvailable } from "../../img/shirtPhotoNotAvailable.svg";

class LayeredPreview extends React.Component {
  handlePreview = () => {
    const { order, previews, alreadyLoadedPreview } = this.props;
    const colors = Object.keys(order.sizes);
    const coloredShirtToDisplay = [];
    if (alreadyLoadedPreview) {
      coloredShirtToDisplay.push(alreadyLoadedPreview);
    }

    colors.forEach(color => {
      previews.forEach(preview => {
        if (preview.color.hex === color) {
          return coloredShirtToDisplay.push(preview.image);
        }
      });
    });
    const previewsArray = [];
    coloredShirtToDisplay.forEach(imageUrl => {
      if (previews.length === 0) {
        previewsArray.push(alreadyLoadedPreview);
      } else {
        return previews.forEach(preview => {
          if (
            preview.image === imageUrl &&
            colors.indexOf(preview.color.hex) !== -1
          ) {
            previewsArray.push(preview.image);
          } else return;
        });
      }
    });

    return previewsArray.filter((v, i, a) => a.indexOf(v) === i).slice(0, 4);
  };

  confirmPreviews = () => {
    let { order, previews } = this.props;
    let pickedColors = [];
    Object.keys(order.sizes).forEach(color => {
      let colorIsPicked =
        order.sizes[color].reduce(
          (acc, value) => Number(acc) + Number(value),
          0
        ) > 0;
      if (colorIsPicked) {
        pickedColors.push(color);
      }
    });

    let filteredPreviews = [];

    pickedColors.forEach(color => {
      let coloredPreview = previews.filter(
        preview => preview.color.hex === color
      )[0];
      if (coloredPreview) {
        filteredPreviews.push(coloredPreview.image);
      }
    });
    return filteredPreviews;
  };

  componentDidUpdate(prevProps, prevState) {
    const updatedLayeredPreviews = this.confirmPreviews();
    const order = this.props.order;
    const updatedOrder = Object.assign({}, order, {
      layeredPreviews: updatedLayeredPreviews
    });
    const previewsChanged =
      difference(updatedLayeredPreviews, prevProps.order.layeredPreviews)
        .length > 0;
    const previewsChanged2 =
      difference(prevProps.order.layeredPreviews, updatedLayeredPreviews)
        .length > 0;
    if (previewsChanged || previewsChanged2) {
      this.props.setLayeredPreviews(updatedOrder);
    }
  }

  render() {
    return (
      <div className="layeredPreview">
        <div className="layeredPreview__image__wrapper">
          {this.handlePreview().length > 0 ? (
            this.handlePreview().map((preview, index) => (
              <img
                src={preview}
                alt=""
                className="layeredPreview__image"
                key={preview}
                style={{
                  transform: `scale(${index === 0 ? 1 : 1 - index * 0.05})`,
                  zIndex: 100 - index
                }}
              />
            ))
          ) : (
            <div className="preview__image__nophoto">
              <PhotoNotAvailable />
              <span className="preview__image__nophoto__label">
                Данная модель есть в другом цвете
              </span>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ order }) => {
  return {
    order: order.order
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setLayeredPreviews: updatedOrderWithPreviews =>
      dispatch(updateOrder(updatedOrderWithPreviews))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LayeredPreview);
