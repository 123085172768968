import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import "./index.scss";
import BasketItem from "../BasketItem";
import MobileBasketItem from "../MobileBasketItem";
import TabletBasketItem from "../../components/TabletBasketItem";

const OrderInfo = ({ basketOrders, clientWidth }) => (
  <div className="orderInfo">
    <h3 className="orderInfo__header">Оформление заказа</h3>
    {basketOrders.orders.length > 0 ? null : <Redirect to="/basket" />}
    {clientWidth > 540
      ? clientWidth < 1200
        ? basketOrders.orders.map(order => (
            <TabletBasketItem key={order.id} order={order} orderItem={true} />
          ))
        : basketOrders.orders.map(order => (
            <BasketItem key={order.id} order={order} orderItem={true} />
          ))
      : basketOrders.orders.map(order => (
          <MobileBasketItem key={order.id} order={order} />
        ))}
  </div>
);

const mapStateToProps = ({ order, helpers }) => {
  return {
    basketOrders: order.basketOrders,
    clientWidth: helpers.clientWidth
  };
};

export default connect(mapStateToProps)(OrderInfo);
