export const TOGGLE_PRODUCT_MODAL = "TOGGLE_PRODUCT_MODAL";

export const OPEN_CONFIRMATION_MODAL = "OPEN_CONFIRMATION_MODAL";

export const CLOSE_CONFIRMATION_MODAL = "CLOSE_CONFIRMATION_MODAL";

export const TOGGLE_CALCULATOR_MODAL = "TOGGLE_CALCULATOR_MODAL";

export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";

export const SET_CLIENT_WIDTH = "SET_CLIENT_WIDTH";

export const ANIMATE_BASKET = "ANIMATE_BASKET";

export const BASKET_IS_ANIMATING = "BASKET_IS_ANIMATING";

export const DELIVERY_FORM_IS_VALID = "DELIVERY_FORM_IS_VALID";

export const CHANGE_CALCULATOR_ACTIVE_METHOD =
  "CHANGE_CALCULATOR_ACTIVE_METHOD";

export const OPEN_COLOR_PALLETE = "OPEN_COLOR_PALLETE";
