import React from "react";

import "../index.scss";
import { ReactComponent as PhoneIcon } from "../../../img/confirmationModal/phone.svg";
import { ReactComponent as Mail } from "../../../img/confirmationModal/mail.svg";

const Phone = ({
  handlePhoneInput,
  handlePhoneBlur,
  inputIsValid,
  inputTouched,
  submitCallbackRequest,
  value
}) => (
  <div className="phone">
    <h4 className="phone__title">
      Оставьте номер телефона и менеджер свяжется с вами
    </h4>
    <form className="phone__inputgroup">
      <div className="phone__inputgroup__input__wrapper">
        <input
          onInput={handlePhoneInput}
          onBlur={handlePhoneBlur}
          autoFocus
          placeholder="Номер телефона"
          type="tel"
          value={value}
          maxLength="11"
          className={[
            "phone__inputgroup__input",
            !inputIsValid && inputTouched
              ? "phone__inputgroup__input-invalid"
              : ""
          ].join(" ")}
        />
      </div>
      <button
        className={["phone__inputgroup__button"].join(" ")}
        onClick={submitCallbackRequest}
      >
        Жду звонка
      </button>
    </form>
    <div className="phone__footer">
      <h5 className="phone__footer__title">
        Или свяжитесь с нами прямо сейчас:
      </h5>
      <div className="phone__footer__group">
        <div className="phone__footer__item">
          <PhoneIcon className="phone__footer__icon" />
          <span className="phone__footer__phone">+7 (928) 226 53 81</span>
        </div>
        <div className="phone__footer__item">
          <Mail className="phone__footer__icon" />
          <span className="phone__footer__phone">mail@futbolka.market</span>
        </div>
      </div>
    </div>
  </div>
);

export default Phone;
