import React from "react";
import { connect } from "react-redux";
import Spinner from "react-spinkit";

import "./index.scss";
import { updateOrder } from "../../store/order/actions";
import PreviewProduct from "../PreviewProduct";
import SizesTableInfo from "../SizesTableInfo";
import { ReactComponent as Arrow } from '../../img/productModal/go-back.svg';

class Info extends React.Component {

  getProductComposition = () => {
    let productComposition = this.props.currentModel.fabric.composition;
    let productCompositionStrings = [];
    let compositionQuantity = Object.keys(productComposition).length;
    Object.keys(productComposition).forEach((key, index) => {
      productCompositionStrings.push(
        `${key} ${productComposition[key]}%${
        index + 1 === compositionQuantity ? "" : ", "
        }`
      );
    });
    return productCompositionStrings;
  };

  getProductPriceTable = () => {
    let productPriceGrid = this.props.currentModel.price_grid;
    let productPriceArray = [];
    Object.keys(productPriceGrid).forEach(key =>
      productPriceArray.push({ amount: key, price: productPriceGrid[key] })
    );
    return productPriceArray;
  };

  getRusProductType = () => {
    let productType = this.props.match.params.productType;
    switch (productType) {
      case "jersey":
        return "Футболка";
      case "sweatshirt":
        return "Толстовка";
      case "polo":
        return "Поло";
      default:
        return "";
    }
  };

  redirectToCatalog = () => {
    this.props.history.push(`/${this.props.order.productType}`);
    console.log("что это",this.props.currentModel)
  }

  render() {
    const model = this.props.currentModel;

    return (
      <div>
        {!this.props.loadingModel ? (
          <div className="info">
            <div className="info__goback__wrapper">
              <div onClick={this.redirectToCatalog} className="info__goback">
                <Arrow className="info__goback__icon" />
                <span className="info__goback__label">Каталог</span>
              </div>
            </div>
            <div className="info__header">
              <span className="info__header__title">
                {`${this.getRusProductType()} ${model.name}`}
              </span>
            </div>
            <PreviewProduct
              previews={model.preview.images}
              colors={Object.keys(this.props.order.sizes)}
            />
            <div className="info__description__wrapper">
            <div className="info__description" dangerouslySetInnerHTML={{ __html: model.description }}></div>
              <div className="info__description__group">
              <div className="info__description__group__item">

              <div className="info__description__material">
                <span className="info__description__material__label">
                  Состав
                </span>
                <span className="info__description__material__value">
                  {this.getProductComposition().map(composition => composition)}
                </span>
              </div>
              <div className="info__description__material">
                <span className="info__description__material__label">
                  Плотность
                </span>
                <span className="info__description__material__value">
                  {model.fabric.density} г/м
                </span>
              </div>
            </div>
            <div className="info__description__group__item">
            <div className="info__description__material">
              <span className="info__description__material__label">
                Количество
              </span>

                {this.getProductPriceTable().map((priceRow, index) => (
                  <span key={priceRow.amount + index}
                  className="info__prices__label" >
                    {priceRow.amount}
                  </span>
                ))}

            </div>
            <div className="info__description__material">
              <span className="info__description__material__label">
                Стоимость
              </span>
              {this.getProductPriceTable().map((priceRow, index) => (
                <span
                  key={priceRow.price + index}
                  className="info__prices__label"
                >
                  {priceRow.price}
                </span>
              ))}
            </div>
            </div>
          </div>

              <div className="info__sizesTable">
                <div className="info__sizesTable__scroller">
                  <SizesTableInfo sizes={model.size_info} sleeveLength={model.sleeve_length_img}/>
                </div>
              </div>
            </div>
          </div>
        ) : (
            <Spinner
              className="productModal__spinner"
              name="ball-clip-rotate-multiple"
            />
          )}
      </div>
    );
  }
}

const mapStateToProps = ({ order, catalog }) => {
  return {
    order: order.order,
    currentModel: catalog.currentModel,
    catalogueColorFilter: catalog.catalogueColorFilter,
    loadingModel: catalog.loadingModel
  };
};

const mapDispatchToPros = dispatch => {
  return {
    setEmptyOrder: updatedOrder => dispatch(updateOrder(updatedOrder))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToPros
)(Info);
