import React from 'react';

import './index.scss';
import ico1 from '../../img/case/case-ico1.png';
import Elis from '../../img/case/case-ico2.png';
import Gazprom from '../../img/case/case-ico3.png';
import Harbegon from '../../img/case/case-ico4.png';
import Katusha from '../../img/case/case-ico5.png';
import PitCofe from '../../img/case/case-ico6.png';
import Sicilia from '../../img/case/case-ico7.png';
import Lukoil from '../../img/case/case-ico8.png';
import MozartHouse from '../../img/case/case-ico9.png';
import PoiskHome from '../../img/case/case-ico10.png';
import Rostselmash from '../../img/case/case-ico11.png';
import ico12 from '../../img/case/case-ico12.png';

const Partners = () => (
  <div className="partners">
    <div className="partners__container">
      <img src={Gazprom} className="partners__img3" alt="ГАЗПРОМ" />
      <img src={PitCofe} className="partners__img6" alt="Пить кофе" />
      <img src={ico12} className="partners__img12" alt="" />
      <img src={Elis} className="partners__img2" alt="ELIS" />
      <img src={Katusha} className="partners__img5" alt="Катюша" />
      <img src={Sicilia} className="partners__img7" alt="Сицилия" />
      <img src={Harbegon} className="partners__img4" alt="HARBEGON" />
      <img src={MozartHouse} className="partners__img9" alt="Mozart House" />
      <img src={Lukoil} className="partners__img8" alt="ЛУКОЙЛ" />
      <img src={ico1} className="partners__img1" alt="" />
      <img src={PoiskHome} className="partners__img10" alt="Poisk Home" />
      <img src={Rostselmash} className="partners__img11" alt="РОСТСЕЛЬМАШ" />
    </div>
    <div className="partners__container">
      <img src={Gazprom} className="partners__img3" alt="ГАЗПРОМ" />
      <img src={PitCofe} className="partners__img6" alt="Пить кофе" />
      <img src={ico12} className="partners__img12" alt="" />
      <img src={Elis} className="partners__img2" alt="ELIS" />
      <img src={Katusha} className="partners__img5" alt="Катюша" />
      <img src={Sicilia} className="partners__img7" alt="Сицилия" />
      <img src={Harbegon} className="partners__img4" alt="HARBEGON" />
      <img src={MozartHouse} className="partners__img9" alt="Mozart House" />
      <img src={Lukoil} className="partners__img8" alt="ЛУКОЙЛ" />
      <img src={ico1} className="partners__img1" alt="" />
      <img src={PoiskHome} className="partners__img10" alt="Poisk Home" />
      <img src={Rostselmash} className="partners__img11" alt="РОСТСЕЛЬМАШ" />
    </div>
  </div>
);

export default Partners;