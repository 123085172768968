import * as types from "./actionTypes";
import uuidv1 from "uuid/v1";
import {
  countProductsPrice,
  makeEmptyOrder,
  countOrderWeight,
  countBasketOrderPrice,
  countBasketOrderProducts,
  countBaskerOrderBoxes
} from "../../helpers";

const initialState = {
  order: {
    sizes: {
      "#f0f0ec": [0, 0, 0, 0, 0, 0, 0]
    },
    prints: [],
    price: [],
    id: uuidv1()
  },
  basketOrders: {
    orders: [],
    id: uuidv1()
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.ORDER:
      const productsCounter = Object.keys(action.order.sizes)
        .map(key => action.order.sizes[key])
        .map(
          sizes => sizes.reduce((acc, value) => Number(acc) + Number(value)),
          0
        )
        .reduce((acc, value) => {
          if (typeof acc === null) {
            return;
          } else return acc + Number(value);
        }, 0);
      const productsPrice = countProductsPrice(
        productsCounter,
        state.order.price_grid
      );
      let updatedOrder = JSON.parse(JSON.stringify(action.order));
      return {
        ...state,
        order: {
          ...updatedOrder,
          productsCounter,
          totalPrice: productsPrice
        }
      };

    case types.SET_EMPTY_ORDER:
      let {
        hex,
        previews,
        price_grid,
        size_info,
        modelName,
        model,
        gender,
        productType
      } = action;
      let color;
      state.catalogueColorFilter
        ? (color = state.catalogueColorFilter)
        : (color = action.hex);
      return {
        ...state,
        order: makeEmptyOrder(
          hex,
          previews,
          price_grid,
          size_info,
          modelName,
          model,
          gender,
          productType
        )
      };
    case types.ADD_BASKET_ORDER:
      let basketOrderProductsCounter = countBasketOrderProducts(
        action.basketOrders
      );
      let basketOrderTotalPrice = countBasketOrderPrice(action.basketOrders);
      let basketOrderWeight = countOrderWeight(action.basketOrders);
      let baskerOrderBoxes = countBaskerOrderBoxes(action.basketOrders);
      return {
        ...state,
        basketOrders: {
          ...action.basketOrders,
          orderWeight: basketOrderWeight,
          productsCounter: basketOrderProductsCounter,
          totalPrice: basketOrderTotalPrice,
          boxes: baskerOrderBoxes
        }
      };
    case types.DELETE_BASKET_ORDERS:
      return {
        ...state,
        basketOrders: {
          orders: [],
          id: uuidv1()
        }
      };
    default:
      return state;
  }
};
