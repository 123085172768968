import React from 'react';

import './index.scss';
import Button from '../Button';

const Calculator = React.forwardRef(({
  handlePrintMethod,
  calculatorActiveMethod,
  handleCalculateApplication,
  state,
  handleStraigthPrintingSizeChange,
  showStraigthPrintingOptions,
  handleChromacityChange,
  orderPrint,
  straightPrintingSizes
}, ref) => (
    <div className="calculator__frame">
      <div className="calculator__body">
        <h3 className="calculator__title">
          Онлайн калькулятор
        </h3>
        <div className="calculator__body__wrapper">
          <div className="calculator__group">
            <div
              onClick={() => handlePrintMethod('silkscreen')}
              className={[
                "calculator__printMethod",
                calculatorActiveMethod === 'silkscreen' ? "calculator__printMethod-active" : ''
              ].join(" ")}>
              Шелкография
            </div>
            <div
              onClick={() => handlePrintMethod('straightPrinting')}
              className={[
                "calculator__printMethod",
                calculatorActiveMethod === 'straightPrinting' ? "calculator__printMethod-active" : ''
              ].join(" ")}>
              Прямая печать
            </div>
          </div>
          <div className="calculator__group">
            <div className="calculator__group__item">
              <div className="calculator__amount">
                <span className="calculator__amount__label">
                  Кол-во изделий
                </span>
                <input
                  onChange={(event) => handleCalculateApplication(event, 'sumItems')}
                  className="calculator__amount__input"
                  type="tel"
                  maxLength={4}
                  value={state[calculatorActiveMethod].sumItems === 0 ? "" : state[calculatorActiveMethod].sumItems} />
              </div>
              <div
                className={[
                  "calculator__amount",
                  calculatorActiveMethod === 'straightPrinting' ? 'calculator__amount--hidden' : ''
                ].join(" ")}>
                <span className="calculator__amount__label">
                  Размер,см
                </span>
                <input
                  onChange={(event) => handleCalculateApplication(event, 'sizeHeight')}
                  className="calculator__amount__input"
                  type="tel"
                  maxLength={3}
                  value={state[calculatorActiveMethod].sizeHeight === 0 ? "" : state[calculatorActiveMethod].sizeHeight} />
                <span className="calculator__amount__x">
                  x
                </span>
                <input
                  className="calculator__amount__input"
                  onChange={(event) => handleCalculateApplication(event, 'sizeWidth')}
                  type="tel"
                  maxLength={3}
                  value={state[calculatorActiveMethod].sizeWidth === 0 ? "" : state[calculatorActiveMethod].sizeWidth} />
              </div>
              <div
                className={[
                  "calculator__amount",
                  calculatorActiveMethod === 'straightPrinting' ? '' : 'calculator__amount--hidden'
                ].join(" ")}>
                <span className="calculator__amount__label">
                  Размер,см
                </span>
                <div className="calculator__amount__dropdown__wrapper">
                  <div
                    className={[
                      "calculator__amount__dropdown",
                      state.showStraigthPrintingOptions ? 'calculator__amount__dropdown__header-isOpen' : ''
                    ].join(" ")}
                    ref={ref}>
                    <div
                      className={["calculator__amount__dropdown__header"].join(" ")}
                      onClick={showStraigthPrintingOptions}>
                      <span className="calculator__amount__label">
                        {state.straightPrinting.size}
                      </span>
                    </div>
                    <div
                      className={[
                        "calculator__amount__dropdown__options",
                        state.showStraigthPrintingOptions ? "calculator__amount__dropdown__options-show" : ""
                      ].join(" ")}>
                      {straightPrintingSizes.map(size =>
                        <span
                          key={size}
                          onClick={() => handleStraigthPrintingSizeChange(size)}
                          className="calculator__amount__label calculator__amount__dropdown__item">
                          {size}
                        </span>)}
                    </div>
                  </div>
                </div>
              </div>
              <div className={calculatorActiveMethod === 'silkscreen' ? 'calculator__amount' : 'calculator__amount--hidden'}>
                <span
                  style={{ width: 'auto' }}
                  className="calculator__amount__label">
                  Количество цветов
                </span>
                <input
                  onChange={handleChromacityChange}
                  className="calculator__amount__input"
                  type="tel"
                  maxLength={1}
                  value={state[calculatorActiveMethod].chromacity === 0 ? "" : state[calculatorActiveMethod].chromacity} />
              </div>
            </div>
            <div className="calculator__group__item">
              <div className="calculator__summ">
                <div className="calculator__summ__label">
                  Стоимость <span className="calculator__summ__info">*</span>:</div>
                <div>
                  <span className="calculator__summ__value">
                    {state[calculatorActiveMethod].totalPriceApplication}
                  </span>
                  <span className="calculator__summ__currency">
                    руб
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="calculator__footer">
          <div className="calculator__footer__info">
            * Точную стоимость сообщит Вам менеджер
          </div>
          <Button handler={orderPrint}>
            Заказать нанесение
          </Button>
        </div>
      </div>
    </div>
  ));

export default Calculator;
