import React from 'react';

import './index.scss';
import Button from '../../components/Button';
import { ReactComponent as EmptyBasketIcon } from '../../img/basket/basket-empty.svg';

const EmptyBasket = ({ redirectToShirts }) => (
  <div className="basket__empty">
    <EmptyBasketIcon className="basket__empty__icon"/>
    <span className="basket__empty__title">
      Корзина пуста
    </span>
    <Button handler={redirectToShirts}>
      В каталог
    </Button>
  </div>
)

export default EmptyBasket;