import React from 'react'

import './index.scss';
import { ReactComponent as Arrow } from '../../img/news/arrow.svg';

const NewsItem = ({ preview, title, handler, id }) => (
  <div
    onClick={() => handler(id)}
    className="newsItem">
    <div className="newsItem__image">
      <img src={preview} alt={title} />
    </div>
    <div className="newsItem__body">
      <p className="newsItem__body__title">
        {title}
      </p>
      <span className="newsItem__body__line" />
      <div className="newsItem__body__details">
        <span>Подробнее</span>
        <Arrow />
      </div>
    </div>
  </div>
)

export default NewsItem;
