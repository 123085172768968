import React from "react";

import "./index.scss";
import Button from "../Button";
import { ReactComponent as GoBack } from "../../img/productModal/go-back.svg";
import CalculatorContacts from "./contacts";

const MobileCalculator = React.forwardRef(
  (
    {
      state,
      handlePrintMethod,
      calculatorActiveMethod,
      handleCalculateApplication,
      handleChromacityChange,
      handleStraigthPrintingSizeChange,
      showStraigthPrintingOptions,
      orderPrint,
      submitOrder,
      straightPrintingSizes,
      name,
      email,
      phone,
      handleNameChange,
      handleEmailChange,
      handlePhoneChange
    },
    ref
  ) => (
    <div
      className={[
        "calculator__wrapper",
        state.ordering === true
          ? "calculator__wrapper-forward"
          : state.ordering === false
          ? "calculator__wrapper-back"
          : ""
      ].join(" ")}
    >
      <div className="calculator__frame">
        <div className="calculator__body">
          <h3 className="calculator__title">Онлайн калькулятор</h3>
          <div className="calculator__body__wrapper">
            <div
              className="calculator__group"
              style={{
                overflow: state.showStraigthPrintingOptions
                  ? "visible"
                  : "hidden"
              }}
            >
              <div
                className={[
                  "calculator__printMethod",
                  "calculator__printMethod-active"
                ].join(" ")}
                onClick={() => handlePrintMethod("silkscreen")}
              >
                Шелкография
              </div>
              <div className="calculator__group">
                <div
                  className={[
                    "calculator__group__item",
                    calculatorActiveMethod === "silkscreen"
                      ? "calculator__group-shown"
                      : "calculator__group-hidden"
                  ].join(" ")}
                >
                  <div className="calculator__amount">
                    <span className="calculator__amount__label">
                      Кол-во изделий
                    </span>
                    <input
                      onChange={event =>
                        handleCalculateApplication(event, "sumItems")
                      }
                      className="calculator__amount__input"
                      type="tel"
                      maxLength={4}
                      value={
                        state.silkscreen.sumItems === 0
                          ? ""
                          : state.silkscreen.sumItems
                      }
                    />
                  </div>
                  <div className="calculator__amount">
                    <span className="calculator__amount__label calculator__amount__label-size">
                      Размер,см
                    </span>
                    <input
                      onChange={event =>
                        handleCalculateApplication(event, "sizeHeight")
                      }
                      className="calculator__amount__input"
                      type="tel"
                      maxLength={3}
                      value={
                        state.silkscreen.sizeHeight === 0
                          ? ""
                          : state.silkscreen.sizeHeight
                      }
                    />
                    <span className="calculator__amount__x">x</span>
                    <input
                      className="calculator__amount__input"
                      onChange={event =>
                        handleCalculateApplication(event, "sizeWidth")
                      }
                      type="tel"
                      maxLength={3}
                      value={
                        state.silkscreen.sizeWidth === 0
                          ? ""
                          : state.silkscreen.sizeWidth
                      }
                    />
                  </div>
                  <div className="calculator__amount">
                    <span className="calculator__amount__label">
                      Количество цветов
                    </span>
                    <input
                      onChange={handleChromacityChange}
                      className="calculator__amount__input"
                      type="tel"
                      maxLength={1}
                      value={state.silkscreen.chromacity}
                    />
                  </div>
                </div>
              </div>
              <div
                className="calculator__printMethod calculator__printMethod-active"
                onClick={() => handlePrintMethod("straightPrinting")}
              >
                Прямая печать
              </div>
              <div
                className="calculator__group"
                style={{
                  overflow: state.showStraigthPrintingOptions
                    ? "visible"
                    : "hidden"
                }}
              >
                <div
                  className={[
                    "calculator__group__item",
                    calculatorActiveMethod === "straightPrinting"
                      ? "calculator__group-shown"
                      : "calculator__group-hidden"
                  ].join(" ")}
                >
                  <div className="calculator__amount">
                    <span className="calculator__amount__label">
                      Кол-во изделий
                    </span>
                    <input
                      onChange={event =>
                        handleCalculateApplication(event, "sumItems")
                      }
                      className="calculator__amount__input"
                      type="tel"
                      maxLength={4}
                      value={
                        state.straightPrinting.sumItems === 0
                          ? ""
                          : state.straightPrinting.sumItems
                      }
                    />
                  </div>
                  <div className="calculator__amount">
                    <span className="calculator__amount__label calculator__amount__label-size">
                      Размер,см
                    </span>
                    <div className="calculator__amount__dropdown__wrapper">
                      <div
                        className={[
                          "calculator__amount__dropdown",
                          state.showStraigthPrintingOptions
                            ? "calculator__amount__dropdown__header-isOpen"
                            : ""
                        ].join(" ")}
                        ref={ref}
                      >
                        <div
                          className={[
                            "calculator__amount__dropdown__header"
                          ].join(" ")}
                          onClick={showStraigthPrintingOptions}
                        >
                          <span className="calculator__amount__label">
                            {state.straightPrinting.size}
                          </span>
                        </div>
                        <div
                          className={[
                            "calculator__amount__dropdown__options",
                            state.showStraigthPrintingOptions
                              ? "calculator__amount__dropdown__options-show"
                              : ""
                          ].join(" ")}
                        >
                          {straightPrintingSizes.map(size => (
                            <span
                              key={size}
                              onClick={() =>
                                handleStraigthPrintingSizeChange(size)
                              }
                              className="calculator__amount__label calculator__amount__dropdown__item"
                            >
                              {size}
                            </span>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="calculator__group__item">
              <div className="calculator__summ">
                <div className="calculator__summ__label">Стоимость:<span className="calculator__summ__info">*</span></div>

                <div>
                  <span className="calculator__summ__value">
                    {state[calculatorActiveMethod].totalPriceApplication}
                  </span>
                  <span className="calculator__summ__currency">руб</span>

                </div>
              </div>
            </div>
          </div>
          <div className="calculator__footer">
            <div className="calculator__footer__info">
              * Точную стоимость сообщит Вам менеджер
            </div>
            <Button handler={orderPrint}>Заказать нанесение</Button>
          </div>
        </div>
      </div>
      <CalculatorContacts goback={orderPrint} submitOrder={submitOrder} />
    </div>
  )
);

export default MobileCalculator;
