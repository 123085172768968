import React, { forwardRef } from "react";

import "./index.scss";
import sto from "../../img/application/print.svg";
import clock from "../../img/application/clock.png";
import wallet from "../../img/application/wallet.png";
import guarantee from "../../img/application/guarantee.png";
import why from "../../img/application/canvas.jpg";

const Why = forwardRef((props, ref) => (
  <div className="why">
    <img src={why} alt="футболки оптом" className="why__img" ref={ref} />
    <div className="why__content">
      <div className="why__content__text">Почему мы</div>
    </div>
    <div className="why__info">
      <div className="why__info__content">
        <div className="why__info__content__item">
          <img src={sto} alt="" />
          <div className="why__info__content__item--caption">
            Стойкая печать
          </div>
        </div>
        <div className="why__info__content__item">
          <img src={clock} alt="" />
          <div className="why__info__content__item--caption">Быстрые сроки</div>
        </div>
        <div className="why__info__content__item why__info__content__item-prices">
          <img src={wallet} alt="" />
          <div className="why__info__content__item--caption">Выгодные цены</div>
        </div>
        <div className="why__info__content__item">
          <img src={guarantee} alt="" />
          <div className="why__info__content__item--caption">Гарантия</div>
        </div>
      </div>
    </div>
  </div>
));

export default Why;
