export function countProductsPrice(productsCounter, priceGrid) {
  let priceGridKeys = Object.keys(priceGrid).map(priceGridKey =>
    Number(priceGridKey.replace("+", ""))
  );
  let priceGrindValues = Object.keys(priceGrid).map(key => priceGrid[key]);
  let finalPrice;
  priceGridKeys.reduce((acc, currentValue, index) => {
    if (productsCounter === 1) {
      finalPrice = priceGrindValues[0];
      return;
    } else if (productsCounter < currentValue) {
      finalPrice = priceGrindValues[index - 1];
      return currentValue;
    } else if (productsCounter >= priceGridKeys[priceGridKeys.length - 1]) {
      finalPrice = priceGrindValues[priceGrindValues.length - 1];
    }
    return currentValue;
  }, 0);
  let productsPrice = finalPrice * productsCounter;
  return productsPrice;
}