import React from 'react';
import { withRouter } from 'react-router-dom';

class ScrollToTop extends React.Component {

  isCheckingOut = prevLocation => {
    const { location } = this.props;
    const isCheckingOutModal = !!(
      location.state &&
      location.state.isCheckingOutModal
    );
    const isProductModalPrevLocation = !!(
      prevLocation.state &&
      prevLocation.state.isCheckingOutModal
    )
    let clientWidth = window.innerWidth;
    return isCheckingOutModal && clientWidth > 940 || (isProductModalPrevLocation && location.pathname.indexOf('checkout') !== -1 && clientWidth > 940);
  }

  isCatalogue = prevLocation => {
    const { location } = this.props;
    const isProductModal = !!(
      location.state &&
      location.state.isProductModal
    );
    const isProductModalPrevLocation = !!(
      prevLocation.state &&
      prevLocation.state.isProductModal
    )
    let clientWidth = window.innerWidth;
    return isProductModal && clientWidth > 940 || (isProductModalPrevLocation && location.pathname.indexOf('jersey') !== -1 && clientWidth > 940);
  }

  isBasket = prevLocation => {
    const { location } = this.props;
    const isProductModal = !!(
      location.state &&
      location.state.isProductModal
    );
    const isProductModalPrevLocation = !!(
      prevLocation.state &&
      prevLocation.state.isProductModal
    )
    let clientWidth = window.innerWidth;
    return isProductModal || (isProductModalPrevLocation && location.pathname === '/basket');
  }


  isApplication = prevLocation => {
    const { location } = this.props;
    const isApplicationModal = !!(
      location.state &&
      location.state.isApplicationModal
    );
    const isApplicationModalPrevLocation = !!(
      prevLocation.state &&
      prevLocation.state.isApplicationModal
    )
    let clientWidth = window.innerWidth;
    return isApplicationModal && clientWidth > 940 || (isApplicationModalPrevLocation && location.pathname === '/application' && clientWidth > 940)
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const prevLocation = prevProps.location;

    if (location !== prevLocation &&
      !this.isApplication(prevLocation) &&
      !this.isCatalogue(prevLocation) &&
      !this.isCheckingOut(prevLocation)) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children
  }
}

export default withRouter(ScrollToTop)