import * as types from "./actionTypes";

const initialState = {
  types: [],
  colors: [],
  colorsInStock: [],
  customColors: [],
  currentModel: null,
  genderFilter: "",
  textFilter: "",
  catalogueColorFilter: null,
  loadingModel: true,
  ourWorks: [],
  models: [],
  filteredModels: [],
  typeFilter: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_FILTERED_MODELS:
      return {
        ...state,
        filteredModels: action.filteredModels
      };
    case types.SET_TYPE_FILTER:
      return {
        ...state,
        typeFilter: action.typeFilter
      };
    case types.SET_GENDER_FILTER:
      return {
        ...state,
        genderFilter: action.genderFilter
      };
    case types.SET_TEXT_FILTER:
      return {
        ...state,
        textFilter: action.textFilter
      };
    case types.FETCH_TYPES_SUCCESS:
      return {
        ...state,
        types: action.types
      };
    case types.FETCH_COLORS_SUCCESS:
      return {
        ...state,
        colors: action.colors
      };
    case types.FETCH_TYPE_COLORS:
      return {
        ...state,
        colorsInStock: action.colorsInStock,
        customColors: action.customColors
      };
    case types.FETCH_MODELS_SUCCESS:
      return {
        ...state,
        models: action.models
      };
    case types.FETCH_OUR_WORKS_SUCCESS:
      return {
        ...state,
        ourWorks: action.ourWorks
      };
    case types.SET_CURRENT_MODEL:
      return {
        ...state,
        currentModel: action.model
      };
    case types.SET_EMPTY_MODEL:
      return {
        ...state,
        currentModel: null
      };
    case types.SET_CATALOGUE_COLOR_FILTER:
      return {
        ...state,
        catalogueColorFilter: action.color
      };
    case types.MODEL_IS_LOADING:
      return {
        ...state,
        loadingModel: action.flag
      };
    default:
      return state;
  }
};
