import React from 'react';

import './index.scss';

const Button = ({ children, handler, style, disabled }) => (
  <button
    className={[
      "button",
      disabled ? "button--disabled" : ''
    ].join(" ")}
    onClick={handler}
    style={style}
    disabled={disabled}>
    {children}
  </button>
)

export default Button;