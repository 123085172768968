import React from 'react';
import { scroller } from 'react-scroll';
import { connect } from 'react-redux';

import './index.scss';
import Question from '../../components/Question';
import { ReactComponent as Payment } from '../../img/faq/payment.svg';
import { ReactComponent as Delivery } from '../../img/faq/delivery.svg';
import { ReactComponent as Guarantee } from '../../img/faq/guarantee.svg';
import { ReactComponent as Quality } from '../../img/faq/quality.svg';
import questions from './questions.json';
import Helmeted from '../../components/Helmeted';

class Faq extends React.Component {
  state = {
    questionSection: 'quality'
  }

  handleQuestionCategoryChange = section => {
    this.setState({ questionSection: section });
    if (this.props.clientWidth < 540) {
      scroller.scrollTo('questions', {
        duration: 1000,
        smooth: true,
      })
    }
  }

  getQuestionsPerCategory = category => {
    return questions.filter(question => question.category === category);
  }

  handleQuestionAmountDeclension = category => {
    let questionsAmount = this.getQuestionsPerCategory(category).length;
    const expressions = ['вопрос', 'вопроса', 'вопросов'];
    let result;
    let count = questionsAmount % 100;
    if (count >= 5 && count <= 20) {
      result = expressions['2'];
    } else {
      count = count % 10;
      if (count == 1) {
        result = expressions['0'];
      } else if (count >= 2 && count <= 4) {
        result = expressions['1'];
      } else {
        result = expressions['2'];
      }
    }
    return `${questionsAmount} ${result}`;
  }

  render() {
    return (
      <div className="faq page">
        <Helmeted />
        <div className="contacts__title">
          Часто задаваемые вопросы
        </div>
        <div className="faq__body">
          <div className="faq__items">
            <div
              onClick={() => this.handleQuestionCategoryChange('quality')}
              className={[
                "faq__item",
                this.state.questionSection === 'quality' ? 'faq__item-active' : ''
              ].join(" ")}>
              <div className="faq__item__circle">
                <Quality className="faq__item__icon" />
              </div>
              <span className="faq__item__label">
                Качество
              </span>
              <span className="faq__item__value">
                {this.handleQuestionAmountDeclension('quality')}
              </span>
            </div>
            <div
              onClick={() => this.handleQuestionCategoryChange('delivery')}
              className={[
                "faq__item",
                this.state.questionSection === 'delivery' ? 'faq__item-active' : ''
              ].join(" ")}>
              <div className="faq__item__circle">
                <Delivery className="faq__item__icon" />
              </div>
              <span className="faq__item__label">
                Доставка
              </span>
              <span className="faq__item__value">
                {this.handleQuestionAmountDeclension('delivery')}
              </span>
            </div>
            <div
              onClick={() => this.handleQuestionCategoryChange('payment')}
              className={[
                "faq__item",
                this.state.questionSection === 'payment' ? 'faq__item-active' : ''
              ].join(" ")}>
              <div className="faq__item__circle">
                <Payment className="faq__item__icon" />
              </div>
              <span className="faq__item__label">
                Оплата
              </span>
              <span className="faq__item__value">
                {this.handleQuestionAmountDeclension('payment')}
              </span>
            </div>
            <div
              onClick={() => this.handleQuestionCategoryChange('guarantee')}
              className={[
                "faq__item",
                this.state.questionSection === 'guarantee' ? 'faq__item-active' : ''
              ].join(" ")}>
              <div className="faq__item__circle">
                <Guarantee className="faq__item__icon" />
              </div>
              <span className="faq__item__label">
                Гарантия
              </span>
              <span className="faq__item__value">
                {this.handleQuestionAmountDeclension('guarantee')}
              </span>
            </div>
          </div>
          <div
            className="faq__questions"
            id="questions">
            {this.getQuestionsPerCategory(this.state.questionSection).map((question, index) =>
              <Question {...question} key={question.question} />)}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ clientWidth }) => {
  return {
    clientWidth
  }
}

export default connect(mapStateToProps)(Faq);