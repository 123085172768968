import React from 'react';
import { Link } from 'react-router-dom';

import './index.scss';
import OurWork from '../OurWork';

const OurWorks = ({ ourWorks }) => (
  <section className="work">
    <div className="work__title">
      наши работы
    </div>
    <div className="work__container">
      {
        ourWorks
        ?
        ourWorks.map(ourWork => <OurWork
          key={ourWork.id}
          image={ourWork.image} />)
          :
        null
      }

    </div>
    <div className="work__calc">
      <Link
        className="work__calc__link"
        to="/works">
        <div className="work__calc__button">
          Смотреть все
        </div>
      </Link>
    </div>
  </section>
);

export default OurWorks;
