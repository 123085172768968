import React from "react";

import "./index.scss";
import OrderForm from "../../components/OrderForm";

const CustomOrder = ({ openCalculatorModal }) => (
  <div className="customOrder">
    <div className="customOrder__body">
      <div className="customOrder__body__info">
        <h3 className="customOrder__body__info__title">
          Пошив трикотажных изделий под заказ
        </h3>
        <p className="customOrder__body__info__description">
          Мы изготовим для вас эксклюзивные изделия: футболки, рубашки поло,
          толстовки, разного фасона и силуэта.
        </p>
      </div>
      <OrderForm />
      <button onClick={openCalculatorModal} className="customOrder__button">
        Оставить заявку
      </button>
    </div>
  </div>
);

export default CustomOrder;
