import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

import "./index.scss";
import { openConfirmationModal } from "../../store/helpers/actions";
import Button from "../Button";

class OrderForm extends Component {
  state = {
    phone: "",
    comment: '',
    touched: {
      phone: false,
      comment: false
    },
    valid: {
      phone: false,
      comment: false
    }
  };

  handleTouched = label => {
    this.setState({
      touched: {
        ...this.state.touched,
        [label]: true
      }
    });
  };

  handleInput = (e, label) => {
    this.setState(
      {
        [label]: e.target.value
      },
      () => this.checkInputsValidity()
    );
  };

  handlePhoneInput = e => {
    let phonePattern = /^[0-9-()+]*$/;
    let phone = e.target.value;
    if (phonePattern.test(phone)) {
      this.setState(
        {
          phone
        },
        () => this.checkInputsValidity()
      );
    } else return;
  };

  checkInputsValidity = () => {

    let { phone } = this.state;
    this.setState({
      valid: {
        phone: phone.length > 10,
      }
    });
  };

  formIsValid = () => {
    return this.state.valid.phone;
  };

  submitApplication = () => {
    if (this.formIsValid()) {
      this.props.handleClaimSuccess("claim");
      const form = {
        phone: this.state.phone,
        comment: this.state.comment
      };
      axios.post("/api/v1/callback", form).then(response => {
        this.props.handleClaimSuccess("manager");
        console.log("form", form)
        this.setState({
          phone: "",
          comment: ''
        });
      });
    }
  };
  render() {
    return (
      <div className="order">
        <span className="order__title">Оставить заявку</span>
        <div className="order__group">
          <div className="order__group__title">Номер телефона</div>
          <input
            value={this.state.phone}
            maxLength="11"
            type="tel"
            onBlur={() => this.handleTouched("phone")}
            onChange={e => this.handlePhoneInput(e)}
            className={[
              "order__input",
              this.state.touched.phone && !this.state.valid.phone
                ? "order__input-invalid"
                : ""
            ].join(" ")}
          />
        </div>
        <div className="order__group">
        <div className="order__group__title">Оставить комментарий</div>
          <textarea
            type="text"
            onBlur={() => this.handleTouched('comment')}
            onChange={(e) => this.handleInput(e, 'comment')}
            className="order__group__comment"
            value={this.state.comment} />
        </div>
        <Button handler={this.submitApplication}>Отправить</Button>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleClaimSuccess: tab => dispatch(openConfirmationModal(tab))
  };
};

export default connect(
  null,
  mapDispatchToProps
)(OrderForm);
