import React from 'react';
import Slider from 'react-slick';

import './index.scss';
import Button from '../Button';

const settings = {
  dots: true,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
};

const MainCarousel = ({ handler, applicationHandler }) => (
  <div className="for__dots__wrapper">
    <div className="for__dots__group__wrapper">
      <div className="for__dots__group">
        <div className="for__dots__group__title">
          <span className="for__dots__group__main">
            ФУТБОЛКИ, ТОЛСТОВКИ,
          </span>
          <span className="for__dots__group__main">
            ПОЛО
          </span>
          <span
            onClick={applicationHandler}
            className="for__dots__group__application">
            + Нанесение
          </span>
        </div>
        <span className="for__dots__group__subtitle">
          В любом количестве и размере
        </span>
        <Button handler={handler}>
          Выбрать
        </Button>
      </div>
    </div>
    <Slider {...settings}>
      <div className="for__dots__slide for__dots__slide-first" />
      <div className="for__dots__slide for__dots__slide-second" />
      <div className="for__dots__slide for__dots__slide-third" />
      <div className="for__dots__slide for__dots__slide-fourth" />
    </Slider>
  </div>
);

export default MainCarousel;