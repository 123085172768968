import React from 'react';

import '../index.scss';
import close from '../../../img/application/close.svg';
import PreviewCarousel from '../../ApplicationPreviewSlider';

const Silkscreen = ({ openCalculatorModal, closeApplicationModal }) => (
  <div className="silk">
    <div className="silk__preview">
      <img
        className="silk__preview__close"
        src={close}
        onClick={closeApplicationModal} />
      <div className="silk__preview__content">
        <PreviewCarousel applicationMethod="silk" />
      </div>
    </div>
    <div className="silk__column">
      <div className="silk__column__content">
        <div className="silk__column__content__group">
          <div className="silk__column__content__caption">
            Шелкография
          </div>
          <div
            className="silk__column__content__button"
            onClick={() => openCalculatorModal('silkscreen')}>
            Рассчитать нанесение
          </div>
        </div>
        <div className="silk__column__content__text">
          <p>Шелкография (трафаретная печать, прямая печать на ткани) — самый экономичный вариант при тиражах от 50 шт, позволяет получить яркие цвета устойчивые к механическим воздействиям и влаге, совмещать краску и спецэффекты такие как puff, глиттер, фольга и т.д. </p>
          <p>Подходит для натуральных и смесовых тканей любого цвета.</p>
          <p>Максимальный размер печати 48 см * 70 см</p>
        </div>
      </div>
    </div>
  </div>
)

export default Silkscreen;
