import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import "./index.scss";
import MobileBasketItem from "../MobileBasketItem";
import TabletBasketItem from "../TabletBasketItem";
import BasketItem from "../BasketItem";

const PaymentInfo = ({ basketOrders, clientWidth }) => (
  <div>
    <div className="payment__header">
      <span className="payment__header__title">
        Заказ {basketOrders.id.slice(0, 8).toUpperCase()}
      </span>
    </div>
    {basketOrders.orders.length > 0 ? null : <Redirect to="/basket" />}
    <div className="payment">
      <div className="payment__sizes">
        {clientWidth > 540
          ? clientWidth < 1200
            ? basketOrders.orders.map(order => (
                <TabletBasketItem
                  key={order.id}
                  order={order}
                  orderItem={true}
                />
              ))
            : basketOrders.orders.map(order => (
                <BasketItem key={order.id} order={order} orderItem={true} />
              ))
          : basketOrders.orders.map(order => (
              <MobileBasketItem key={order.id} order={order} />
            ))}
      </div>
      <div className="payment__body">
        <span className="payment__body__title">Доставка</span>
        <div className="payment__body__header">
          {basketOrders.delivery ? (
            <>
              <span className="payment__body__label payment__body__label-address">
                {basketOrders.customerInfo.city}
              </span>
              <span className="payment__body__label payment__body__label-address">
                Транспортная компания {basketOrders.deliveryCompany}
              </span>
            </>
          ) : (
            <>
              <span className="payment__body__label payment__body__label-address">
                Ростов-на-Дону ул. Доватора, 142 литА
              </span>
              <span className="payment__body__label payment__body__label-address">
                Самовывоз
              </span>
            </>
          )}
          <div className="payment__body__group payment__body__group-first">
            <span className="payment__body__label">Габариты груза, см</span>
            <span className="payment__body__value">
              (60х40х50) х {basketOrders.boxes}
            </span>
          </div>
          <div className="payment__body__group payment__body__group-second">
            <span className="payment__body__label">Вес груза, кг</span>
            <span className="payment__body__value">
              {basketOrders.orderWeight}
            </span>
          </div>
        </div>
        <span className="payment__body__address__title">Получатель груза</span>
        <form className="payment__body__address__group">
          <input
            className="payment__body__address__input"
            type="text"
            placeholder="Имя"
            disabled
            readOnly
            value={basketOrders.customerInfo.name}
          />
          <input
            className="payment__body__address__input"
            type="tel"
            placeholder="Телефон"
            disabled
            readOnly
            value={basketOrders.customerInfo.phone}
          />
          <input
            className="payment__body__address__input"
            type="email"
            placeholder="E-mail"
            disabled
            readOnly
            value={basketOrders.customerInfo.email}
          />
        </form>
      </div>
    </div>
  </div>
);

const mapStateToProps = ({ order, helpers }) => {
  return {
    order: order.order,
    basketOrders: order.basketOrders,
    clientWidth: helpers.clientWidth
  };
};

export default connect(mapStateToProps)(PaymentInfo);
