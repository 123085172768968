import * as types from "./actionTypes";

export const updateOrder = order => {
  return {
    type: types.ORDER,
    order
  };
};

export const addBasketOrder = basketOrders => {
  return {
    type: types.ADD_BASKET_ORDER,
    basketOrders
  };
};

export const setEmptyOrder = ({
  hex,
  previews,
  price_grid,
  size_info,
  modelName,
  model,
  gender,
  productType
}) => {
  return {
    type: types.SET_EMPTY_ORDER,
    hex,
    previews,
    price_grid,
    size_info,
    modelName,
    model,
    gender,
    productType
  };
};

export const deleteBasketOrders = () => {
  return {
    type: types.DELETE_BASKET_ORDERS
  };
};
