import React from 'react';

import './index.scss';

const OurWork = ({ image }) => (
  <div className="ourWork">
    <img
      src={image}
      alt="печать на заказ"
      className="ourWork__img" />
  </div>
);

export default OurWork;