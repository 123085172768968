import React from 'react';

import '../index.scss';

const Quit = ({ cancel, closeModal }) => (
  <div className="closeModal">
    <span className="closeModal__title">
      Завершить оформление заказа?
    </span>
    <p className="closeModal__description">
      Заказ не будет сохранен, вся загруженная информация обнулится.
    </p>
    <div className="closeModal__button__wrapper">
      <button
        onClick={cancel}
        className="closeModal__button">
        Отмена
      </button>
      <button
        onClick={closeModal}
        className="closeModal__button">
        Завершить
      </button>
    </div>
  </div>
)

export default Quit;