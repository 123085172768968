import React, { Component } from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";

import "./index.scss";
import {
  toggleCalculatorModal,
  handleCalculatorActiveMethod
} from "../../store/helpers/actions";
import Helmeted from '../Helmeted';
import SilkScreen from "./tabs/silkscreen";
import Embroidery from "./tabs/embroidery";
import Sublimation from "./tabs/sublimation";
import Straightprinting from "./tabs/straightprinting";
import Speacialeffects from "./tabs/speacialeffects";

class ApplicationModalEmbroidery extends Component {
  closeApplicationModal = () => {
    this.props.history.push("/application");
  };

  openCalculatorModal = activeMethod => {
    if (this.props.clientWidth > 940) {
      this.props.history.push("/application");
      this.props.toggleCalculatorModal(true);
      this.props.handleCalculatorActiveMethod(activeMethod);
    } else {
      this.props.toggleCalculatorModal(true);
      this.props.handleCalculatorActiveMethod(activeMethod);
    }
  };

  handleApplicationTab = tab => {
    switch (tab) {
      case "embroidery":
        return (
          <Embroidery closeApplicationModal={this.closeApplicationModal} />
        );
      case "silkscreen":
        return (
          <SilkScreen
            openCalculatorModal={this.openCalculatorModal}
            closeApplicationModal={this.closeApplicationModal}
          />
        );
      case "sublimation":
        return (
          <Sublimation
            openCalculatorModal={this.openCalculatorModal}
            closeApplicationModal={this.closeApplicationModal}
          />
        );
      case "straight-printing":
        return (
          <Straightprinting
            openCalculatorModal={this.openCalculatorModal}
            closeApplicationModal={this.closeApplicationModal}
          />
        );
      case "special-effects":
        return (
          <Speacialeffects closeApplicationModal={this.closeApplicationModal} />
        );
      default:
        return <Redirect to="/application" />;
    }
  };

  render() {
    const { clientWidth } = this.props;
    return (
      <div>
        <Helmeted />
        {clientWidth > 940 ? (
          <Modal
            isOpen={true}
            onRequestClose={this.closeApplicationModal}
            className="applicationModal"
            overlayClassName="applicationModal__overlay"
          >
            {this.handleApplicationTab(this.props.match.params.type)}
          </Modal>
        ) : (
            this.handleApplicationTab(this.props.match.params.type)
          )}
      </div>
    );
  }
}

const mapStateToProps = ({ helpers }) => {
  return {
    clientWidth: helpers.clientWidth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleCalculatorModal: flag => dispatch(toggleCalculatorModal(flag)),
    handleCalculatorActiveMethod: activeMethod =>
      dispatch(handleCalculatorActiveMethod(activeMethod))
  };
};
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ApplicationModalEmbroidery)
);
