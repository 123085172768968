import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import { setClientWidth } from "./store/helpers/actions";
import Layout from "./hoc/layout";
import Main from "./pages/main";
import Catalogue from "./pages/catalogue";
import Works from "./pages/ourWorks";
import Basket from "./pages/basket";
import Contacts from "./pages/contacts";
import Delivery from "./pages/delivery";
import Faq from "./pages/faq";
import CustomProduct from "./pages/customProduct";
import CheckoutModal from "./pages/checkoutModal";
import ProductModal from "./pages/productModal";
import Application from "./pages/application/applicationresolver";
import News from "./pages/news";
import NewsPage from "./pages/newsPage";
import Order from "./pages/order";

class Routes extends React.Component {
  previousLocation = this.props.location;

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions.bind(this));
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  updateDimensions() {
    this.props.setClientWidth(window.innerWidth);
  }

  componentWillUpdate(nextProps) {
    const { location } = this.props;
    if (
      nextProps.history.action !== "POP" &&
      (!location.state ||
        (!location.state.isProductModal &&
          !location.state.isApplicationModal &&
          !location.state.isCheckingOutModal))
    ) {
      this.previousLocation = this.props.location;
    }
  }

  render() {
    const { location, clientWidth } = this.props;

    const isProductModal = !!(
      location.state &&
      location.state.isProductModal &&
      this.previousLocation !== location
    );

    const isCheckingOutModal = !!(
      location.state &&
      location.state.isCheckingOutModal &&
      this.previousLocation !== location
    );

    return (
      <Layout>
        <Switch
          location={
            (isCheckingOutModal || isProductModal) && clientWidth > 940
              ? this.previousLocation
              : location
          }
        >
          <Route path="/" exact component={Main} />
          <Route
            path="/jersey"
            exact={clientWidth < 940}
            component={Catalogue}
          />
          <Route
            path="/sweatshirt"
            exact={clientWidth < 940}
            component={Catalogue}
          />
          <Route path="/polo" exact={clientWidth < 940} component={Catalogue} />
          <Route path="/application" component={Application} />
          <Route path="/works" component={Works} />
          <Route path="/basket" exact component={Basket} />
          <Route path="/contacts" component={Contacts} />
          <Route path="/faq" component={Faq} />
          <Route path="/delivery" component={Delivery} />
          <Route path="/custom-product" component={CustomProduct} />
          <Route path="/news" exact component={News} />
          <Route path="/news/:title" component={NewsPage} />
          <Route path="/checkout/:action" component={CheckoutModal} />
          <Route path="/:productType/:gender/:model" component={ProductModal} />
          <Route path="/orders/:id" component={Order} />
          <Redirect to="/" />
        </Switch>
        {isCheckingOutModal && clientWidth > 940 ? (
          <Route path="/checkout/:action" component={CheckoutModal} />
        ) : null}
        {clientWidth > 940 ? (
          <Route path="/:productType/:gender/:model" component={ProductModal} />
        ) : null}
      </Layout>
    );
  }
}

const mapStateToProps = ({ helpers }) => {
  return {
    clientWidth: helpers.clientWidth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setClientWidth: clientWidth => dispatch(setClientWidth(clientWidth))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Routes);
