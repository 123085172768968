import React from 'react';

import '../index.scss';

const DeleteOrder = ({ deleteOrder, closeModal }) => (
  <div className="deleteOrder">
    <span className="deleteOrder__title">
      Вы действительно хотите удалить заказ?
    </span>
    <div className="deleteOrder__buttons">
      <button
        onClick={deleteOrder}
        className="gratitude__button deleteOrder__button">
        Удалить
      </button>
      <button
        onClick={closeModal}
        className="gratitude__button">
        Отмена
      </button>
    </div>
  </div>
)

export default DeleteOrder;